import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Resolution, EditedResolution, WsCommand, LiveResolution } from '../../../models/PollChoice';

@Component({
  selector: 'vc-fixed-live-resolution',
  templateUrl: 'live-resolution.component.html',
  styleUrls: ['./live-resolution.component.less']
})

export class LiveFixedResolutionComponent implements OnInit {
  @Input() index: number;
  @Input() round: number;
  @Input() status: LiveResolution;
  @Input() resolution: Resolution;
  @Input() isActive: boolean;
  @Input() isPublic: boolean;
  @Output() sendCommand = new EventEmitter<WsCommand>();
  @Output() sendResults = new EventEmitter<number>();
  @Output() deleteEvent = new EventEmitter<number>()
  
  editedObject: EditedResolution;
  isValid = false;

  constructor() {}

  delete() {
    this.deleteEvent.emit(this.round)
  }

  autoHeight() {
    let element = document.getElementsByTagName("textarea")

    for (let i = 0; i < element.length; i++) {
      element[i].style.height = "36px";
      element[i].style.height = element[i].scrollHeight + "px";
    }
  }

  startResolution() {
    const command = new WsCommand(this.round, "open")
    this.sendCommand.emit(command)
  }

  endResolution() {
      const command = new WsCommand(this.round, "close")
      this.sendCommand.emit(command);
  }

  results() {
    this.sendResults.emit(this.index)
  }

  ngOnInit() {
    this.editedObject = new EditedResolution(this.index, this.resolution)
    this.autoHeight()
  }
}
