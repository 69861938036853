<div class="div-arriere-plan-action" (click)="clicAction($event)"></div>
  <div class="div-global-action" (click)="clicAction($event)">
  <div id="center-square">
    <div id="blue-lines"></div>
    <div class="text-holder">
      <h1 class="header_title">
        {{'POLL-PAYMENT.CONGRATS' | translate}}
      </h1>
      <h1 class="header_title" >
        {{'POLL-PAYMENT.UPDATE' | translate}}
      </h1>
      <h1 class="header_title" >
        {{'POLL-PAYMENT.DESCRIPTION' | translate}}
      </h1>
      <h1 class="header_title" >
        {{'POLL-PAYMENT.NUMBER' | translate}}
      </h1>
      <div class="separator"></div>
    </div>

    <div class="button-holder">
      <button type="button" id="button-vote" (click)="cancel()">
      {{'LIVE.POPUP.AGREE' | translate}}
      </button>
    </div>
  </div>
</div>
