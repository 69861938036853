import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-valid-changes',
  templateUrl: './valid-changes.component.html',
  styleUrls: ['./valid-changes.component.less', '../../../shared/common.less']
})
export class ValidChangesComponent implements OnInit {
  @Output() private close: EventEmitter<void> = new EventEmitter();

  constructor() { }

  closeValidationPopup() {
    this.close.emit();
  }

  ngOnInit(): void {
  }

}
