import { Component, Input } from '@angular/core';

@Component({
  selector: 'vc-excel-error',
  templateUrl: 'excel-error-details.component.html',
  styleUrls: ['./excel-error-details.component.less'],
})
export class ExcelError {
  @Input() isImportError;
  @Input() isPonderationError;
  @Input() isSMSError;
  @Input() numberOfVoters;
  @Input() is2FA;
  @Input() messageString;

  ngOnInit() {

  }

  constructor() {}
}
