import { Component, OnInit, Input } from '@angular/core';
// import { AuthenticationService } from '../../../shared/authentication.service';
import { AuthenticationService } from '../../../../shared/authentication.service';
import { Subscribe } from './Subscribe';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { ApiService } from "./../../../../shared/api.service";
import { ActivatedRoute } from "@angular/router";
import { Common } from 'src/app/shared/common';

@Component({
  selector: 'vc-subscribe',
  templateUrl: './inscription.component.html',
  styleUrls: ['./../../css/normalize.css', './../../css/webflow.css', './../../../home.component.less', './inscription.component.less']
  //'./../../css/normalize.css', './../../css/webflow.css', './../../css/new-v8te.webflow.css',
})

export class SubscribeComponent implements OnInit {
  isMobile: boolean;
  subscriptionData = new Subscribe()
  popupStatus = 0;
  subscribeValid: boolean = false;
  subscribeMail: string = "";
  backURL = "https://www.v8te.com/fr";


  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private common: Common
  ) {}

  hasLowerCase(password) {
    return (/[a-z]/.test(password));
  }

  hasUpperCase(password) {
    return (/[A-Z]/.test(password));
  }

  hasNumber(password) {
    return (/[0-9]/.test(password));
  }

  hasSpecial(password) {
    var specialRegex = new RegExp("^(?=.*[!.,;:+%/{}'\"\(\)?@#\$%\^&\*])")
    return specialRegex.test(password)
  }

  onPasswordInput($event) {
    this.validatePassword($event.target.value) ? this.hideCriterias() : this.triggerCriterias()
  }

  validatePassword(password: string) {
    this.popupStatus = 0;
    const lower = document.getElementById("lower")
    const upper = document.getElementById("upper")
    const number = document.getElementById("number")
    const special = document.getElementById("special")
    const elements = document.getElementById("elements")
    const length = document.getElementById("length")
    let validCriteriasCount = 0
    // const validCriteriasCount = document.getElementsByClassName("round")

    this.hasLowerCase(password) == true ? lower.className = "round" : lower.className = "invalid-round"
    this.hasUpperCase(password) == true ? upper.className = "round" : upper.className = "invalid-round"
    this.hasNumber(password) == true ? number.className = "round" : number.className = "invalid-round"
    this.hasSpecial(password) == true ? special.className = "round" : special.className = "invalid-round"

    validCriteriasCount += this.hasLowerCase(password) ? 1 : 0
    validCriteriasCount += this.hasUpperCase(password) ? 1 : 0
    validCriteriasCount += this.hasNumber(password) ? 1 : 0
    validCriteriasCount += this.hasSpecial(password) ? 1 : 0
    validCriteriasCount > 2 ? elements.className = "round" : elements.className = "invalid-round"
    password.length > 7 ? length.className = "round" : length.className = "invalid-round"

    if (password.length > 7)
      return validCriteriasCount > 2 ? true : false
    return false
    }

  resetTooltips() {
    this.popupStatus = 0;
  }

  checkIfMobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      } else {
        return false;
      }
  }

    checked() {
      const condition = document.getElementById('terms-and-conditions') as HTMLInputElement
      if (condition) {
        condition.classList.remove("unselected-checkbox")
      }
    }

    async subscribeUser() {
        const password = document.getElementById('password') as HTMLInputElement
        const mail = document.getElementById('email') as HTMLInputElement
        const condition = document.getElementById('terms-and-conditions') as HTMLInputElement
        const newsletter = document.getElementById('newsletter') as HTMLInputElement

        this.subscribeMail = mail.value;
        if (mail.value.length < 2) {
          this.popupStatus = 1;
          mail.focus();
        } else {
          if(!this.common.validateEmail(mail.value)) {
            this.popupStatus = 2;
            mail.focus();
          } else {
            if(!this.validatePassword(password.value)) {
              if (password.value.length < 1)
                this.popupStatus = 3;
              else
                this.popupStatus = 4;
              password.focus();
            } else {
              if (condition.checked === false) {
                condition.classList.add("unselected-checkbox");
                this.popupStatus = 5;
              }
            }
          }
        }
        if (this.popupStatus == 0) {
            this.authenticationService.subscribe({
                provider: "Username-Password-Authentication",
                mail: String(mail.value),
                password: String(password.value),
                newsletter: String(newsletter.checked),
                language: this.common.getLangCode()
            }, this.displaySubscriptionResult.bind(this));

        }
    }

  triggerCriterias() {
    const validCriteriasCount = document.getElementsByClassName("round")
    const length = document.getElementById("length")
    const elements = document.getElementById("elements")

    // if (validCriteriasCount.length < 5) {
    if (length.className.localeCompare("round") != 0 ||
        elements.className.localeCompare("round") != 0) {
      let container = document.getElementById('password-criterias')
      container.style.height = "241px"
      container.style.padding = "20px"
      container.style.border = "solid 1px #d9d9e9"
    }
    }

    hideCriterias() {
      let container = document.getElementById('password-criterias')
      container.style.height = "0px"
      container.style.padding = "0px"
      container.style.border = "none"
    }

    highlightCriterias() {
      let container = document.getElementById('password-criterias')
      if (container.style.height.localeCompare("241px") === 0)
        container.style.border = "solid 1px #EB173B"
    }

    displaySubscriptionResult(result: any) {
      if (typeof(result) == "object")
        if (result.code.localeCompare("invalid_signup") == 0)
          this.popupStatus = 6
      if (result == 200) {
        this.subscribeValid = true
      } else {
        this.subscribeValid = false
      }
    }

  redirectToConnection() {
    this.router.navigateByUrl("/connexion")
  }

  loginWithGoogle() {
      this.isMobile = this.checkIfMobile();
      if (!this.isMobile) {
        this.authenticationService.loginWithSocial('google-oauth2');
      }
    }

    loginWithLinkedin() {
      this.isMobile = this.checkIfMobile();
      if (!this.isMobile) {
        this.authenticationService.loginWithSocial('linkedin');
      }
    }

    ngOnInit(): void {
      const currentUser = this.authenticationService.currentUserValue;
      if (currentUser) {
        this.router.navigate(['/polls']);
      }
      var userLang = navigator.language || navigator["userLanguage"];
          if (userLang.substring(0, 2) != "fr") {
            this.backURL = "https://www.v8te.com/";
          }
      this.route.queryParams.subscribe((params) => {
             if (params.key != null) {
                localStorage.setItem("referral", params.key);
                this.apiService.getNameFromEncoded(params.key).subscribe((res) => {
                  let emailInput = (<HTMLInputElement>document.getElementById('email'));
                  emailInput.value = res["email"];
                });
             }
          });
    }
}
