import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditedResolution, WsCommand, LiveResolution} from '../../../models/PollChoice';
import { PollQuestion } from './../../../models/PollCreationData';


@Component({
  selector: 'app-fixed-live-candidate',
  templateUrl: 'live-candidate.component.html',
  styleUrls: ['./live-candidate.component.less']
})

export class LiveFixedCandidateComponent {
  @Input() index: number;
  @Input() round: number;
  @Input() status: LiveResolution;
  @Input() choiceEvent: PollQuestion;
  @Input() isActive: boolean;
  @Input() isPublic: boolean;
  @Output() sendCommand = new EventEmitter<WsCommand>();
  @Output() sendResults = new EventEmitter<number>();
  @Output() sendResolution = new EventEmitter<EditedResolution>();
  @Output() deleteQuestionEvent = new EventEmitter<number>();
  editedObject: EditedResolution;
  isValid = false;

  constructor() {
  }

  startResolution() {
    const command = new WsCommand(this.index, 'open');
    this.sendCommand.emit(command);
  }

  endResolution() {
      const command = new WsCommand(this.index, 'close');
      this.sendCommand.emit(command);
  }

  deleteQuestion() {
    this.deleteQuestionEvent.emit(this.round)
  }

  results() {
    this.sendResults.emit(this.index);
  }
}
