<div class="div-arriere-plan-action"></div>
<div class="div-global-action">
  <div id="print-recommendations" class="content">
    <h3 class="title">{{title}}</h3>
    <h3 *ngFor="let voter of voters" class="recommendations-title">{{voter.email}}</h3>
      <button type="button" class="button-see-more" (click)=onDismiss()>
        OK
      </button>
  </div>
</div>
