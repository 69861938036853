<div class="form-holder">
  <div class="safe-area">
    <h1 *ngIf="description != ' '" class="objective" [innerHTML]="replaceURLs(description)"></h1>
    <h1 class="objective" [innerHTML]="replaceURLs(sharedLinks)"></h1>
    <div class="rejoin-holder">
        <span class="status">{{'POLL-ONGOING.TITLE' | translate}}</span>
        <div class="button-container">
          <button class="blue-button" style="margin-top: 3px;" *ngIf="type == 'liveCandidates' || type == 'election' && isLive" (click)=rejoin() data-cy="return-to-election-btn">{{'POLL-ONGOING.REJOIN-ELECTION' | translate}}</button>
          <button class="blue-button" style="margin-top: 3px;" *ngIf="type == 'live' || type == 'resolution' && isLive" (click)=rejoin() data-cy="return-to-election-btn">{{'POLL-ONGOING.REJOIN-RESOLUTION' | translate}}</button>
        </div>
        <div style="width: 126px"></div>
    </div>
    <div class="separator"></div>
    <div class="infos-holder">
      <div class="small-holder">
        <img class="description-icon" src="assets/calendar-small.svg">
        <div>
          <h3 class="descriptor">{{'POLL-ONGOING.START' | translate}}</h3>
          <h3 class="info">{{startDate}}</h3>
        </div>
      </div>
      <div>
        <h3 class="descriptor">{{'POLL-ONGOING.END' | translate}}</h3>
        <h3 class="info">{{endDate}}</h3>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/election-simple.svg">
        <div>
          <h3 class="descriptor">{{'POLL-ONGOING.ELECTORS' | translate}}</h3>
          <h3 class="info-bold">{{votersCount}}</h3>
        </div>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/tot-voters.svg">
        <div>
          <h3 class="descriptor">{{'POLL-ONGOING.VOTERS' | translate}}</h3>
          <h3 class="info-bold">{{votersVoted}}</h3>
        </div>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/participation.svg">
        <div>
          <h3 class="descriptor">{{'POLL-ONGOING.PARTICIPATION' | translate}}</h3>
          <h3 class="info-bold">{{participation}}%</h3>
        </div>
      </div>
      <div class="small-holder">
        <img (click)="refresh()" class="refresh-icon" src="assets/refresh.svg">
      </div>
    </div>
  </div>
</div>
