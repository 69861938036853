export class ShortVoter {
  email: string;
  firstName: string;
  lastName: string;

  public static withEmail(email: string) {
    const voter = new ShortVoter();
    voter.email = email;
    return voter;
  }
  public static withEmailAndName(email: string, name: string) {
    const voter = ShortVoter.withEmail(email);
    voter.firstName = name;
    return voter;
  }
}
