<p *ngIf="result.text == 'POUR'" class="graph"><progress class="for-bar" max="100" value={{result.percentage}}> 70% </progress></p>
<p *ngIf="result.text == 'CONTRE'" class="graph"><progress class="contre-bar" max="100" value={{result.percentage}}> 70% </progress></p>
<p *ngIf="result.text == 'ABSTENTION'" class="graph"><progress class="abstention-bar" max="100" value={{result.percentage}}> 70% </progress></p>
<p *ngIf="result.text != 'POUR' && result.text != 'ABSTENTION' && result.text != 'CONTRE'" class="graph"><progress max="100" value={{result.percentage}}> 70% </progress></p>
<div class="flex-main">
  <span class="answer">{{result.text === '' ? '?' : choice}}</span>
  <div class="flex-half">
    <span class="voters">{{result.votes === null ? '?' : result.votes + "  votes -"}}</span>
    <span class="percentage">{{result.percentage === null ? '?' : result.percentage + "%"}}</span>
  </div>
</div>
<div class="spacer"></div>
