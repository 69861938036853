import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
} from "@angular/core";

@Component({
  selector: "vc-plurinominal-input",
  templateUrl: "plurinominal-input.component.html",
  styleUrls: ["./plurinominal-input.component.less"],
})
export class PlurinominalInput implements OnInit {
  @Input() numberOfResolution: Number;
  @Input() numberOfCandidates: Number;
  @Input() numberOfVote: number = 1;
  @Input() index: number;
  @Input() temporality: string;
  @Input() votingType: string;
  @Output() sendNumberOfVote = new EventEmitter<Number>();

  onVotersChanges($event) {
    const invalidCharacters = /[^0-9]/gi;
    const input = document.getElementById(
      "pluri-" + this.index
    ) as HTMLInputElement;

    if (invalidCharacters.test(input.value)) {
      const validInput = input.value.replace(invalidCharacters, "");
      input.value = validInput;
    }

    // if (this.numberOfCandidates < Number(input.value)) {
    //   input.value = String(this.numberOfCandidates);
    // }

    if (Number(input.value) == 0 && $event.keyCode !== 8) {
      input.value = "1";
    }
    this.sendNumberOfVote.emit(Number(input.value));
  }

  onVotersDelete() {
    const input = document.getElementById(
      "pluri-" + this.index
    ) as HTMLInputElement;
    if (input.value.length > 0) {
      if (this.numberOfCandidates < Number(input.value)) {
        input.value = String(this.numberOfCandidates);
        this.sendNumberOfVote.emit(Number(input.value));
      }
    } else {
      if (this.numberOfCandidates < Number(input.value))
        input.value = String(this.numberOfCandidates);
    }
    this.sendNumberOfVote.emit(Number(input.value));
  }

  onVotersDone() {
    const input = document.getElementById(
      "pluri-" + this.index
    ) as HTMLInputElement;
    if (input.value.length > 0) {
      if (Number(input.value) == 0) {
        input.value = "1";
        this.sendNumberOfVote.emit(Number(input.value));
      }
    } else {
      input.value = "1";
      this.sendNumberOfVote.emit(this.numberOfVote);
    }
  }

  ngOnChanges() {
  }

  ngOnInit() {
    // this.numberOfVote = 1;
  }
}
