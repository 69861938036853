<div class="offers-holder" *ngIf="index == 0">
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.HUSSAR-SINGULAR' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.FORMATION-2H' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.FULL-SERVICE' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">1 068€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">890€ HT</span>
  </div>
</div>
<div class="offers-holder" *ngIf="index == 1">
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.HUSSAR-SINGULAR' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.LEGAL-OPTION' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.FORMATION-2H' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.FULL-SERVICE' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">1 068€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">890€ HT</span>
  </div>
</div>
<div class="offers-holder" *ngIf="index >= 2">
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.HUSSAR-PLURAL' | translate}}</span>
    <span class="offer-price">{{'PRICE-DETAILS.QUOTE' | translate}}</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.LEGAL-OPTION' | translate}}</span>
    <span class="offer-price">{{'PRICE-DETAILS.QUOTE' | translate}}</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.FORMATION-PL' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">1 068€ TTC</span>
    <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
  </div>
  <div class="offer-line">
    <span class="offer-text">{{'PRICE-DETAILS.FULL-SERVICE' | translate}}</span>
    <span class="offer-price">{{'PRICE-DETAILS.QUOTE' | translate}}</span>
  </div>
</div>