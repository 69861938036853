import { Component, Input } from "@angular/core";

@Component({
  selector: "vc-offer-details-popup",
  templateUrl: "offer-details-popup.component.html",
  styleUrls: ["./offer-details-popup.component.less"],
})
export class OfferDetailsPopup {
  @Input() index;
  @Input() isAssoc: boolean;

  constructor() {}

  ngOnInit() {
    
  }
}
