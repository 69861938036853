import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-voters-relaunch',
  templateUrl: './voters-relaunch.component.html',
  styleUrls: ['./voters-relaunch.component.less']
})
export class VotersRelaunchComponent {
  @Output() isClosed = new EventEmitter<boolean>();
  @Input() isResend = true;
  @Input() isAssessors = false;

  onDismiss(event: boolean) {
    this.isClosed.emit(event);
  }
}
