<div class="form-holder">
  <vc-poll-card-header [image]="'assets/planifier.svg'" [title]="'POLL-CREATION.FORM.CONTEXT' | translate" [description]="'POLL-CREATION.FORM.DESCRIPTION' | translate">
  </vc-poll-card-header>
  <div class="safe-area">
    <h3 *ngIf="!disableTemporalitySelection" class="subtitle" id="subtitle-main">{{'POLL-CREATION.FORM.TEMPORALITY' | translate}}</h3>
    <div *ngIf="!disableTemporalitySelection" id="type-holder">
      <div class="left-option" (click)="setTemporality('live')"
        [ngClass]="createdPoll.temporality =='live' ? 'left-option-selected' : 'left-option'">
        <img *ngIf="createdPoll.temporality =='live'" class="selected-icon" src="assets/selection-checkbox.svg">
        <img [src]="createdPoll.temporality =='live' ? 'assets/live-white.svg' : 'assets/live-gris.svg'"
          id="live-image" height="36" width="36">
        <h2 class="button-text">{{'POLL-CREATION.FORM.LABEL-LIVE' | translate}}</h2>
      </div>
      <div class="right-option" (click)="setTemporality('planned')"
        [ngClass]="createdPoll.temporality =='planned' ? 'right-option-selected' : 'right-option'">
        <img *ngIf="createdPoll.temporality =='planned'" class="selected-icon" src="assets/selection-checkbox.svg">
        <img [src]="createdPoll.temporality =='planned' ? 'assets/calendar-selected.svg' : 'assets/calendar-simple.svg'"
          id="resolution-image" height="36" width="36">
        <h2 class="button-text">{{'POLL-CREATION.FORM.LABEL-PLANNED' | translate}}</h2>
      </div>
    </div>
    <p *ngIf="createdPoll.temporality =='live' && !disableTemporalitySelection" class="description">{{'POLL-CREATION.MAIN.LIVE-VOTE' | translate}}</p>
    <p *ngIf="createdPoll.temporality =='planned' && !disableTemporalitySelection" class="description">{{'POLL-CREATION.MAIN.PLANNED-VOTE' | translate}}</p>
    <div [hidden]="createdPoll.temporality =='live'">
      <h3 class="subtitle">{{'POLL-CREATION.FORM.START-TIME' | translate}}</h3>
       <input type="date" id="start-date" (change)="onStartDate($event)" placeholder="{{'POLL-CREATION.FORM.START-DATE-PLACEHOLDER' | translate}}"
        [ngClass]="isValidStartDate == true ? 'date-valid' : 'date-invalid'" value="{{createdPoll.startDate}}" min="{{limitDate}}">

      <input type="time" id="start-time" (focusout)="onStartTime($event)" placeholder="00:00"
        [ngClass]="isValidStartTime == true ? 'date-valid' : 'date-invalid'" value="{{createdPoll.startTime}}">
      <h3 class="subtitle">{{'POLL-CREATION.FORM.END-TIME' | translate}}</h3>
      <input type="date" id="end-date" (change)="onEndDate($event)" placeholder="{{'POLL-CREATION.FORM.START-DATE-PLACEHOLDER' | translate}}"
        [ngClass]="isValidEndDate == true ? 'date-valid' : 'date-invalid'" value="{{createdPoll.endDate}}" min="{{limitEndDate}}">
      <input type="time" id="end-time" (focusout)="onEndTime($event)" placeholder="00:00"
        [ngClass]="isValidEndTime == true ? 'date-valid' : 'date-invalid'" value="{{createdPoll.endTime}}">
    </div>
  </div>
</div>
