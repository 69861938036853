<div class="div-arriere-plan-action"></div>
<div class="div-global-action">
  <div id="print-recommendations" class="content">
  <div class="recommendations-title">
    {{'POLL-CREATION.LOADER' | translate}}
  </div>
    <mat-spinner diameter="75" id="img_macaron">
    </mat-spinner>
  </div>
</div>
