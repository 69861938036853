<div class="div-global-action">
  <div id="container">
    <label class="switch">
      <div id="switch-container">
        <input type="checkbox" (click)="defineActive($event)">
        <span class="slider round">
          <div id="toggle-text-container">
            <div id="asso" class="active">
              <p class="toggle-text">ASSOCIATION</p>
            </div>
            <div id="company" class="inactive">
              <p class="toggle-text">ENTREPRISE</p>
            </div>
          </div>
        </span>
      </div>
    </label>
  </div>

  <div class="container-offer" *ngIf="isAssoc else sub">
    <vc-offer-card price="{{'0€'}}" [index]="0" [source]="source" (sendSelection)="receiveSelection($event)">
    </vc-offer-card>

    <vc-offer-card price="{{'240€'}}" [index]="1" [source]="source" (sendSelection)="receiveSelection($event)">
    </vc-offer-card>

    <vc-offer-card-main price="{{'480€'}}" [index]="2" special="{{'PRICE-DETAILS.ECONOMY-60' | translate}}" [source]="source"
      (sendSelection)="receiveSelection($event)"></vc-offer-card-main>

    <vc-offer-card price="{{'600€'}}" [index]="3" special="{{'PRICE-DETAILS.LIMITED' | translate}}" [source]="source"
      (sendSelection)="receiveSelection($event)"></vc-offer-card>
  </div>
  <ng-template #sub>
    <div class="container-offer" *ngIf="!isAssoc">
      <vc-offer-card [isAssoc]="isAssoc" [source]="source" [index]="0" (sendSelection)="receiveSelection($event)"
        price="{{'0€'}}"></vc-offer-card>

      <vc-offer-card [isAssoc]="isAssoc" [source]="source" [index]="1" (sendSelection)="receiveSelection($event)"
        price="{{'590€'}}"></vc-offer-card>

      <vc-offer-card-main [isAssoc]="isAssoc" [source]="source" [index]="2" (sendSelection)="receiveSelection($event)"
        price="{{'1 490€'}}" special="{{'PRICE-DETAILS.ECONOMY-60' | translate}}"></vc-offer-card-main>

      <vc-offer-card [isAssoc]="isAssoc" [source]="source" [index]="3" (sendSelection)="receiveSelection($event)"
        price="{{'1 590€'}}" special="{{'PRICE-DETAILS.LIMITED' | translate}}"></vc-offer-card>
    </div>
  </ng-template>

  <vc-warning-popup *ngIf="status == 1" [isAssoc]="isAssoc" [index]="selectedCardIndex"
    (endDetailsPopup)="endDetailsPopup($event)" title="{{'PRICE-DETAILS.TITLE' | translate}}" [cancelButton]="true"
    [callButton]="true" [origin]="'HP'" [separator]="false"></vc-warning-popup>

  <vc-warning-popup *ngIf="status == 2" title="{{'DETAILS-CONTACT.TITLE' | translate}}" [phoneInput]="true"
    [validateButton]="true" [cancelButton]="true" (contactPhone)="getContactPhone($event)"
    (endDetailsPopup)="endDetailsPopup($event)">
  </vc-warning-popup>

  <vc-warning-popup *ngIf="status == 3" title="{{'DETAILS-CONTACT.THANK' | translate}}"
    firstText="{{'DETAILS-CONTACT.CONFIRMATION' | translate}}" secondText="{{'DETAILS-CONTACT.CONTACT' | translate}}"
    (endDetailsPopup)="endDetailsPopup($event)" [understoodEndButton]="true"></vc-warning-popup>
</div>
