<div class="form-holder">
  <vc-poll-card-header [image]="'assets/assesseur-toggle-icon.svg'" [title]="'ASSESSORS.ASSESSOR-TOGGLE-TITLE' | translate"
  [description]="'ASSESSORS.ASSESSOR-TOGGLE-DESCRIPTION' | translate">
  </vc-poll-card-header>
  <div class="safe-area">
    <p class="description" [innerHTML]="'ASSESSORS.ASSESSORS-TOGGLE-DESCRIPTION' | translate"></p>
    <!-- <p *ngIf="isUncrypted" class="description" [innerHTML]="'POLL-CREATION.MAIN.SECRET-LIST-DESCRIPTION' | translate"></p> -->
    <div class="checkbox-holder">
      <span [ngClass]="assessorsActive ? 'checkbox-label-left-gray' : 'checkbox-label-left'">{{'ASSESSORS.NO-ASSESSORS' | translate}}</span>
      <label class="switch">
        <input type="checkbox" id="assessor-checkbox-control" [(ngModel)]="assessorsActive"  data-md-icheck (change)="setAssessors($event)">
        <span class="slider round"></span>
      </label>
      <span [ngClass]="assessorsActive ? 'checkbox-label' : 'checkbox-label-gray'">{{'ASSESSORS.ASSESSORS' | translate}}</span>
    </div>
    <div class="checkbox-holder">
      <span class="disclaimer" style="padding-left: 3px; text-align: end;">{{'ASSESSORS.NO-ASSESSORS-SUB' | translate}}</span>
      <span class="space-disclaimer"></span>
      <span class="disclaimer">{{'ASSESSORS.ASSESSORS-SUB' | translate}}</span>
    </div>
  </div>
</div>
