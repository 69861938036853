<div id="page-container">
  <div id="content-wrap">
    <vc-poll-pages-header [title]="title" [shouldReturnToPoll]="false" [pollId]="pollId"
      [isLive]="isLive" [isPublic]="isPublic" [SMSOption]="SMSOption" [is2FA]="is2FA"  [isAssessors]="hasAssessors">
    </vc-poll-pages-header>
    <vc-poll-finished-summary [description]="description" [startDate]="startDate" [endDate]="endDate" [votersCount]="votersCount"
      [votersVoted]="votes" [participation]="percentageString" (downloadVoters)="downloadDocuments()" (shareResults)="shareResults()"
      [resultsShared]="resultsShared" [isShareEnabled]="isShareEnabled" [endDateFormat]="endDateFormat" (return)="rejoinPoll()"
      [type]="votingType" [calculating]="calculating">
    </vc-poll-finished-summary>
    <app-results id="resultsPage" [pollId]="pollId" [displayIndex]="displayIndex" [pollData]="pollData"
      [assessorsValidated]="assessorsValidated" (sendAssessorsList)="getRemainingAssessors()" [hasAssessors]="hasAssessors"
      (sendAssessorsInvitation)="relaunchAssessors()" [canResendAssessors]="canResendAssessors"
      [remainingAssessorTime]="remainingAssessorTime" [endDateFormat]="endDateFormat" [assessorCount]="assessors" [inactiveAssessorCount]="remainingAssessors">
    </app-results>
  </div>
</div>
<div *ngIf="isError">
  <app-error
    (isClosed)="common.navigateToPolls()"
    [errorMessage]="errorMessage">
  </app-error>
</div>
<vc-warning-popup *ngIf="shouldDisplaySharePopup" title="{{'OFFERS.WARNING.CATCH' | translate}}"
  firstText="{{'POLL-WARNING.SHARE-POPUP' | translate}}" [confirmButton]="true"
  (endDetailsPopup)="dismissSharePopup($event)">
</vc-warning-popup>
<div *ngIf="isSending">
  <vc-loader messageToTheUser="{{'LOADER.SHARE-RESULT' | translate}}">
  </vc-loader>
</div>
<div *ngIf="displayAssessorPopup">
  <vc-warning-popup title="{{'REMAINING-ASSESSORS' | translate}}" [mails]="inactiveAssessors" [understoodEndButton]="true"
    (endDetailsPopup)="dismissAssessorsPopup()" [separator]="false">
  </vc-warning-popup>
</div>
