export class Voter {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public weight: string,
    public phone: string,
    public validationCode: string,
    public language: string) {
  }

    public getName(): string {
      if (this.firstName != null && this.lastName != null) { return `${this.firstName} ${this.lastName}`; }
      else {
        if (this.firstName != null && this.lastName == null) return this.firstName;
        else return this.lastName;
      }
    }
}
