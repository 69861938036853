<div class="fullScreen">
  <div>
    <img src="assets/v8te.png" id="logo" data-cy="back-btn" (click)="navigateHome()">
    <div class="title-container">
      <div class="title-container-sub">
        <h3 class="title" data-cy="event-title-ongoing">{{title}}</h3>
      </div>
      <img src="assets/info_icon.svg" id="info-icon" (click)="navigateHome()">
      <p class="poll-info" data-cy="details-ongoing">
        <span *ngIf="isLive">{{'POLL-CREATION.FORM.LABEL-LIVE' | translate}}</span>
        <span *ngIf="!isLive">{{'POLL-CREATION.FORM.LABEL-PLANNED' | translate}}</span>,
        <span *ngIf="!isPublic">{{'COMMON.LOW-SECRET' | translate}}</span>
        <span *ngIf="isPublic">{{'COMMON.LOW-PUBLIC' | translate}}</span>,
        <span *ngIf="SMSOption && !is2FA">{{'COMMON.LOW-MAIL-SMS' | translate}}</span>
        <span *ngIf="SMSOption && is2FA">{{'COMMON.LOW-2FA' | translate}}</span>
        <span *ngIf="!SMSOption">{{'COMMON.LOW-MAIL' | translate}}</span>
        <span *ngIf="isAssessors">{{'COMMON.ASS-INVITE' | translate}}</span>
      </p>
      <p></p>
    </div>
  </div>
  <div></div>
</div>
