<div class="form-holder">
  <div class="safe-area">
    <h1 class="title">{{'POLL-PLANNED.TIMER-TITLE' | translate}}</h1>
    <div id="timers">
      <div class="timer">
        <h1 class="time">{{timeDelta.days}}</h1>
        <h1 class="measurement">{{'TIMER.DAYS' | translate}}</h1>
      </div>
      <div class="timer">
        <h1 class="time">{{timeDelta.hours}}</h1>
        <h1 class="measurement">{{'TIMER.HOURS' | translate}}</h1>
      </div>
      <div class="timer">
        <h1 class="time">{{timeDelta.minutes}}</h1>
        <h1 class="measurement">{{'TIMER.MINUTES' | translate}}</h1>
      </div>
      <div class="timer">
        <h1 class="time">{{timeDelta.seconds}}</h1>
        <h1 class="measurement">{{'TIMER.SECONDS' | translate}}</h1>
      </div>
    </div>
    <p *ngIf="hasAssessorsButtons" class="resolutions-participation">
      <span class="assessor-title" style="margin-right: 10px;">{{'ASSESSORS.ASSESSOR-TITLE' | translate}}</span>
      <span class="participation-label">{{assessorCount - inactiveAssessors}}/{{assessorCount}}</span>
      <progress max="100" value={{participation}}> 70% </progress>
    </p>
    <div *ngIf="canSendConvocations == true" style="text-align: center;">
      <button class="blue-button" (click)=launchConvocations()>{{'TIMER.CONVOCATIONS' | translate}}</button>
    </div>
    <div *ngIf="canSendConvocations == false" style="text-align: center;">
      <button class="inactive-button">{{'TIMER.CONVOCATIONS' | translate}}</button>
      <div *ngIf="timeDiffHours != null" class="clock-holder">
        <img class="clock-icon" src="assets/horologe.svg">
        <p class="description">{{'POLL-PLANNED.RESEND-TIME' | translate}}{{timeDiffHours}}</p>
      </div>
    </div>
    <div *ngIf="hasAssessorsButtons" class="assessor-buttons">
      <button class="button-blue-dash" (click)=getInactiveAssessors()>{{'TIMER.ASS-LISTE' | translate}}</button>
      <button *ngIf="canResendAssessors == true && inactiveAssessors > 0" class="blue-button" (click)=relaunchAssessors()>{{'TIMER.ASS-RELAUNCH' | translate}}</button>
      <button *ngIf="canResendAssessors == false || (inactiveAssessors == 0 && !isAssessorPreview)" class="inactive-button">{{'TIMER.ASS-RELAUNCH' | translate}}</button>
    </div>
    <div *ngIf="isAssessorPreview" style="text-align: center;">
      <button *ngIf="inactiveAssessors != 0" class="blue-button" (click)="navigateToOpening()">{{'TIMER.PREVIEW' | translate}}</button>
      <button *ngIf="inactiveAssessors == 0" class="blue-button" (click)="navigateToOpening()">{{'TIMER.PREVIEW-SEALED' | translate}}</button>
    </div>
    <div *ngIf="hasAssessorsButtons == true && canResendAssessors == false" style="text-align: center;">
      <div *ngIf="remainingAssessorTime != null" class="clock-holder">
        <img class="clock-icon" src="assets/horologe.svg">
        <p class="description">{{'POLL-PLANNED.ASS-RESEND-TIME' | translate}}{{remainingAssessorTime}}</p>
      </div>
      <div style="height: 20px"></div>
    </div>
    <div *ngIf="hasAssessorsButtons == true" style="text-align: center;">
      <div class="download-button" (click)="changeAssessors()">
        <span class="link-text">{{'CHANGE-ASSESSOR.LINK' | translate}}</span>
      </div>
    </div>
  </div>
</div>
