<div class="offers-holder">
  <div class="offer-line" *ngIf="SMSNeeded <= firstOffer">
    <span class="offer-text">{{'PRICE-DETAILS.200-SMS' | translate}} {{'PRICE-DETAILS.INVITES' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">120€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">200€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(1)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>
  <div class="offer-line" *ngIf="SMSNeeded <= 500">
    <span class="offer-text">{{'PRICE-DETAILS.500-SMS' | translate}} {{'PRICE-DETAILS.INVITES' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">180€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">400€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(2)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>
  <div class="offer-line" *ngIf="SMSNeeded <= 1000">
    <span class="offer-text">{{'PRICE-DETAILS.1000-SMS' | translate}} {{'PRICE-DETAILS.INVITES' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">240€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">600€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(3)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>
  <div class="offer-line" *ngIf="SMSNeeded <= 5000">
    <span class="offer-text">{{'PRICE-DETAILS.5000-SMS' | translate}} {{'PRICE-DETAILS.INVITES' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">960€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">2 400€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(4)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>
</div>