<div class="form-holder">
  <div *ngIf="!hideHeader" id="header">
    <img id="header-icon" src="assets/resolution-icon.svg">
    <div>
      <h1 class="title" *ngIf="currentPoll.votingType == 'election'">{{'POLL-CREATION.QUESTIONS.TITLE-CANDIDATES' | translate}}</h1>
      <h1 class="title" *ngIf="currentPoll.votingType == 'resolution'">{{'POLL-CREATION.QUESTIONS.TITLE-RESOLUTIONS' | translate}}</h1>

      <h1 class="title" *ngIf="currentPoll.votingType == 'list' && isMainList">{{numberOfList}}{{ordinal}} {{'POLL-CREATION.QUESTIONS.TITLE-CORPORATE' | translate}}</h1>
      <p *ngIf="currentPoll.votingType == 'list' && isMainList" class="description">{{'POLL-CREATION.QUESTIONS.CORPORATE-DESCRIPTION' | translate}} </p>
      <p *ngIf="(currentPoll.votingType == 'live' && isLiveResolution) || currentPoll.votingType == 'resolution'" class="description">{{'POLL-CREATION.QUESTIONS.RESOLUTIONS-DESCRIPTION' | translate}}</p>
      <p *ngIf="currentPoll.votingType == 'election' && currentPoll.temporality == 'live'" class="description">{{'POLL-CREATION.QUESTIONS.CANDIDATES-DESCRIPTION' | translate}} </p>
      <p *ngIf="currentPoll.votingType == 'election' && currentPoll.temporality != 'live'" class="description">{{'POLL-CREATION.QUESTIONS.CANDIDATES-DESCRIPTION-SCHEDULED' | translate}} </p>
    </div>
  </div>
  <div class="safe-area">

    <div *ngIf="currentPoll.votingType == 'resolution'">
      <div *ngFor="let resolution of resolutions; let i = index; trackBy: trackByFn">
        <vc-live-resolution [temporality]="currentPoll.temporality" [index]="i" [titleSet]="currentPoll.title"
          [resolution]="resolution" (sendResolution)="getResolution($event)" (sendCommand)="wsCommand($event)"
          (deleteEvent)="deleteResolution($event)" (linkEvent)="getResolution($event)" (sendAnswerImage)="sendResolutionImage($event)" (sendDeselect)="markDeselect()">
        </vc-live-resolution>
      </div>
    </div>

    <div *ngIf="currentPoll.votingType == 'election'">
      <div class="line-holder">
        <div style="width: 45px; height: 58px;"></div>
        <textarea id="{{'election-subject-' + index}}" class="election-subject" (keyup)="onTitleInput($event)" required pattern="\S+.*" maxlength="255"
        placeholder="{{'POLL-CREATION.QUESTIONS.SUBJECT-PLACEHOLDER' | translate}}" matInput cdkTextareaAutosize
        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8" value="{{subject}}"></textarea>

        <!-- <div class="delete-container"> -->
          <div class="icon-container">
            <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="closeInput()" id="input-{{index}}"/>
            <img *ngIf="!blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/grey-link.svg">
            <img *ngIf="blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/blue-link.svg">
          </div>
        <!-- </div> -->
        <div style="width: 14.5px; height: 58px;"></div>


      </div>

      <div [ngClass]="eventSubjectCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{eventSubjectCharacters}}/255</div>
      <vc-plurinominal-input [numberOfCandidates]="choices.length" [temporality]="currentPoll.temporality" [votingType]="currentPoll.votingType"
        (sendNumberOfVote)="sendVotersNumber($event)" [index]="index" [numberOfVote]="numberOfVote">
      </vc-plurinominal-input>
      <div *ngFor="let choice of choices; let i = index; trackBy: trackByFn">
        <vc-input-candidate [index]="i" [choice]="choice" (sendPollChoice)="getChoice($event)"
          (deleteEvent)="deleteChoice($event)" inputPlaceholder="{{'POLL-CREATION.QUESTIONS.CANDIDATE-INPUT-PLACEHOLDER' | translate}}"
          (linkEvent)="sendLinkCandidate($event)" [parentIdentifier]="index" (sendAnswerImage)="getCandidatesImages($event)" (sendDeselect)="markDeselect()">
        </vc-input-candidate>
      </div>
    </div>

    <div *ngIf="currentPoll.votingType == 'list'" class="corporate">
      <h1 *ngIf="isMainList" class="title-election">
        {{'POLL-CREATION.QUESTIONS.SET-TITLE-CORPORATE' | translate}} <span class="mandatory">{{'POLL-CREATION.QUESTIONS.MANDATORY-TITLE-CORPORATE' | translate}}</span>
      </h1>
      <textarea *ngIf="isMainList" class="subject election-title" (keyup)="onElectionTitleInput($event)"
        required pattern="\S+.*" maxlength="255" placeholder="{{'POLL-CREATION.QUESTIONS.ELECTION-TITLE-PLACEHOLDER' | translate}}"
        matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8" value="{{eventTitle}}" (focusout)="markDeselect()"></textarea>
      <div *ngIf="isMainList" [ngClass]="eventTitleCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{eventElectionTitleCharacters}}/255</div>

      <h1 class="title-list">
        {{'POLL-CREATION.QUESTIONS.SET-LIST-CORPORATE' | translate}} <span class="mandatory">{{'POLL-CREATION.QUESTIONS.MANDATORY-TITLE-CORPORATE' | translate}}</span>
      </h1>

      <div [ngClass]="index != 0 ? 'line-holder line-holder-special' : 'line-holder'">

        <div class="resolution-index" (click)="fileInput.click()" appDragDrop>
          <input style="display: none" type="file" accept=".jpeg, .jpeg, .gif, .png"
          (change)="onFileChanged($event.target.files); fileInput.value=''" #fileInput>
          <div id="uploaded-wrapper-{{index}}" class="list-images-wrapper">
            <img id="uploaded-{{index}}" class="list-images" src="#" alt="">
          </div>
        </div>

        <textarea class="listName election-title" (keyup)="onTitleInput($event)" required pattern="\S+.*" maxlength="255"
            placeholder="{{'POLL-CREATION.QUESTIONS.LIST-TITLE-PLACEHOLDER' | translate}}" matInput cdkTextareaAutosize
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8" value="{{subject}}" (focusout)="markDeselect()">
          </textarea>
        <div class="delete-container">
          <div class="icon-container">
            <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="closeInput()" id="input-{{index}}"/>
            <img *ngIf="!blueLinkIcon" class="icons"  style="margin-bottom: 2px;" (click)="revealInput()" src="assets/grey-link.svg">
            <img *ngIf="blueLinkIcon" class="icons"  (click)="revealInput()" style="margin-bottom: 2px;" src="assets/blue-link.svg">
          </div>
        </div>
        <div class="delete-placeholder"></div>

      </div>
      <div [ngClass]="eventTitleCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{eventSubjectCharacters}}/255</div>

      <div *ngFor="let choice of choices; let i = index; trackBy: trackByFn">
        <vc-input-candidate [index]="i" [choice]="choice" (sendPollChoice)="getChoice($event)" (linkEvent)="sendLinkCandidate($event)"
          (deleteEvent)="deleteChoice($event)" inputPlaceholder="{{'POLL-CREATION.QUESTIONS.LIST-INPUT-PLACEHOLDER' | translate}}"
          [parentIdentifier]="index" (sendAnswerImage)="getCandidatesImages($event)" (sendDeselect)="markDeselect()">
        </vc-input-candidate>
      </div>
    </div>

    <div *ngIf="currentPoll.votingType == 'resolution' || (currentPoll.votingType == 'live' && isLiveResolution)" class="add-button">
      <div class="button-container live-resolution-button-container">

        <white-dashed-button [buttonText]="'POLL-CREATION.QUESTIONS.ADD-RESOLUTION' | translate" [buttonPlus]="true"
        (addChoiceEvent)="addChoice()"></white-dashed-button>
      </div>
    </div>

    <div *ngIf="currentPoll.votingType == 'election' && !isLiveResolution" class="add-button">
      <div class="button-container">
        <button id="add-label" (click)=addChoice()>
          +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.QUESTIONS.ADD' | translate}}
        </button>
        <button *ngIf="isLaunchEnabled && isValid" id="launch-button"
          (click)="launchPoll()">{{'POLL-CREATION.QUESTIONS.LAUNCH' | translate}}</button>
        <button *ngIf="(!isLaunchEnabled || !isValid) && currentPoll.temporality == 'live'"
          class="launch-button-disabled">{{'POLL-CREATION.QUESTIONS.LAUNCH' | translate}}</button>
      </div>
    </div>

    <div *ngIf="currentPoll.votingType == 'list'" class="add-button">
      <div class="button-container">
        <button id="add-label" (click)=addChoice()>+&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.QUESTIONS.ADD-CANDIDATE-LIST' |
          translate}}</button>
        <button *ngIf="isLaunchEnabled && isValid" id="launch-button"
          (click)="launchPoll()">{{'POLL-CREATION.QUESTIONS.LAUNCH' | translate}}</button>
        <button *ngIf="(!isLaunchEnabled || !isValid) && currentPoll.temporality == 'live'"
          class="launch-button-disabled">{{'POLL-CREATION.QUESTIONS.LAUNCH' | translate}}</button>
      </div>
    </div>

  </div>
</div>
<div *ngIf="deleteEnabled" class="delete-question-holder">
  <button (click)="deleteQuestion()" class="delete-question-button">
    <img src="assets/white-trash.svg" class="trash" loading="lazy">
    &nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.DELETE-QUESTION' | translate}}</button>
</div>
<div *ngIf="deleteEnabled" class="separator-line"></div>

<!--
-- POPUP FOR BOTH MODE
-->
<vc-warning-popup class="image-error" *ngIf="displayImageError"
  [understoodButton]="true" (endDetailsPopup)="dismissPayPopup()"
  title="{{'UPLOAD-IMAGES.POPUP-TEXT' | translate}}">
</vc-warning-popup>
