import { createReducer, MetaReducer, ActionReducer, on, Action } from "@ngrx/store"
import { setLiveResolutionsAction } from "./livePollActions";
import { Resolution } from "../models/PollChoice";



export interface LiveState {
    poll: {
        resolutions: Resolution
    }
}

export const defaultPoll: LiveState = {
    poll: {
        resolutions: null
    }
}

function log(reducer: ActionReducer<LiveState, Action>) : ActionReducer<LiveState, Action> {
    return (state, action) => {
        const currentState = reducer(state, action);
         
        return currentState
    }
}

export const metaLiveReducer: MetaReducer = log

export const liveReducer = createReducer(defaultPoll, 
    on(setLiveResolutionsAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                resolutions: props.resolutions,
            }
        }
    })
)