import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'vc-poll-planned-summary',
  templateUrl: 'poll-planned-summary.component.html',
  styleUrls: ['./poll-planned-summary.component.less']
})

export class PollPlannedSummaryComponent implements OnInit {
  @Input() description: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() votersCount: number;
  @Input() isSms: boolean;
  @Input() is2FA: boolean;
  @Input() isPublic: boolean;
  @Input() sharedLinks: string;

  constructor() {
  }

  replaceURLs(message: string) {
    if(!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

  ngOnInit() {
  }
}
