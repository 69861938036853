<div class="div-arriere-plan-action" (click)="clicAction($event)"></div>
<div class="div-global-action">
  <div id="center-square">
    <img class="close-icon" src="assets/close_24px.svg" (click)="cancelDetails(false)">
    <div class="text-holder">
      <img src="assets/image.png" class="keys" loading="lazy">

      <h1 *ngIf="title !== undefined" class="header_title title">{{title}}</h1>
      <h1 *ngIf="firstText !== undefined && !displayInput" class="header_title">{{firstText}}</h1>
      <h1 *ngIf="secondText !== undefined && !displayInput" class="header_title">{{secondText}}</h1>
      <h1 *ngIf="thirdText !== undefined && !displayInput" class="header_title">{{thirdText}}<a class="resend" (click)="showMailInput()">{{'CONNECT.RESEND-LINK' | translate}}</a></h1>
      <h1 *ngIf="displayInput" class="header_title">Merci de nous indiquer votre adresse d’inscription afin de recevoir votre e-mail de validation de compte.</h1>
    </div>

    <div *ngIf="displayInput">
      <label for="email">Email</label>
      <input type="email" (keyup)="resetError()" class="login-field w-input" maxlength="256" name="email" data-name="email" placeholder="Votre email" id="popup-email" required pattern="/^\S+@\S+$/">
      <p *ngIf="inputError == true" class="unselected-condition">{{'CONNECT.HINT-MAIL' | translate}}</p>
      <!-- <div *ngIf="inputError == true" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT' | translate}}</span></div> -->
    </div>

    <div class="button-holder">
      <button *ngIf="!displayInput" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'DETAILS-CONTACT.UNDERSTOOD' | translate}}
      </button>
      <button *ngIf="displayInput" type="button" id="button-vote" (click)="sendEmail()">
        {{'CONNECT.RESEND-BUTTON' | translate}}
      </button>
    </div>
  </div>
</div>
