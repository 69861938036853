<div *ngIf="index != 0" class="top-line"></div>
<div class="question-header">
  <h2 class="index-number">{{index+1}}</h2>
  <h5 class="description">{{result.question === '' ? '?' : result.question}}</h5>
  <div class="spacer-20"></div>
</div>
<vc-poll-results-result
  *ngFor="let resultIterator of result.results"
  [result]="resultIterator">
</vc-poll-results-result>
