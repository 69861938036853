
export class PaymentData{
  constructor(public paymentMethodId: string, public items: Array<BoughtItem>) {
  }
}

export class BoughtItem{
  constructor(public priceId: string, public quantity: number) {
  }
}

export class ShoppingCart{
  constructor(public items: Array<BoughtItem>, public tax: string, public coupon: string) {
  }
}

export class AssoConnectData{
  constructor(public organizationBaseUrl: string, public organizationUlid: string, public token: string) {
  }
}

export class ChangeVoterForm{
  constructor(public oldEmail: string, public newEmail: string, public language: string) {
  }
}

export class ValidateVoterChangeForm{
  constructor(public email: string) {}
}
