import { Component, Input } from '@angular/core';
import { ResolutionResult } from '../../../models/Poll';
import {Router} from '@angular/router';

@Component({
  selector: 'vc-poll-results-resolution-list',
  templateUrl: './poll-results-resolution-list.component.html',
  styleUrls: ['./poll-results-resolution-list.component.less'],
  styles: [`
    ngb-progressbar {
      margin-top: 5rem;
    }
  `]
})
export class PollResultsResolutionListComponent {
  @Input() result: ResolutionResult;
  @Input() index: number;
  show = true;



  constructor(private router: Router) {
  }


  seeMore() {
    this.show = false;
  }

  seeLess() {
    this.show = true;
  }

  ngOnInit() {
  }

}
