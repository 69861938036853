<div id="page-container">
  <div id="content-wrap">
    <vc-poll-pages-header [title]="title" [isLive]="isLive" [isPublic]="isPublic"
    [SMSOption]="isSms" [is2FA]="is2FA" [isAssessors]="hasAssessorsButtons"></vc-poll-pages-header>
    <vc-poll-planned-summary [description]="description" [startDate]="startDate" [endDate]="endDate" [votersCount]="votersCount"
      [isSms]="isSms" [isPublic]="isPublic" [is2FA]="is2FA"  [sharedLinks]="sharedLinks">
    </vc-poll-planned-summary>
    <app-poll-planned-timer [timeDelta]="timeDifference" [timeDiffHours]="remainingTime"
      (shouldLaunchConvocations)="displayConvocationPopup()" [canSendConvocations]="canSendConvocation"
      [canResendAssessors]="canResendAssessors" [remainingAssessorTime]="remainingAssessorTime"
      (shouldRelaunchAssessors)="relaunchAssessors()" [hasAssessorsButtons]="hasAssessorsButtons"
      (shouldGetAssessors)="displayAssessors()" [inactiveAssessors]="remainingAssessors" [assessorCount]="assessors"
      (sendChangeAssessor)="showChangeAssessors()">
    </app-poll-planned-timer>

    <div *ngIf="votingType == 'resolution'">
      <vc-poll-planned-list class="resolutions" [votingType]="votingType" [answers]="answers"
      (editionEventEmitter)="editPoll()" [shouldEdit]="true">
      </vc-poll-planned-list>
    </div>
    <div *ngIf="votingType == 'election'">
      <!-- Condition should edit a revoir potentiellement -->
      <!-- numberOfVote -->
      <vc-poll-planned-list *ngFor="let candidat of candidates, let index = index" class="election" [votingType]="votingType" [candidates]="candidat"
      [answers]="answers[0]" [numberOfVote]="answers[0].numberOfVote" (editionEventEmitter)="editPoll()" [shouldEdit]="answers[0].displayedQuestions.length <= 1"
      [index]="index">
      </vc-poll-planned-list>
      </div>
      <div *ngIf="votingType == 'list'">
      <vc-poll-planned-list *ngFor="let list of lists" class="election" [votingType]="votingType" [titles]="titles"
      [answers]="list" [numberOfVote]="list.numberOfVote" (editionEventEmitter)="editPoll()" [shouldEdit]="false">
      </vc-poll-planned-list>
    </div>
  </div>
</div>
<div *ngIf="isError">
  <app-error
    (isClosed)="common.navigateToPolls()"
    [errorMessage]="errorMessage">
  </app-error>
</div>
<div *ngIf="shouldDisplayConvocationWarning">
  <app-voters-relaunch (isClosed)="handleConvocations($event)" [isResend]=false>
  </app-voters-relaunch>
</div>
<div *ngIf="shouldAssessorConvocationWarning">
  <app-voters-relaunch (isClosed)="launchAssessorsRelaunch($event)" [isResend]=false [isAssessors]=true>
  </app-voters-relaunch>
</div>
<div *ngIf="shouldDisplayAssessors">
  <vc-warning-popup title="{{'REMAINING-ASSESSORS' | translate}}" [mails]="inactiveAssessors" [understoodEndButton]="true"
    (endDetailsPopup)="dismiss1Assessors()" [separator]="false">
  </vc-warning-popup>
</div>
<div *ngIf="displayChangeAssessor">
  <app-assessor-change-component [pollId]="pollId" (closeChangeAssessor)="closeChangeAssessor($event)"></app-assessor-change-component>
</div>
<div *ngIf="shouldDisplayChangeAssessorValidation">
  <app-valid-changes (close)="closeChangeValidation()"></app-valid-changes>
</div>
