export class TimeDelta {
  public days: number;
  public hours: number;
  public minutes: number;
  public seconds: number;
  constructor(days: number, hours: number, minutes: number, seconds: number) {
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }
}
