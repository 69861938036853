import { Component, Input } from '@angular/core';

@Component({
    selector: 'vc-poll-card-header',
    templateUrl: 'poll-card-header.component.html',
    styleUrls: ['./poll-card-header.component.less']
  })

export class PollCardHeaderComponent {
  @Input() image: string;
  @Input() title: string;
  @Input() description: string;
}
