<div class="offers-holder">
  <div class="offer-line" *ngIf="licenseTime == 'D'">
    <span class="offer-text">{{'PRICE-DETAILS.HOTLINE-ONE' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">90€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">190€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(0)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>

  <div class="offer-line" *ngIf="licenseTime == 'DM'">
    <span class="offer-text">{{'PRICE-DETAILS.HOTLINE-FIVE' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">180€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">390€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(1)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>

  <div class="offer-line" *ngIf="licenseTime == 'Y'">
    <span class="offer-text">{{'PRICE-DETAILS.HOTLINE-YEAR' | translate}}</span>
    <span *ngIf="isAssoc" class="offer-price">240€ {{'PRICE-DETAILS.WITH-TAXES' | translate}}</span>
    <span *ngIf="!isAssoc" class="offer-price">590€ HT</span>
    <button type="button" class="button-add" (click)="sendOption(2)">
      {{'PRICE-DETAILS.ADD-BUTTON' | translate}}
    </button>
  </div>

</div>