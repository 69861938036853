import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import {
  BoughtItem,
  ShoppingCart,
} from "./../../models/PaymentData";
import { environment } from "../../../environments/environment";
import { OfferCommand, OfferInfo } from "../../models/Enums";

@Component({
  selector: "vc-offers-popup",
  templateUrl: "./offers-popup.component.html",
  styleUrls: ["./offers-popup.component.less"],
})
export class OffersPopup {
  @Input() source: string;
  @Input() voters;
  @Input() isAssoc = true;
  @Output() moreInfoEmitter = new EventEmitter<Array<number>>();
  @Output() displaySMSEmitter = new EventEmitter<number>();
  @Output() isClosed = new EventEmitter<boolean>();
  @Output() organization = new EventEmitter<string>();
  @Output() freeTrial = new EventEmitter<boolean>();
  @Output() selectedOffer = new EventEmitter<ShoppingCart>();

  smsAdditionalOption: boolean = false
  selectedCardIndex = 0;
  status = 0;
  stripe: stripe.Stripe;
  stripeConstants = environment.stripeConstants;

  constructor(
    public auth: AuthService,
  ) {}

  defineActive($event: { target: { checked: boolean } }) {
    const asso = document.getElementById("asso");
    const company = document.getElementById("company");
    this.isAssoc = !$event.target.checked;
    if ($event.target.checked) {
      company.className = "active";
      asso.className = "inactive";
    } else {
      company.className = "inactive";
      asso.className = "active";
    }
  }

  clicAction($event) {
    if ($event.target.className === "div-arriere-plan-action") {
      this.isClosed.emit(false);
    }
  }

  receiveSelection($event: OfferCommand) {
    switch ($event.offer) {
      case OfferInfo.FreeTest: {
        this.freeTrial.emit(true);
        break;
      }
      case OfferInfo.MoreInfo: {
        this.selectedCardIndex = $event.index;
        this.status = 1;
        this.cancelSelection()
        this.moreInfoEmitter.emit([this.selectedCardIndex, Number(this.isAssoc)])
        break;
      }
      case OfferInfo.Buy: {
        this.selectedCardIndex = $event.index;
        this.isAssoc ? this.addSMSForAsso() : this.selectOffer(this.selectedCardIndex);
        break;
      }
    }
  }

  addSMSForAsso() {
    this.cancelSelection()
    this.displaySMSEmitter.emit(this.selectedCardIndex)
  }

  selectOffer(index: number, smsOption: string = "") {
    switch (index) {
      case 0: {
        let price = this.isAssoc
          ? this.stripeConstants.event.association
          : this.stripeConstants.day.corp50;
        this.buyOffer(price, smsOption);
        break;
      }
      case 1: {
        let price = this.isAssoc
          ? this.stripeConstants.pack.asso50
          : this.stripeConstants.day.corp1000;
        this.buyOffer(price, smsOption);
        break;
      }
      case 2: {
        let price = this.isAssoc
          ? this.stripeConstants.pack.asso1000
          : this.stripeConstants.pack.corp50;
        this.buyOffer(price, smsOption);
        break;
      }
      case 3: {
        let price = this.isAssoc
          ? this.stripeConstants.pack.asso2ag
          : this.stripeConstants.pack.corp1000;
        this.buyOffer(price, smsOption);
        break;
      }
    }
  }

  buyOffer(offer: string, smsCode: string = "") {
    console.log("buyOffer");
    const items = smsCode.length < 1 ? [new BoughtItem(offer, 1)] : [new BoughtItem(offer, 1), new BoughtItem(smsCode, 1)];
    let cart = new ShoppingCart(
      items,
      this.stripeConstants.tax20,
      null
    );
    this.selectedOffer.emit(cart)
  }

  cancelSelection() {
    this.isClosed.emit(true);
  }

  ngAfterViewInit() {
    const switchInput = document.getElementById("switch-container");
    if (!this.isAssoc)
      switchInput.click()
  }

  ngOnInit() {
    this.stripe = Stripe(this.stripeConstants.key);
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.cancelSelection();
      }
    })
  }
}
