<div class="line-holder">
  <h3 class="resolution-index">{{index+1}}</h3>
  <div class="fields">
    <div class="input-unit">
      <textarea type="text" class="names name-assessor" (keyup)="onNameInput($event)"
      (keyup)="common.autoHeight($event, 10)" value="{{name}}" maxlength="255" (focusout)="saveAssessorToDraft()"
      style="height: 26px;" placeholder="{{'ASSESSORS.NAME-PLACEHOLDER' | translate}}"
      [ngClass]="!displayError && isValidName ? 'mail-correct' : 'mail-error'"></textarea>
    </div>
    <div [ngClass]="nameCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{nameCharacters}}/255</div>
    <div class="input-unit">
      <textarea type="text" class="names mail-assessor" (keyup)="onMailInput($event)"
      (keyup)="common.autoHeight($event, 10)" value="{{mail}}" maxlength="255" style="height: 26px;"
      placeholder="{{'ASSESSORS.MAIL-PLACEHOLDER' | translate}}" (focusout)="saveAssessorToDraft()"
      [ngClass]="isValidMail == true && !displayError ? 'mail-correct' : 'mail-error'"></textarea>
    </div>
    <div [ngClass]="mailCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{mailCharacters}}/255</div>
  </div>

  <div *ngIf="assessorsSize > 3" class="delete-container">
    <div class="icon-container">
      <img id="delete-button" (click)="delete()" src="assets/deletebutton.svg">
    </div>
  </div>

  <div *ngIf="assessorsSize < 4" class="delete-placeholder"></div>
</div>

