import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  public constructor(private titleService: Title) {
    const userLang = navigator.language || navigator['userLanguage'];
    if (userLang.substring(0, 2) === 'fr') {
      this.setTitle('V8TE - vote en ligne sécurisé');
    } else {
      this.setTitle('V8TE - secure online voting');
    }
   }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}
