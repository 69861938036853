<div class="form-holder">
  <div class="safe-area">

    <h1  *ngIf="votingType == 'resolution' || votingType == 'live'" id="title">{{'POLL-PLANNED.RESOLUTION' | translate}}</h1>
    <h1  *ngIf="votingType == 'list'" id="title">{{titles[answers.round]}}</h1>
    <a href="{{blockchainLink}}" target="_blank" rel="noopener noreferrer" *ngIf="votingType == 'list' && displayBlockchain" class="blockchain-link">
      <img src="assets/global.svg" class="global-icon" /> {{'POLL-PLANNED.POLL-BLOCKCHAIN' | translate}}
    </a>
      <div class="container-answers" *ngIf="isElection">
        <ng-container *ngIf="votingType == 'list'">
          <ng-container *ngFor="let subject of subjects, let parent = index">
            <div class="container-candidate">
              <div class="left-block block"></div>
              <div class="right-block block subject-block" style="font-weight: 900;">{{translateAnswer(subject)}}</div>
            </div>
            <ng-container *ngFor="let answer of displayedAnswers[parent]; let index = index">
              <div class="container-candidate">
                <div class="left-block block">{{index + 1}}</div>
                <div class="right-block block">{{answer}}</div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="votingType == 'election'">
            <div class="container-candidate">
              <div class="left-block block"></div>
              <div class="right-block block">{{subjects[index]}}</div>
            </div>

            <div class="container-candidate">
              <div class="left-block block"></div>
              <div class="numberOfVote">
                <p class="limit-text">{{'POLL-CREATION.VOTERS.PLURINOMINAL' | translate}}</p>
                <div class="limit-voters" id="pluri-{{index}}">{{answers.displayedQuestions[index].numberOfVote}}</div>
              </div>
            </div>

            <ng-container *ngFor="let candidate of candidates.answers; let index = index">
              <div class="container-candidate">
                <div class="left-block block">{{index + 1}}</div>
                <div class="right-block block">{{candidate}}</div>
              </div>
            </ng-container>
        </ng-container>
      </div>
    <ng-container *ngIf="votingType == 'resolution'">
      <div class="container-resolution" *ngFor="let item of answers; let indexOfelement = index;">
        <div class="left-block block">{{indexOfelement + 1}}</div>
        <div class="right-block block">{{item.question}}</div>
      </div>
    </ng-container>
  </div>
</div>
