import { Component, Output, Input, EventEmitter } from "@angular/core";
import { Voter } from "../../../models/Voter";

@Component({
  selector: "vc-sms-details-popup",
  templateUrl: "sms-details-popup.component.html",
  styleUrls: ["./sms-details-popup.component.less"],
})
export class SMSDetailsPopup {
  @Input() voters: Array<Voter>;
  @Input() isAssoc: boolean;
  @Input() SMSOwned: number = 0;

  @Output() selectedOption = new EventEmitter<number>();

  firstOffer = 200;
  secondOffer = 500;
  thirdOffer = 1000;
  fourthOffer = 2000;
  votersLength = 0;
  SMSNeeded = 0;


  sendOption(index: number) {
    this.selectedOption.emit(index)
  }

  constructor() {}

  ngOnInit() {
    if (this.voters !== undefined) {
      this.votersLength = this.voters.length;
      this.SMSNeeded = this.votersLength - this.SMSOwned
    }
  }
}
