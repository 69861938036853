<div class="div-arriere-plan-action" (click)="closeChangeVoters()"></div>
<div class="div-global-action-modified" style="flex-direction: column">
  <div class="fake-blue-spacer"></div>
  <img class="close-icon" src="assets/close_24px.svg" (click)="closeChangeVoters()">
  <vc-poll-card-header [image]="'assets/resolution-icon.svg'" [title]="'CHANGE-ASSESSOR.TITLE' | translate" [description]="'CHANGE-ASSESSOR.DESCRIPTION' | translate">
  </vc-poll-card-header>
  <div class="line-holder" style="margin-top: 24px">
    <input type="email" id="old-email" class="mail-input" maxlength="250" (keyup)="updateCounters()" (change)="validateVoter()" placeholder="{{ 'CHANGE-VOTER.MAIL-OLD' | translate }}">
  </div>
  <div class="error-line-holder" *ngIf="emailVerificationStatus == 4 || emailVerificationStatus == 3">
    <div *ngIf="emailVerificationStatus === 4" class="error-message">{{'CHANGE-VOTER.ERROR-404' | translate}}</div>
    <div *ngIf="emailVerificationStatus === 3" class="error-message">{{'CHANGE-VOTER.ERROR-403' | translate}}</div>
    <div class="character-counter-emb">{{oldMailCharacters}}/250</div>
  </div>
  <div *ngIf="emailVerificationStatus != 4 && emailVerificationStatus != 3" class="character-counter">{{oldMailCharacters}}/250</div>
  <div class="line-holder">
    <input type="email" id="new-email" class="mail-input" maxlength="250" placeholder="{{ 'CHANGE-VOTER.MAIL-NEW' | translate }}" (keyup)="updateCounters()">
  </div>
  <div class="character-counter">{{newMailCharacters}}/250</div>
  <div class="line-holder">
    <input type="email" id="new-email-2" [ngClass]="differentValuesEmails ? 'mail-input' : 'mail-input-red'" maxlength="250" placeholder="{{ 'CHANGE-VOTER.MAIL-CONFIRM' | translate }}" (keyup)="updateCounters()">
  </div>
  <div class="character-counter">{{confirmMailCharacters}}/250</div>
  <div class="shorter-line">
    <input type="checkbox" id="confirmation-checkbox">
    <p class="disclaimer">{{ 'CHANGE-ASSESSOR.CHECK-DESCRIPTION' | translate }}</p>
  </div>
  <div class="send-holder">
    <button class="red-button" *ngIf="areFieldsValid()" (click)="changeMail()" style="margin-bottom: 12px">{{ 'CHANGE-ASSESSOR.BUTTON' | translate }}</button>
    <button *ngIf="!areFieldsValid()" class="inactive-button">{{ 'CHANGE-ASSESSOR.BUTTON' | translate }}</button>
  </div>
</div>
