import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {EditedAnswer} from '../../../../models/PollChoice';
import { ref } from 'firebase/storage';
import { storage } from 'src/app/firebase';
import { v4 } from 'uuid';

@Component({
  selector: 'vc-added-candidate-input',
  templateUrl: 'input-candidate.component.html',
  styleUrls: ['./input-candidate.component.less']
})

export class AddedCandidateInput implements OnInit {
  @Input() index: number;
  @Output() sendPollChoice = new EventEmitter<EditedAnswer>();
  @Output() deleteEvent = new EventEmitter<number>();
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  @Output() linkEvent = new EventEmitter<[number, string]>();
  editedObject: EditedAnswer;
  eventTitleCharacters = 0;
  displayImageError = false;
  blueLinkIcon: boolean;

  constructor() {
  }

  onNameInput(event: { target: { value: string; }; }) {
    this.editedObject.answer = event.target.value;
    this.eventTitleCharacters = event.target.value.length;
    this.sendPollChoice.emit(this.editedObject);
  }

  getInput() {
    const floatingInput = document.getElementById(`input-${this.index}`);
    floatingInput.style.display = 'block';
    floatingInput.focus();
  }

  closeInput() {
    const floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement;
    floatingInput.style.display = 'none';
    if (floatingInput.value != undefined && floatingInput.value.length > 0) {
      const link = floatingInput.value.trim();
      if (link.length > 0) {
        this.blueLinkIcon = true;
      }
    } else {
      this.blueLinkIcon = false;
    }
    this.linkEvent.emit([this.index, floatingInput.value]);
  }

  onFileChanged(event) {
    const displayImage = document.getElementById(`uploaded-${this.index}`) as HTMLImageElement;
    const wrapper = document.getElementById(`uploaded-wrapper-${this.index}`) as HTMLImageElement;
    const candidateIndex = document.getElementById(`candidate-index-${this.index}`) as HTMLImageElement;

    const reader = new FileReader();
    const extensionImage = event[0].name.substring(event[0].name.lastIndexOf('.'));

    const imageRef = ref(storage, `${v4()}${extensionImage}`);
    const imageBlob = new Blob(event);

    reader.readAsDataURL(imageBlob);

    if (event[0].size > 2097152) {
      this.displayImageError = true;
    }

    const image = new Image();
    const objectUrl = URL.createObjectURL(event[0]);
    image.onload = function() {

      if (image.width !== image.height) {
        this.displayImageError = true;
      }
      if (this.displayImageError === false) {
        wrapper.style.display = 'flex';
        candidateIndex.style.display = 'none';
        displayImage.src = URL.createObjectURL(imageBlob);
        this.sendAnswerImage.emit([imageRef, imageBlob, this.index]);
      }
      URL.revokeObjectURL(objectUrl);
    }.bind(this, event);
    image.src = objectUrl;
  }

  dismissPayPopup() {
    this.displayImageError = false;
  }

  delete() {
    this.deleteEvent.emit(this.index);
  }

  ngOnInit() {
    this.editedObject = new EditedAnswer(this.index, undefined);
  }
}
