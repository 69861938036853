<!DOCTYPE html><!--  This site was created in Webflow. http://www.webflow.com  -->
<!--  Last Published: Mon Dec 06 2021 14:53:06 GMT+0000 (Coordinated Universal Time)  -->
<!-- <html data-wf-page="618b982cfc404c22b8ff9d0a" data-wf-site="61715e9a57ec0ef0de74fa80"> -->
<!-- <head> -->
  <!-- <meta charset="utf-8"> -->
  <!-- <title>Inscription</title> -->
  <!-- <meta content="Inscription" property="og:title"> -->
  <!-- <meta content="https://uploads-ssl.webflow.com/61715e9a57ec0ef0de74fa80/617c2b88daa3588d9b8d22dd_v8te_logo_shortversion.png" property="og:image"> -->
  <!-- <meta content="Inscription" property="twitter:title"> -->
  <!-- <meta content="https://uploads-ssl.webflow.com/61715e9a57ec0ef0de74fa80/617c2b88daa3588d9b8d22dd_v8te_logo_shortversion.png" property="twitter:image"> -->
  <!-- <meta content="width=device-width, initial-scale=1" name="viewport"> -->
  <!-- <meta content="Webflow" name="generator"> -->
  <!-- <link href="../../css/normalize.css" rel="stylesheet" type="text/css">
  <link href="../../css/webflow.css" rel="stylesheet" type="text/css">
  <link href="../../css/new-v8te.webflow.css" rel="stylesheet" type="text/css"> -->
  <!-- <script type="text/javascript">WebFont.load({  google: {    families: ["Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic","Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic","Manrope:regular,500,600,700,800"]  }});</script> -->
  <!-- [if lt IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js" type="text/javascript"></script><![endif] -->
  <!-- <script type="text/javascript">!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);</script> -->
  <!-- <link href="../images/favicon.jpg" rel="shortcut icon" type="image/x-icon"> -->
  <!-- <link href="../images/webclip.jpg" rel="apple-touch-icon"> -->
<!-- </head> -->
<!-- <body class="body"> -->
<body class="body">
  <div class="header-login">
    <a href={{backURL}} class="w-inline-block"><img src="assets/v8te_logo.png" loading="lazy" sizes="150px" srcset="assets/v8te_logo-p-500.png 500w, assets/v8te_logo-p-800.png 800w, assets/v8te_logo-p-1080.png 1080w, assets/v8te_logo-p-1600.png 1600w, assets/v8te_logo-p-2000.png 2000w, assets/v8te_logo.png 2528w" alt="" class="logo-header-login"></a>
    <a href={{backURL}} class="link-block-2 w-inline-block"><img src="assetsfleche_menu.png" loading="lazy" sizes="(max-width: 767px) 25px, (max-width: 991px) 3vw, (max-width: 1919px) 25px, 1vw" srcset="assets/fleche_menu-p-500.png 500w, assets/fleche_menu.png 512w" alt="" class="image-6"></a>
  </div>
  <div *ngIf="subscribeValid != true" class="main-login-section wf-section">
    <div class="content-login">
      <div class="div-block-3">
        <h1 class="h1 h1-login">{{'SUBSCRIBE.TITLE' | translate}}</h1>
        <div>{{'SUBSCRIBE.DESCRIPTION' | translate}}</div>
        <div class="login-form w-form">
          <form id="email-form" name="email-form" data-name="Email Form" class="login-form-grid">
            <div id="w-node-f1044e8b-da19-88d2-d6bc-eadeb197c642-b8ff9d0a">
              <label for="email">{{'SUBSCRIBE.EMAIL' | translate}}</label>
              <input type="email" class="login-field w-input" maxlength="256" name="email" data-name="email" (keyup)="resetTooltips()" placeholder="{{'CONNECT.MAIL-PLACEHOLDER' | translate}}" id="email" required>
              <div *ngIf="popupStatus == 1" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT' | translate}}</span></div>
              <div *ngIf="popupStatus == 2" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT-MAIL' | translate}}</span></div>
              <div *ngIf="popupStatus == 6" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.USED-MAIL' | translate}}</span></div>
              <!-- <div *ngIf="popupStatus == 4" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.ERROR' | translate}}</span></div> -->
            </div>
            <div id="w-node-_95ec4354-544b-ef73-efec-a5ff230107db-b8ff9d0a">
              <label for="password">{{'SUBSCRIBE.PASSWORD' | translate}}</label>
              <input type="password" (focus)="triggerCriterias()" (focusout)="highlightCriterias()" class="login-field w-input" maxlength="256" name="password" data-name="password" (keyup)="onPasswordInput($event)" placeholder="{{'CONNECT.PASSWORD-PLACEHOLDER' | translate}}" id="password" required>
              <div *ngIf="popupStatus == 3" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT' | translate}}</span></div>
              <div *ngIf="popupStatus == 4" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.CHARACTERS' | translate}}</span></div>
            </div>
            <div id="password-criterias">
              <div>
                <p class="criterias">{{'RENEW-PASSWORD.CRITERIAS' | translate}}</p>
              </div>

              <div class="wrapper">
                <div id="length" class="invalid-round">
                  <span class="checkmark checkmark-none"></span>
                </div>
                <p class="criterias">{{'RENEW-PASSWORD.LENGTH' | translate}}</p>
              </div>

              <div class="wrapper">
                <div id="elements" class="invalid-round">
                  <span class="checkmark checkmark-none"></span>
                </div>
                <p class="criterias">{{'RENEW-PASSWORD.ELEMENTS' | translate}}</p>
              </div>

              <div class="wrapper marged-wrapper">
                <div id="lower" class="invalid-round">
                  <span class="checkmark checkmark-none"></span>
                </div>
                <p class="criterias">{{'RENEW-PASSWORD.LOWER' | translate}}</p>
              </div>

              <div class="wrapper marged-wrapper">
                <div id="upper" class="invalid-round">
                  <span class="checkmark checkmark-none"></span>
                </div>
                <p class="criterias">{{'RENEW-PASSWORD.UPPER' | translate}}</p>
              </div>

              <div class="wrapper marged-wrapper">
                <div id="number" class="invalid-round">
                  <span class="checkmark checkmark-none"></span>
                </div>
                <p class="criterias">{{'RENEW-PASSWORD.NUMBER' | translate}}</p>
              </div>

              <div class="wrapper marged-wrapper">
                <div id="special" class="invalid-round">
                  <span class="checkmark checkmark-none"></span>
                </div>
                <p class="criterias">{{'RENEW-PASSWORD.SPECIAL' | translate}}</p>
              </div>
            </div>
            <label id="w-node-c49590e5-9528-d3f8-2767-a24e5fb47804-b8ff9d0a" class="w-checkbox">
              <input type="checkbox" name="terms-and-conditions" id="terms-and-conditions" data-name="terms and conditions" required class="w-checkbox-input" (click)="checked()">
              <span class="w-form-label" for="terms-and-conditions">
                {{'SUBSCRIBE.TERMS' | translate}}<a href="{{'SUBSCRIBE.LEGAL-LINK' | translate}}" class="login-terms-link">{{'SUBSCRIBE.TERMS-LINK' | translate}}</a>
              </span>
              <p *ngIf="popupStatus == 5" class="unselected-condition">{{'SUBSCRIBE.CONDITION' | translate}}</p>
            </label>
            <label id="w-node-_03fc5c1a-deac-5607-e8ca-38dfc6bfa92e-b8ff9d0a" class="w-checkbox">
              <input type="checkbox" name="newsletter" id="newsletter" data-name="newsletter" class="w-checkbox-input">
              <span class="w-form-label" for="newsletter">
                {{'SUBSCRIBE.NEWSLETTER' | translate}}<a href="#" class="login-terms-link"></a>
              </span>
            </label>
            <button data-wait="Création en cours" id="w-node-_5b1bd641-da28-6d30-abf4-b39d5bbc1401-1bdf2d9a" class="button-primary" style="border-radius: 50px;" (click)="subscribeUser()">{{'SUBSCRIBE.BUTTON' | translate}}</button>
          </form>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        <div class="div-block-2"></div>
        <div class="sign-in-methods">
          <a (click)="loginWithGoogle()" style="cursor: pointer;" class="sign-in-method w-inline-block"><img src="assets/Google_icon-icons.com_66793.png" loading="lazy" sizes="(max-width: 767px) 25px, (max-width: 991px) 3vw, (max-width: 1919px) 25px, 1vw" srcset="assets/Google_icon-icons.com_66793-p-500.png 500w, assets/Google_icon-icons.com_66793.png 512w" alt="" class="icon-singin">
            <div class="text-block-2">{{'CONNECT.GOOGLE' | translate}}</div>
          </a>
          <a (click)="loginWithLinkedin()" style="cursor: pointer;" class="sign-in-method w-inline-block"><img src="assets/linkedin-icon.png" loading="lazy" sizes="(max-width: 767px) 25px, (max-width: 991px) 3vw, (max-width: 1919px) 25px, 1vw" srcset="assets/linkedin-icon-p-500.png 500w, assets/linkedin-icon-p-800.png 800w, assets/linkedin-icon-p-1080.png 1080w, assets/linkedin-icon.png 2160w" alt="" class="icon-singin">
            <div class="text-block-2">{{'CONNECT.LINKEDIN' | translate}}</div>
          </a>
        </div>
        <div class="text-block-4" style="cursor: pointer;">{{'SUBSCRIBE.ACCOUNT' | translate}}<a (click)="redirectToConnection()" class="link">{{'SUBSCRIBE.CREATE' | translate}}</a>
        </div>
      </div>
    </div><img src="assets/page-login-v8te-connexion.svg" loading="lazy" alt="" class="img-login">
  </div>
  <div *ngIf="subscribeValid == true">
    <div class="main-section wf-section">
      <div class="confirm-container">
        <img src="assets/check-circle.svg" loading="lazy" alt="" class="valid-icon">
        <p class="confirm-title-password">{{'SUBSCRIBE.SUCCESS' | translate}}</p>
        <p class="confirm-description-password">{{'SUBSCRIBE.VALIDATION' | translate}} {{subscribeMail}}</p>
        <button type="submit" data-wait="J'ai compris" id="w-node-_5b1bd641-da28-6d30-abf4-b39d5bbc1401-b8ff9d0a" class="button-primary" style="border-radius: 50px;" (click)="redirectToConnection()">{{'UNDERSTOOD' | translate}}</button>
      </div>
    </div>
  </div>
  <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js" type="text/javascript"></script>
  <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61715e9a57ec0ef0de74fa80" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
</body>
