import {Voter} from "./Voter";

export enum ImportStatuses {
  responseOk = 0,
  errorEmail = 1,
  errorSms = 2,
  errorWeights = 3
}

export class ImportMessage {
  voters: Array<Voter>
  status: ImportStatuses

  static fromData(voters: Array<Voter>, status: ImportStatuses) {
    const data = new ImportMessage();
    data.voters = voters;
    data.status = status;
    return data;
  }
}
