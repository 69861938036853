<div class="div-arriere-plan-action"></div>
<div class="div-global-action">
  <div id="print-recommendations" class="content">
    <img width="70px" id="img_macaron" src="assets/warning.svg">
      <div class="recommendations-title">
        {{errorMessage}}
      </div>
      <div class="recommendations-content">
        {{errorDescription}}
      </div>
      <button type="button" class="button-see-more" (click)=onDismiss()>
        OK
      </button>
  </div>
</div>
