export enum OfferInfo {
  FreeTest,
  MoreInfo,
  Buy,
  SignUp,
}
export enum ImageTypes {
  Poll,
  Resolution,
  List,
  ListCandidate
}

export class OfferCommand {
  constructor(public offer: OfferInfo, public index: number) {}
}

export const PollImportKeys = {
  general: {
    title: "__EMPTY",
    description: "__EMPTY_1",
    startDate: "__EMPTY_2",
    endDate: "__EMPTY_4",
    sender: "__EMPTY_6",
    documents: "__EMPTY_7"
  },
  assessors: {
    name: "__EMPTY_8",
    email: "__EMPTY_9"
  },
  votes: {
    title: "__EMPTY_11",
    election: {
      prefix: "__EMPTY_",
      baseOffset: 12,
      title: 0,
      documents: 1,
      candidateName: 2,
      candidateLink: 3,
      diff: 4
    },
    separator: {
      first: "Title of the election 1\n (Mandatory field)",
      second: "Title of the election 2\n (Mandatory field)",
      firstWin: "Title of the election 1\r\n(Mandatory field)",
      secondWin: "Title of the election 2\r\n(Mandatory field)"
    }
  }
}

export const blacklistedSenders = ["Apple", "Microsoft", "Amazon", "Facebook", "Instagram", "tesla", "SpaceX", "Space X",
  "Alibaba", "Oracle", "Google", "Uber", "Twitter", "Airbnb", "Nasa", "Disney", "SFR", "TOTAL", "Carrefour", "GDF-SUEZ",
  "EDF", "PEUGEOT", "FRANCE TELECOM", "GROUPE AUCHAN", "AUCHAN", "SAINT-GOBAIN", "LOUIS DREYFUS", "E. LECLERC", "VEOLIA ENVIRONNEMENT",
  "GROUPE INTERMARCHE","INTERMARCHE", "RENAULT", "VINCI", "BOUYGUES", "SANOFI-AVENTIS", "AIRBUS", "AIRBUS SAS", "RALLYE",
  "CASINO", "VIVENDI", "SNCF", "AIR FRANCE", "AIR FRANCE-KLM", "KLM", "LA POSTE" , "ALSTOM", "DIOR", "CHRISTIAN DIOR",
  "L’OREAL", "L OREAL", "LVMH", "LOUIS VUITTON", "SCHNEIDER ELECTRIC", "MICHELIN", "SODEXO", "ALCATEL-LUCENT", "ALCATEL LUCENT",
  "DANONE", "Groupe DANONE","VINCI", "EIFFAGE", "THALES", "AIR LIQUIDE", "VEOLIA EAU", "VEOLIA", "SONEPAR", "COLAS",
  "REXEL", "AUTOMOBILES PEUGEOT", "CORA", "SAFRAN", "ESSO-SAF", "LA FRANCAISE DES JEUX", "BOUYGUES CONSTRUCTION",
  "BOUYGUES TELECOM", "PMU"];
