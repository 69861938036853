<div *ngIf="isExpanded == false" class="holder-column tio">
  <h3 class="cell-header">{{pollTitle}}</h3>
  <div *ngIf="polls.length == 0" class="no-polls">
    <h3 class="empty-label">{{'POLL-DASHBOARD.NONE' | translate}}</h3>
  </div>
  <div class="has-polls" *ngFor="let poll of polls; let i = index">
    <div *ngIf="i < 5">
      <vc-poll-dashboard-item *ngIf="category == 0" [pollData]="poll" [column]=0 (openPollEvent)="editPoll(poll.id)"
      (deletePollEvent)="refreshPolls()" (editPollEvent)="editPoll(poll.id)" (clonePollEvent)="clonePoll(poll.id, false)">
      </vc-poll-dashboard-item>
      <vc-poll-dashboard-item *ngIf="category == 1" [pollData]="poll" [column]=1 (openPollEvent)="navigateToPlannedPoll(poll.id)"
      (deletePollEvent)="refreshPolls()" (editPollEvent)="editPoll(poll.id)" (clonePollEvent)="clonePoll(poll.id, true)"
      [super]="super">
      </vc-poll-dashboard-item>
      <vc-poll-dashboard-item *ngIf="category == 2" [pollData]="poll" [column]=2 (openPollEvent)="navigateToOngoingPoll(poll.id)"
      (clonePollEvent)="clonePoll(poll.id, true)" [super]="super">
      </vc-poll-dashboard-item>
      <vc-poll-dashboard-item *ngIf="category == 3" [pollData]="poll" [column]=3 (openPollEvent)=navigateToClosedPoll(poll.id)
      (deletePollEvent)="refreshPolls()"  (clonePollEvent)="clonePoll(poll.id, true)">
      </vc-poll-dashboard-item>
    </div>
  </div>
  <div *ngIf="polls.length > 5">
    <h5 class="see-more" id="expand-{{category}}" (click)=seeMore(true)>{{'POLL-DASHBOARD.SEE-ALL' | translate}}
      ({{polls.length - 5}})</h5>
  </div>
</div>

<div *ngIf="isExpanded == true" class="holder-column-expanded">
  <h3 class="cell-header">{{pollTitle}}</h3>
  <div *ngIf="polls.length == 0" class="no-polls">
    <h3 class="empty-label">{{'POLL-DASHBOARD.NONE' | translate}}</h3>
  </div>
  <div class="has-polls" *ngFor="let poll of polls; let i = index">
    <vc-poll-dashboard-item *ngIf="category == 0" [pollData]="poll" [column]=0 (openPollEvent)="editPoll(poll.id)"
    (deletePollEvent)="refreshPolls()" (editPollEvent)="editPoll(poll.id)" (clonePollEvent)="clonePoll(poll.id, false)">
    </vc-poll-dashboard-item>
    <vc-poll-dashboard-item *ngIf="category == 1" [pollData]="poll" [column]=1 (openPollEvent)="navigateToPlannedPoll(poll.id)" [super]="super"
    (deletePollEvent)="refreshPolls()" (editPollEvent)="editPoll(poll.id)" (clonePollEvent)="clonePoll(poll.id, true)">
    </vc-poll-dashboard-item>
    <vc-poll-dashboard-item *ngIf="category == 2" [pollData]="poll" [column]=2 (openPollEvent)=navigateToOngoingPoll(poll.id)
    (deletePollEvent)="refreshPolls()" (editPollEvent)="editPoll(poll.id)" (clonePollEvent)="clonePoll(poll.id, true)" [super]="super">
    </vc-poll-dashboard-item>
    <vc-poll-dashboard-item *ngIf="category == 3" [pollData]="poll" [column]=3 (openPollEvent)=navigateToClosedPoll(poll.id)
    (deletePollEvent)="refreshPolls()" (clonePollEvent)="clonePoll(poll.id, true)">
    </vc-poll-dashboard-item>
  </div>
  <div *ngIf="polls.length > 5">
    <h5 class="see-more" (click)=seeMore(false)>{{'POLL-DASHBOARD.SEE-LESS' | translate}}</h5>
  </div>
</div>
