import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'disabled-button',
  templateUrl: 'disabled-button.component.html',
  styleUrls: ['./disabled-button.component.less', './../../common.less'],
})

export class DisabledButtonComponent implements OnInit {
  @Input() buttonText: string = "";
  @Input() buttonPlus: boolean = false;
  @Input() customWidthPx: number;
  @Input() customWidthPercent: number;
  @Output() createPollEvent = new EventEmitter<boolean>();

  ngAfterViewChecked() {
    if (this.customWidthPx != undefined) {
      const buttonElement = document.getElementsByClassName("publish-button-invalid")
      const button = buttonElement[0] as HTMLElement
      button.style.width = this.customWidthPx + "px"
    }
    if (this.customWidthPercent != undefined) {
      const buttonElement = document.getElementsByClassName("publish-button-invalid")
      const button = buttonElement[0] as HTMLElement
      button.style.width = this.customWidthPercent + "%"
    }
  }

  ngOnInit() {

      
  }
}
