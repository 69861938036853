<!DOCTYPE html><!--  This site was created in Webflow. http://www.webflow.com  -->
<!--  Last Published: Mon Dec 06 2021 14:53:06 GMT+0000 (Coordinated Universal Time)  -->
<html data-wf-page="61a36542864af9611bdf2d9a" data-wf-site="61715e9a57ec0ef0de74fa80">
<head>
  <meta charset="utf-8">
  <title>Connexion</title>
  <meta content="Connexion" property="og:title">
  <meta content="https://uploads-ssl.webflow.com/61715e9a57ec0ef0de74fa80/617c2b88daa3588d9b8d22dd_v8te_logo_shortversion.png" property="og:image">
  <meta content="Connexion" property="twitter:title">
  <meta content="https://uploads-ssl.webflow.com/61715e9a57ec0ef0de74fa80/617c2b88daa3588d9b8d22dd_v8te_logo_shortversion.png" property="twitter:image">
  <meta content="width=device-width, initial-scale=1" name="viewport">
  <meta content="Webflow" name="generator">
  <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js" type="text/javascript"></script>
  <script type="text/javascript">WebFont.load({  google: {    families: ["Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic","Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic","Manrope:regular,500,600,700,800"]  }});</script>
  <!-- [if lt IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js" type="text/javascript"></script><![endif] -->
  <script type="text/javascript">!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);</script>
  <link href="../images/favicon.jpg" rel="shortcut icon" type="image/x-icon">
  <link href="../images/webclip.jpg" rel="apple-touch-icon">
</head>
<body class="body">
  <div class="header-login">
    <a href={{backURL}} class="w-inline-block"><img src="assets/v8te_logo.png" loading="lazy" sizes="150px" srcset="assets/v8te_logo-p-500.png 500w, assets/v8te_logo-p-800.png 800w, assets/v8te_logo-p-1080.png 1080w, assets/v8te_logo-p-1600.png 1600w, assets/v8te_logo-p-2000.png 2000w, assets/v8te_logo.png 2528w" alt="" class="logo-header-login"></a>
    <a href={{backURL}} class="link-block-2 w-inline-block"><img src="assets/right-arrow.png" loading="lazy" alt="" class="image-6"></a>
  </div>
  <div *ngIf="!resendPassword && !passwordMailSent" class="main-login-section wf-section">
    <div class="content-login">
      <div class="div-block-3">
        <h1 class="h1 h1-login">{{'CONNECT.TITLE' | translate}}</h1>
        <div>{{'CONNECT.SUBTITLE' | translate}}</div>
        <div class="login-form w-form">
          <form id="email-form" name="email-form" data-name="Email Form" class="login-form-grid login-grid2" >
            <div id="w-node-f1044e8b-da19-88d2-d6bc-eadeb197c642-1bdf2d9a">
              <label for="email">{{'CONNECT.MAIL' | translate}}</label>
              <input (keyup)="emailInput($event)" type="email" class="login-field w-input" maxlength="256" name="email" (keyup)="resetTooltips()" data-name="email" placeholder="{{'CONNECT.MAIL-PLACEHOLDER' | translate}}" id="email" required pattern="/^\S+@\S+$/" style="margin-bottom: 4px;">
              <div *ngIf="popupStatus == 1" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT' | translate}}</span></div>
              <div *ngIf="popupStatus == 2" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT-MAIL' | translate}}</span></div>
              <div *ngIf="popupStatus == 4" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.ERROR' | translate}}</span></div>
              <div *ngIf="popupStatus == 6" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.NOT-VERIFIED' | translate}}</span></div>
            </div>
            <div id="w-node-_95ec4354-544b-ef73-efec-a5ff230107db-1bdf2d9a">
              <label for="password">{{'CONNECT.PASSWORD' | translate}}</label>
              <input type="password" class="login-field w-input" maxlength="256" name="password" (keyup)="resetTooltips()" data-name="password" placeholder="{{'CONNECT.PASSWORD-PLACEHOLDER' | translate}}" id="password" required style="margin-bottom: 4px;">
              <div *ngIf="popupStatus == 3" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT' | translate}}</span></div>
            </div>
            <button id="w-node-_5b1bd641-da28-6d30-abf4-b39d5bbc1401-1bdf2d9a" class="button-primary" style="border-radius : 50px;" (click)="loginWithPassword()">{{'CONNECT.BUTTON' | translate}}</button>
          </form>
        </div>
        <div class="text-block-5" style="cursor: pointer;">{{'CONNECT.NO-ACCOUNT' | translate}}<a (click)="redirectToSubscription()" class="link">{{'CONNECT.SUBSCRIBE' | translate}}</a>
        </div>
        <a (click)="forgotPasswordTrigger()" style="cursor: pointer;" class="link lt20">{{'CONNECT.FORGOTTEN-PASSWORD' | translate}}</a>
        <div class="div-block-2 db22"></div>
        <div class="sign-in-methods">
          <a (click)="loginWithGoogle()" style="cursor: pointer;" class="sign-in-method w-inline-block"><img src="assets/Google_icon-icons.com_66793.png" loading="lazy" sizes="(max-width: 767px) 25px, (max-width: 991px) 3vw, (max-width: 1919px) 25px, 1vw" srcset="assets/Google_icon-icons.com_66793-p-500.png 500w, assets/Google_icon-icons.com_66793.png 512w" alt="" class="icon-singin">
            <div class="text-block-2">{{'CONNECT.GOOGLE' | translate}}</div>
          </a>
          <a (click)="loginWithLinkedin()" style="cursor: pointer;" class="sign-in-method w-inline-block"><img src="assets/linkedin-icon.png" loading="lazy" sizes="(max-width: 767px) 25px, (max-width: 991px) 3vw, (max-width: 1919px) 25px, 1vw" srcset="assets/linkedin-icon-p-500.png 500w, assets/linkedin-icon-p-800.png 800w, assets/linkedin-icon-p-1080.png 1080w, assets/linkedin-icon.png 2160w" alt="" class="icon-singin">
            <div class="text-block-2">{{'CONNECT.LINKEDIN' | translate}}</div>
          </a>
        </div>
      </div>
    </div><img src="assets/page-login-v8te-connexion.svg" loading="lazy" alt="" class="img-login">
  </div>
  <vc-warning-popup *ngIf="isMobile" title="{{'POLL-WARNING.INFORMATION' | translate}}"
    firstText="{{'POLL-WARNING.MOBILE-POPUP' | translate}}" [understoodButton]="true"
    (endDetailsPopup)="dismissMobilePopup()">
  </vc-warning-popup>
  <!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->

  <div  *ngIf="resendPassword && !passwordMailSent" class="main-password-section wf-section">
    <div class="card-password">
      <img src="assets/image.png" class="keys" loading="lazy">
      <p class="resend-title-password">{{'CONNECT.PASSWORD-TITLE' | translate}}</p>
      <p class="resend-title-description">{{'CONNECT.PASSWORD-DESCRIPTION' | translate}}</p>
      <label>{{'COMMON.MAIL' | translate}}</label>
      <input (keyup)="emailInput($event)" type="email" class="login-field w-input" maxlength="256" name="email" data-name="email" placeholder="{{'CONNECT.MAIL-PLACEHOLDER' | translate}}" id="email" required pattern="/^\S+@\S+$/">
      <div *ngIf="popupStatus == 5" class="tooltip"><span class="tooltiptext"><img class="tooltip-icon" src="assets/warning.svg">{{'CONNECT.HINT-MAIL' | translate}}</span></div>
      <input type="submit" id="resendPassword" (click)="forgotPasswordMail()" value="{{'CONNECT.PASSWORD-BUTTON' | translate}}" data-wait="Connexion..." class="button-primary password-button w-button invalid-button">
      <p class="back-description">{{'CONNECT.BACK-PHRASE' | translate}} <span class="connection-link link lt20" (click)="backToConnect()">{{'CONNECT.BACK-ACTION' | translate}}</span></p>
    </div>
  </div>

  <div  *ngIf="passwordMailSent" class="main-password-section wf-section">
    <div class="card-password">
      <img src="assets/image.png" class="keys" loading="lazy">

      <p class="resend-title-password">{{'CONNECT.RESEND-CONFIRM' | translate}}</p>
      <p class="resend-title-description">{{'CONNECT.STEPS-HELP' | translate}}</p>
      <input type="submit" id="resendPassword" (click)="backToConnect()" value="{{'DETAILS-CONTACT.UNDERSTOOD' | translate}}" data-wait="Connexion..." class="button-primary password-button w-button">
    </div>
  </div>

  <vc-homepage-popup *ngIf="test == true" title="{{'CONNECT.ACTIVATION-NEEDED' | translate}}"
  firstText="{{'CONNECT.INSTRUCTION' | translate}}" secondText="{{'CONNECT.SPAM-WARNING' | translate}}"
  thirdText="{{'CONNECT.RESEND' | translate}}" (endDetailsPopup)="closePopup($event)" (resendUserMail)="resendUserMail($event)"
  [mail]="userMail"></vc-homepage-popup>
</body>
</html>
