import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../shared/api.service';
import {Common} from '../shared/common';
import {ReceivedPoll} from '../models/ReceivedPoll';
import {ShortVoter} from '../models/ShortVoter';
import {TimeDelta} from '../models/TimeDelta';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-poll-planned-assessor',
  templateUrl: './poll-planned-assessor.component.html',
  styleUrls: ['./poll-planned-assessor.component.less', '../poll-planned/poll-planned.component.less']
})
export class PollPlannedAssessorComponent implements OnInit {
  pollId: string;
  code: string;
  poll: ReceivedPoll;
  startDate: string;
  endDate: string;
  timeDifference = new TimeDelta(0, 0, 0, 0);
  answers: Array<any>;
  candidates: any;
  titles: Array<string> = [];
  lists = [];
  remainingAssessors: Array<ShortVoter> = [];
  shouldDisplayAssessors = false;
  isError = false;
  errorMessage: string;

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              public common: Common,
              private router: Router) { }

  displayAssessors() {
    this.apiService.getInactiveAssessorValidation(this.pollId, this.code).subscribe((res: Array<string>) => {
      this.remainingAssessors = res.map(x => ShortVoter.withEmail(x));
      this.shouldDisplayAssessors = true;
    });
  }

  dismissAssessors() {
    this.shouldDisplayAssessors = false;
  }

  navigateHome() {
    this.router.navigate(['/']);
  }

  navigateToOpening() {
    window.location.href = `${environment.settings.voteUrl}poll/${this.pollId}/manage?invitation=${this.code}`;
  }

  ngOnInit(): void {
    const pollIdKey = 'id';
    const validationCodeId = 'code';
    this.pollId = this.route.snapshot.params[pollIdKey];
    this.code = this.route.snapshot.params[validationCodeId];
    this.apiService.getInactiveAssessorValidation(this.pollId, this.code).subscribe((res: Array<string>) => {
      this.remainingAssessors = res.map(x => ShortVoter.withEmail(x));
    });
    this.apiService.getPollForAssessor(this.pollId, this.code).subscribe((res: ReceivedPoll) => {
      this.poll = res;
      this.startDate = new Date(res.startDate).toLocaleString();
      this.endDate = new Date(res.endDate).toLocaleString();
      if (res.type.localeCompare('resolution') === 0) {
        this.answers = res.rounds[0].displayedQuestions;
        this.lists = res.rounds;
      }
      if (res.type.localeCompare('election') === 0) {
        this.answers = res.rounds;
        if (res.rounds[0].displayedQuestions.length > 0) {
          this.answers = res.rounds;
          this.candidates = this.answers[0].displayedQuestions;
        }
      }
      if (res.type.localeCompare('list') === 0) {
        this.answers = res.rounds;
        this.lists = res.rounds;
        res.rounds.forEach(element => {
          this.titles.push(element.title);
        });
      }
      if (res.opened) { this.router.navigate([`poll-ongoing-assessor/${this.pollId}/${this.code}`]); }
      setInterval(() => {
        const timerDiff = (new Date(res.startDate)).getTime() - (new Date()).getTime();
        this.timeDifference = this.common.getTimeDelta(timerDiff);
      }, 1000);
    }, (err: any) => {
      this.isError = true;
      this.errorMessage = 'Nous sommes désolés mais vous ne pouvez accéder au contenu \nerreur' + err.status;
    });
  }

}
