import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as xlsx from 'xlsx';
import {JsonObject} from '@angular/compiler-cli/ngcc/src/utils';
import {
  setActivateAssessorsAction, setAssessorsMailsAction, setAssessorsNamesAction, setChoicesAction,
  setDescriptionAction,
  setDocumentAction,
  setEndDateAction,
  setEndTimeAction, setLiveQuestionAction,
  setSenderAction,
  setStartDateAction,
  setStartTimeAction,
  setTemporalityAction,
  setTitleAction,
  setVotingTypeAction
} from '../states/creationActions';
import {Voter} from '../models/Voter';
import {Store} from '@ngrx/store';
import {PollImportKeys} from '../models/Enums';
import {LiveQuestion, PollChoice} from '../models/PollChoice';

@Component({
  selector: 'app-poll-import',
  templateUrl: './poll-import.component.html',
  styleUrls: ['./poll-import.component.less']
})
export class PollImportComponent implements OnInit {

  arrayBuffer: any;
  worksheet: any;
  file: any;
  @Output() sendUpload = new EventEmitter<Array<number>>();

  constructor(private store: Store) { }

  onFileChanged(event: { target: { files: any[] } }) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.fileReader(this.file, Voter);
    }
  }

  private fileReader(file: any, line: any) {
    const fileReader = new FileReader();
    fileReader.onload = (_e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = [];
      for (let i = 0; i !== data.length; i++) {
        arr[i] = String.fromCharCode(data[i]);
      }

      const bstr = arr.join('');
      const workbook = xlsx.read(bstr, { type: 'binary', cellDates: true });
      const firstSheetName = workbook.SheetNames[0];

      const worksheet = workbook.Sheets[firstSheetName];
      this.worksheet = xlsx.utils.sheet_to_json(worksheet, { raw: true });
      this.matchingCell(this.worksheet);
    };
    fileReader.readAsArrayBuffer(file);
  }

  private matchingCell(worksheet: Array<JsonObject>) {
    this.store.dispatch(setTemporalityAction({ temporality: 'planned' }));
    this.store.dispatch(setVotingTypeAction({votingType: 'list'}));
    this.fillGeneralImport(worksheet[2]);
    this.fillAssessor(worksheet);
    this.fillElections(worksheet);
  }

  fillGeneralImport(line: JsonObject) {
    this.store.dispatch(setTitleAction({ title: this.readKey(line, PollImportKeys.general.title) }));
    this.store.dispatch(setDescriptionAction({ description: this.readKey(line, PollImportKeys.general.description) }));
    const startDateParts = this.readKey(line, PollImportKeys.general.startDate).split(' ');
    const startDatePart = startDateParts[0].split('/').reverse().join('-');
    this.store.dispatch(setStartDateAction({startDate: startDatePart}));
    if (startDateParts.length > 1) { this.store.dispatch(setStartTimeAction({startTime: startDateParts[1]})); }
    const endDateParts = this.readKey(line, PollImportKeys.general.endDate).split(' ');
    const endDatePart = endDateParts[0].split('/').reverse().join('-');
    this.store.dispatch(setEndDateAction({endDate: endDatePart}));
    if (endDateParts.length > 1) { this.store.dispatch(setEndTimeAction({endTime: endDateParts[1]})); }
    this.store.dispatch(setSenderAction({ sender: this.readKey(line, PollImportKeys.general.sender) }));
    this.store.dispatch(setDocumentAction({ sharedLinks: this.readKey(line, PollImportKeys.general.documents) }));
  }

  fillAssessor(lines: Array<JsonObject>) {
    const assessorNames = Object.assign([], lines).splice(2).map(line => line[PollImportKeys.assessors.name]).filter(it => it !== null && it !== undefined).map(x => x.toString());
    const assessorEmails = Object.assign([], lines).splice(2).map(line => line[PollImportKeys.assessors.email]).filter(it => it !== null && it !== undefined).map(x => x.toString());
    if (assessorEmails.length > 0 || assessorEmails.length > 0) {
      this.store.dispatch(setActivateAssessorsAction({isAssessors: true}));
    }
    this.store.dispatch(setAssessorsNamesAction({assessorsNames: assessorNames}));
    this.store.dispatch(setAssessorsMailsAction({assessorsMails: assessorEmails}));
  }

  fillElections(lines: Array<JsonObject>) {
    const electionTitles = lines.map(line => line[PollImportKeys.votes.title]).filter(it => it !== null && it !== undefined)
      .filter(x => x !== PollImportKeys.votes.separator.first)
      .filter(x => x !== PollImportKeys.votes.separator.second)
      .filter(x => x !== PollImportKeys.votes.separator.firstWin)
      .filter(x => x !== PollImportKeys.votes.separator.secondWin);
    const separators: Array<number> = [];
    const firstSeparator = lines.map(line => line[PollImportKeys.votes.title]).indexOf(PollImportKeys.votes.separator.firstWin) !== -1 ? PollImportKeys.votes.separator.firstWin : PollImportKeys.votes.separator.first;
    separators.push(lines.map(line => line[PollImportKeys.votes.title]).indexOf(firstSeparator) + 1);
    const secSeparator = lines.map(line => line[PollImportKeys.votes.title]).indexOf(PollImportKeys.votes.separator.secondWin) !== -1 ? PollImportKeys.votes.separator.secondWin : PollImportKeys.votes.separator.second;
    separators.push(lines.map(line => line[PollImportKeys.votes.title]).indexOf(secSeparator) + 1);
    const questions: Array<LiveQuestion> = [];
    const choiceCollection: Array<Array<PollChoice>> = [];
    const questionSeparators = [0];
    for (let elect = 0; elect < electionTitles.length; elect++) {
      let continueParse = true;
      for (let i = 0; i < 5 && continueParse; i++) {
        if (lines[separators[elect]][this.getCandidateKey(i, PollImportKeys.votes.election.title)] !== undefined) {
          const listName = lines[separators[elect]][this.getCandidateKey(i, PollImportKeys.votes.election.title)].toString();
          const listDocuments = (lines[separators[elect]][this.getCandidateKey(i, PollImportKeys.votes.election.documents)] !== undefined) ? lines[separators[elect]][this.getCandidateKey(i, PollImportKeys.votes.election.documents)].toString() : null;
          let parse = true;
          let candidateIndex = separators[elect];
          const choices: Array<PollChoice> = [];
          while (parse) {
            if (this.readKey(lines[candidateIndex], this.getCandidateKey(i, PollImportKeys.votes.election.candidateName)) !== '') {
              const candidate = this.readKey(lines[candidateIndex], this.getCandidateKey(i, PollImportKeys.votes.election.candidateName));
              const choice = PollChoice.withName(candidate);
              if (this.readKey(lines[candidateIndex], this.getCandidateKey(i, PollImportKeys.votes.election.candidateLink)) !== '') {
                choice.link = this.readKey(lines[candidateIndex], this.getCandidateKey(i, PollImportKeys.votes.election.candidateLink));
              }
              candidateIndex++;
              choices.push(choice);
            } else {
              parse = false;
            }
          }
          const newCandidate = new LiveQuestion(i, [], listName,  0, 1, electionTitles[elect].toString(), listDocuments);
          questions.push(newCandidate);
          choiceCollection.push(choices);
          if (i === 4 && elect < electionTitles.length - 1) { questionSeparators.push(questions.length); }
        } else {
          continueParse = false;
          questionSeparators.push(questions.length);
        }
      }
    }
    this.store.dispatch(setChoicesAction({ choices: choiceCollection}));
    this.store.dispatch(setLiveQuestionAction({liveQuestions: questions}));
    this.sendUpload.emit(questionSeparators);
  }

  getCandidateKey(line: number, index: number): string {
    const delta = PollImportKeys.votes.election.baseOffset + PollImportKeys.votes.election.diff * line + index;
    return PollImportKeys.votes.election.prefix + delta;
  }


  readKey(line: JsonObject, key: string): string {
    if (line !== undefined && line[key] !== null && line[key] !== undefined) {
      return line[key].toString();
    }
    return '';
  }

  ngOnInit(): void {
  }

}
