import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'vc-poll-finished-summary',
  templateUrl: 'poll-finished-summary.component.html',
  styleUrls: ['./poll-finished-summary.component.less', '../../shared/common.less']
})

export class PollFinishedSummaryComponent implements OnInit {
  @Input() description: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() votersCount: number;
  @Input() votersVoted: number;
  @Input() participation: string;
  @Input() resultsShared: boolean;
  @Input() isShareEnabled: boolean;
  @Input() endDateFormat: Date;
  @Input() calculating: boolean;
  @Output() downloadVoters = new EventEmitter<boolean>();
  @Output() shareResults = new EventEmitter<boolean>();
  @Output() return = new EventEmitter<boolean>();
  @Input() type: string = "live"
  blockchainLink: string
  isActive: boolean = true

  constructor(
    private router: ActivatedRoute
  ) {
  }

  rejoin() {
    this.return.emit(true)
  }

  isActivePoll() {
    if (this.endDateFormat != undefined) {
      let todayDate = new Date()
      if (todayDate.getTime() > this.endDateFormat.getTime())
      this.isActive = false
    }
  }

  download() {
    this.downloadVoters.emit(true);
  }

  shareResult() {
    this.shareResults.emit(true);
  }

  replaceURLs(message: string) {
    if(!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

  ngOnChanges() {
    this.isActivePoll();
  }

  ngOnInit() {
    let pollId = ""
    this.router.queryParams.subscribe((params) => {
      pollId = params.poll
    })
    this.blockchainLink = environment.settings.validation + pollId
  }
}
