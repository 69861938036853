import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Common} from '../../shared/common';
import {ApiService} from '../../shared/api.service';
import {ChangeVoterForm, ValidateVoterChangeForm} from '../../models/PaymentData';

@Component({
  selector: 'app-assessor-change-component',
  templateUrl: './assessor-change-component.component.html',
  styleUrls: ['./assessor-change-component.component.less', '../../poll-ongoing/change-voter/change-voter.component.less', '../../poll-creation/warning-popup/warning-popup.component.less', '../../shared/common.less']
})
export class AssessorChangeComponentComponent implements OnInit {
  @Output() closeChangeAssessor = new EventEmitter<boolean>();
  @Input() pollId: string;
  oldMailCharacters = 0;
  newMailCharacters = 0;
  confirmMailCharacters = 0;
  emailVerificationStatus = 0;
  differentValuesEmails = true;

  constructor(private common: Common, private apiService: ApiService) { }

  closeChangeVoters(): void {
    this.closeChangeAssessor.emit(false);
  }

  updateCounters(): void {
    const oldMailInput = document.getElementById('old-email') as HTMLInputElement;
    this.oldMailCharacters = oldMailInput.value.length;
    const newMailInput = document.getElementById('new-email') as HTMLInputElement;
    this.newMailCharacters = newMailInput.value.length;
    const newMailConfirmation = document.getElementById('new-email-2') as HTMLInputElement;
    this.confirmMailCharacters = newMailConfirmation.value.length;
  }

  areFieldsValid(): boolean {
    const oldMailInput = document.getElementById('old-email') as HTMLInputElement;
    const newMailInput = document.getElementById('new-email') as HTMLInputElement;
    const newMailConfirmation = document.getElementById('new-email-2') as HTMLInputElement;
    const confirmationCheckbox = document.getElementById('confirmation-checkbox') as HTMLInputElement;
    this.differentValuesEmails = !(newMailConfirmation.value !== newMailInput.value && newMailConfirmation.value.length > 5 && newMailInput.value.length);
    if (this.common.validateEmail(newMailInput.value) && this.common.validateEmail(oldMailInput.value) && this.common.validateEmail(newMailConfirmation.value)) {
      if (newMailInput.value === newMailConfirmation.value && confirmationCheckbox.checked && this.emailVerificationStatus === 2) {
        return true;
      }
    }
    return false;
  }

  validateVoter() {
    const oldMailInput = document.getElementById('old-email') as HTMLInputElement;
    const form = new ValidateVoterChangeForm(oldMailInput.value);
    this.apiService.validateAssessorChange(form, this.pollId).subscribe(res => {
        this.emailVerificationStatus = 2;
      },
      err => {
        if (err.status === 403) { this.emailVerificationStatus = 3; }
        else if (err.status === 404) { this.emailVerificationStatus = 4; }
      });
  }

  changeMail() {
    if (this.areFieldsValid()) {
      const oldMailInput = document.getElementById('old-email') as HTMLInputElement;
      const newMailInput = document.getElementById('new-email') as HTMLInputElement;
      const form = new ChangeVoterForm(oldMailInput.value, newMailInput.value, this.common.getLangCode());
      this.apiService.changeAssessor(form, this.pollId).subscribe(res => {
        this.closeChangeAssessor.emit(true);
      }, error => {
        console.log(error);
      });
    }
  }

  ngOnInit(): void {
  }

}

