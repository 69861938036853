import { Component, Output, Input, EventEmitter } from "@angular/core";

@Component({
  selector: "vc-hotline-details",
  templateUrl: "hotline-details.component.html",
  styleUrls: ["./hotline-details.component.less"],
})
export class HotlineDetails {
  @Input() isAssoc: boolean;
  @Input() licenseTime: string;

  @Output() selectedOption = new EventEmitter<number>();

  firstOffer = 200;
  secondOffer = 500;
  thirdOffer = 1000;
  fourthOffer = 2000;
  votersLength = 0;
  SMSNeeded = 0;


  sendOption(index: number) {
    this.selectedOption.emit(index)
  }

  constructor() {}

  ngOnInit() {
  }
}
