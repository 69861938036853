import { Component, Output, Input, EventEmitter } from "@angular/core";
import { Common } from "src/app/shared/common";

@Component({
  selector: "vc-homepage-popup",
  templateUrl: "homepage-popup.component.html",
  styleUrls: ['./homepage-popup.component.less']
})
export class HomepagePopup {
  // Used as text for the popup
  @Input() title: string;
  @Input() firstText: string;
  @Input() secondText: string;
  @Input() thirdText: string;
  @Input() mail: string
  @Input() displayInput: boolean;
  // Different button for different popup
  @Input() understoodButton: boolean = false;
  @Output() endDetailsPopup = new EventEmitter<boolean>();
  @Output() resendUserMail = new EventEmitter<string>();

  inputError: boolean = false;

  clicAction($event) {
    if ($event.target.className === "div-arriere-plan-action") {
      this.cancelDetails(false);
    }
  }

  showMailInput() {
    this.displayInput = true
  }

  resetError() {
    const emailField = document.getElementById("popup-email") as HTMLInputElement;
    this.inputError = false
    emailField.style.border = "solid 1px #0000000d"
  }

  sendEmail() {
    const emailField = document.getElementById("popup-email") as HTMLInputElement;
    if (this.common.validateEmail(emailField.value))
      this.resendUserMail.emit(emailField.value)
    else {
      this.inputError = true
      emailField.style.border = "solid 1px red"
    }
  }

  cancelDetails(value) {
    const popupBackground = document.getElementsByClassName("dapa");
    const offerBackground = document.getElementsByClassName("dga");
    if (offerBackground != undefined && popupBackground.length > 0) {
      popupBackground[0].className = "div-arriere-plan-action";
      offerBackground[0].className = "div-global-action";
    }
    const disableScroll = document.getElementsByTagName("body");
    disableScroll[0].style.overflow = "initial";
    this.endDetailsPopup.emit(value);
  }

  ngOnInit() {
  }

  constructor(private common: Common) { }
}
