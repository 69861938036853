import {Component, Input, OnInit} from '@angular/core';
import {ListResult, Result} from '../../../models/Poll';
import { Common } from "../../../shared/common";

@Component({
  selector: 'app-list-results-list',
  templateUrl: './list-results-list.component.html',
  styleUrls: ['./../poll-results-resolution-list/poll-results-resolution-list.component.less',
              './../poll-results-result/poll-results-result.component.less',
              './list-results-list.component.less'],
  styles: [`
    ngb-progressbar {
      margin-top: 5rem;
    }
  `]
})
export class ListResultsListComponent implements OnInit{
  @Input() results: ListResult[];
  parentResult: ListResult;

  constructor(private common: Common) {}

  getChildren(index: number, parent: Result): Array<Result> {
    const filtered = this.results.filter(x => x.parent === index);
    const withPercentage = filtered.map(x =>
      new Result(x.question, parseFloat(((parent.votes - x.results[0].votes) / parent.votes * 100).toFixed(2)),
        parent.votes - x.results[0].votes));
    withPercentage.forEach(item => {
      if (isNaN(item.percentage)) {
        item.percentage = 0;
      }
    });
    return index !== this.parentResult.answers.length - 1 ? withPercentage : [] ;
  }

  safeNumber(x: number): number {
    return isNaN(x) ? 0 : x;
  }

  translateAnswer(answer: string): string { return this.common.localizeAnswer(answer);}

  ngOnInit() {
    this.parentResult = this.results[0];
    this.parentResult.results.forEach((value, index) => {
      this.getChildren(index, value);
    });
  }
}
