<div class="div-arriere-plan-action" (click)="clicAction($event)"></div>
<div class="div-global-action" (click)="clicAction($event)">
  <div id="container" *ngIf="status == 0">
    <label class="switch">
      <div id="switch-container">
        <input type="checkbox" (click)="defineActive($event)">
        <span class="slider round"></span>
      </div>

      <div id="toggle-text-container">
        <div id="asso" *ngIf="isAssoc" class="active">
          <p class="toggle-text">{{'OFFERS-POPUP.ASSOCIATION' | translate}}</p>
        </div>
        <div id="asso" *ngIf="!isAssoc" class="inactive">
          <p class="toggle-text">{{'OFFERS-POPUP.ASSOCIATION' | translate}}</p>
        </div>
        <div id="company" *ngIf="!isAssoc" class="active">
          <p class="toggle-text">{{'OFFERS-POPUP.CORPORATE' | translate}}</p>
        </div>
        <div id="company" *ngIf="isAssoc" class="inactive">
          <p class="toggle-text">{{'OFFERS-POPUP.CORPORATE' | translate}}</p>
        </div>
      </div>
    </label>
  </div>

  <div class="container-offer" *ngIf="status == 0 && isAssoc else sub">
    <vc-offer-card [source]="source" [index]="0" (sendSelection)="receiveSelection($event)" price="{{'240€'}}"
    [isAssoc]="isAssoc">
    </vc-offer-card>

    <vc-offer-card [source]="source" [index]="1" (sendSelection)="receiveSelection($event)" price="{{'15€'}}"
    [isAssoc]="isAssoc" [deactivated]="true">
    </vc-offer-card>

    <vc-offer-card-main [source]="source" [index]="2" (sendSelection)="receiveSelection($event)" price="{{'30€'}}"
      special="{{'OFFERS.DASHBOARD.VICTIM' | translate}}" [isAssoc]="isAssoc" [deactivated]="true"></vc-offer-card-main>

    <vc-offer-card [source]="source" [index]="3" (sendSelection)="receiveSelection($event)" price="{{'54€'}}"
      special="{{'OFFERS.DASHBOARD.VICTIM' | translate}}" [deactivated]="true"
      [isAssoc]="isAssoc"></vc-offer-card>
  </div>
  <ng-template #sub>
    <div class="container-offer" *ngIf="status == 0 && !isAssoc">
      <vc-offer-card [isAssoc]="isAssoc" [source]="source" [index]="0" (sendSelection)="receiveSelection($event)"
        price="{{'300€'}}">
      </vc-offer-card>

      <vc-offer-card [isAssoc]="isAssoc" [source]="source" [index]="1" (sendSelection)="receiveSelection($event)"
        price="{{'590€'}}">
      </vc-offer-card>

      <vc-offer-card-main [isAssoc]="isAssoc" [source]="source" [index]="2" (sendSelection)="receiveSelection($event)"
        price="{{'990€'}}" special="{{'PRICE-DETAILS.ECONOMY-60' | translate}}"></vc-offer-card-main>

      <vc-offer-card [isAssoc]="isAssoc" [source]="source" [index]="3" (sendSelection)="receiveSelection($event)"
        price="{{'1 490€'}}" special="{{'PRICE-DETAILS.LIMITED' | translate}}"></vc-offer-card>
    </div>
  </ng-template>
</div>
