import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfferCommand, OfferInfo } from '../../models/Enums';

@Component({
  selector: 'vc-offer-card-main',
  templateUrl: './offer-card-main.component.html',
  styleUrls: ['./offer-card-main.component.less'],
})
export class OfferCardMain {
  @Input() source: string;
  @Input() price: string;
  @Input() special: string;
  @Input() index: number;
  @Input() isAssoc: boolean;
  @Output() sendSelection =  new EventEmitter<OfferCommand>();
  @Input() deactivated = false;

  constructor() {}

  getOptions() {
    this.sendSelection.emit(new OfferCommand(OfferInfo.MoreInfo, this.index));
  }

  subscribe() {
    this.sendSelection.emit(new OfferCommand(OfferInfo.Buy, this.index));
  }

}
