import { Voter } from "./Voter";

export class PollQuestion {
  public question: string;
  public answers: Array<string>;
  public links: Array<string>;
  public listImages: Array<string>;
  public listLink: string;
  public numberOfVote: number;

  public candidateImages: Array<string>;

  constructor(question: string, answers: Array<string>, numberOfVote: number, links: Array<string> = [], listLink = "", listImage: Array<string> = [], candidateImages: Array<string> = []) {
    this.answers = answers;
    this.question = question;
    this.numberOfVote = numberOfVote;
    this.links = links;
    this.listLink = listLink;
    this.listImages = listImage;
    this.candidateImages = candidateImages
  }
}

export class  ListQuestion {
  public title: string;
  public lists: Array<PollQuestion> = []

  constructor(title, lists) {
    this.title = title;
    this.lists = lists;
  }
}

export class AddedPollQuestion {
  public question: PollQuestion;
  public nbVotes: Number;

  constructor(question: PollQuestion, nbVotes: Number) {
    this.nbVotes = nbVotes;
    this.question = question;
  }
}

export class PollCreationData {
  public name: string;
  public description: string;
  public shortName: string;
  public voters: Array<Voter>;
  public questions: Array<ListQuestion>;
  public startDate: number;
  public endDate: number;
  public publicVote: boolean;
  public image: string;
  public type: string;
  public isLive: boolean;
  public isSMS: boolean;
  public numberOfVote: number;
  public is2FA: boolean;
  public sender: string;
  public sharedLinks: string;
  public hotline: boolean
  public assessorsNames: string[]
  public assessorsMails: string[]
  public assessors: Object[];
  public language: string;
  public draftId: string;

  constructor(
    title: string,
    voters: Array<Voter>,
    description: string,
    question: Array<ListQuestion>,
    startDate: number,
    endDate: number,
    image: string,
    type: string,
    publicVote: boolean,
    isSMS: boolean,
    numberOfVote: number,
    is2FA: boolean,
    sender: string,
    sharedLinks: string,
    hotline: boolean,
    language: string,
    draftId: string
  ) {
    this.description = description;
    this.name = title;
    this.questions = question;
    this.shortName = title;
    this.voters = voters;
    this.startDate = startDate;
    this.endDate = endDate;
    this.publicVote = publicVote;
    this.image = image;
    this.type = type;
    this.isLive = type === "live";
    this.isSMS = isSMS;
    this.numberOfVote = numberOfVote;
    this.is2FA = is2FA;
    this.sender = sender;
    this.sharedLinks = sharedLinks;
    this.hotline = hotline;
    this.language = language;
    this.draftId = draftId;
  }
}
