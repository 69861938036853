import { Input, Component, OnInit } from "@angular/core";

@Component({
  selector: "vc-status-bar",
  templateUrl: "status-bar.component.html",
  styleUrls: ["./status-bar.component.less"],
})

export class StatusBar implements OnInit {
  @Input() status: number;

  constructor(
  ) {}

  ngOnChanges(): void {
  }

  ngOnInit(): void {
  }

}
