<div class="card">

  <div *ngIf="special !== undefined" class="special">
    <p>{{special}}</p>
  </div>

  <div class="prices">
    <h2 id="price">{{price}}</h2>
      <div *ngIf="isAssoc !== false; else ht" class="taxes">{{'OFFERS.PRICES.ASSO-TAXES' | translate}}<span *ngIf="price !== '240€'">{{'OFFERS.PRICES.SUBSCRIPTION' | translate}}</span></div>
      <ng-template #ht>
        <div class="taxes">
          {{'OFFERS.PRICES.CORPO-TAXES' | translate}}<span *ngIf="price === '69€'">{{'OFFERS.PRICES.SUBSCRIPTION' | translate}}</span>
        </div>
      </ng-template>
  </div>

  <div class="title" *ngIf="price === '15€'">
    <p class="title-offer">{{'OFFERS.DASHBOARD.ALL-INCLUDED' | translate}}</p>
  </div>
  <div class="title" *ngIf="price === '240€'  || price === '590€' || price === '300€'">
    <p class="title-offer">{{'OFFERS.DASHBOARD.ONE-EVENT' | translate}}</p>
  </div>
  <div class="title" *ngIf="price === '54€'">
    <p class="title-offer">{{'OFFERS.DASHBOARD.PREMIUM' | translate}}</p>
  </div>
  <div class="title" *ngIf="price === '1 490€'">
    <p class="title-offer">{{'OFFERS.DASHBOARD.ALL-INCLUDED' | translate}}{{'OFFERS.DASHBOARD.MAX' | translate}}</p>
  </div>

  <p class="description">{{'OFFERS.DASHBOARD.DESCRIPTION' | translate}}</p>

  <div class="wrapper" *ngIf="!isAssoc else assembly">
    <div class="round">
      <span class="checkmark"></span>
    </div>
    <p class="offer" *ngIf="price !== '1 490€'">{{'OFFERS.DASHBOARD.ONE-ROUND' | translate}}</p>
    <p class="offer" *ngIf="price === '1 490€'">{{'OFFERS.DASHBOARD.TWO-ROUNDS' | translate}}</p>
  </div>
  <ng-template #assembly>
    <div>
      <div class="round">
        <span class="checkmark"></span>
      </div>
      <p class="offer" *ngIf="price === '54€' else lower">{{'OFFERS.DASHBOARD.TWO-ASSEMBLY' | translate}}</p>
      <ng-template #lower>
        <p class="offer">{{'OFFERS.DASHBOARD.ASSEMBLY' | translate}}</p>
      </ng-template>
    </div>
  </ng-template>

  <div class="wrapper">
    <div class="round">
      <span class="checkmark"></span>
    </div>
    <div *ngIf="isAssoc !== false; else company">
      <p *ngIf="price === '15€'; else priced" class="offer">{{'OFFERS.DASHBOARD.LOW-PRICED' | translate}}{{'OFFERS.DASHBOARD.ASSO-OPTION' | translate}}</p>
      <ng-template #priced>
        <p class="offer">{{'OFFERS.DASHBOARD.PRICED' | translate}}{{'OFFERS.DASHBOARD.ASSO-OPTION' | translate}}</p>
      </ng-template>
    </div>
    <ng-template #company>
      <p *ngIf="price === '300€'" class="offer">
        {{'OFFERS.DASHBOARD.LOW-PRICED' | translate}}<span class="important">{{'OFFERS.DASHBOARD.LOW-COMPANY-OPTION' |
          translate}}</span>
      </p>
      <p *ngIf="price === '590€'" class="offer">
        {{'OFFERS.DASHBOARD.PRICED' | translate}}<span class="important">{{'OFFERS.DASHBOARD.COMPANY-OPTION' |
          translate}}</span>
      </p>

      <p *ngIf="price === '1 490€'" class="offer">
        {{'OFFERS.DASHBOARD.PRICED' | translate}}<span class="important">{{'OFFERS.DASHBOARD.MAX-COMPANY-OPTION' |
          translate}}</span>
      </p>
    </ng-template>
  </div>

  <div class="wrapper" >
    <div class="round">
      <span class="checkmark"></span>
    </div>
    <p class="offer" *ngIf="price == '1 490€' || price == '999€' else help">{{'OFFERS.DASHBOARD.TURNKEY' | translate}}</p>
    <ng-template #help>
      <p class="offer">{{'OFFERS.DASHBOARD.HELP' | translate}}</p>
    </ng-template>
  </div>



  <div *ngIf="source === 'dashboard'" class="button">
    <div *ngIf="price === '0€'; else sub">
      <button class="blue-button" style="width: 207px;" (click)="freeTrial()">{{'OFFERS.DASHBOARD.FREE-BUTTON' | translate}}</button>
    </div>
    <ng-template #sub>
      <button *ngIf="!deactivated" class="blue-button" style="width: 207px;" (click)="subscribe()">{{'OFFERS.DASHBOARD.SUBSCRIBE' | translate}}</button>
      <button *ngIf="deactivated" class="blue-button" style="width: 207px; cursor: default">{{'OFFERS.DASHBOARD.UNAVAILABLE' | translate}}</button>
    </ng-template>
  </div>

  <div *ngIf="source === 'payment'" class="button">
    <div *ngIf="price === '0€'; else sub">
      <button class="blue-button" style="width: 207px;">{{'OFFERS.DASHBOARD.FREE-BUTTON' | translate}}</button>
    </div>
    <ng-template #sub>
      <button *ngIf="!deactivated" class="blue-button" style="width: 207px;" (click)="subscribe()">{{'OFFERS.DASHBOARD.SUBSCRIBE' | translate}}</button>
      <button *ngIf="deactivated" class="blue-button" style="width: 207px; cursor: default">{{'OFFERS.DASHBOARD.UNAVAILABLE' | translate}}</button>
    </ng-template>
  </div>

  <div *ngIf="source === 'home'" class="button">
    <!-- <div *ngIf="price === '0€'; else sub"> -->
    <button *ngIf="price === '0€'; else sub" class="blue-button" style="width: 207px;" (click)="signup()">{{'OFFERS.HOME.FREE-BUTTON' |
      translate}}</button>
    <!-- </div> -->
    <ng-template #sub>
      <button class="blue-button" style="width: 207px;" (click)="signup()">{{'OFFERS.HOME.SUBSCRIBE-BUTTON' | translate}}</button>
    </ng-template>
  </div>

  <div class="options" (click)="getOptions()">
    <p>{{'OFFERS.DASHBOARD.OPTIONS' | translate}}</p>
  </div>
</div>
