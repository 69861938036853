<div class="card-wrapper">

  <!-- <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap" rel="stylesheet"> -->
  <div class="card">
    <div *ngIf="special !== undefined" class="special">
      <p>{{special}}</p>
    </div>

    <div class="prices">
      <h2 id="price">{{price}}</h2>
      <div *ngIf="price !== '0€' && isAssoc !== false; else ht" class="taxes">{{'OFFERS.PRICES.ASSO-TAXES' | translate}}{{'OFFERS.PRICES.SUBSCRIPTION' | translate}}</div>
      <ng-template #ht>
        <div class="taxes">
          {{'OFFERS.PRICES.CORPO-TAXES' | translate}}
        </div>
      </ng-template>
    </div>
    <div class="title" *ngIf="price === '0€'">
      <p class="title-offer">{{'OFFERS.DASHBOARD.FREE' | translate}}</p>
    </div>
    <div class="title" *ngIf="price === '240€' || price === '708€'">
      <p class="title-offer">{{'OFFERS.DASHBOARD.ONE-EVENT' | translate}}</p>
    </div>
    <div class="title" *ngIf="price === '30€' || price === '990€'">
      <p class="title-offer">{{'OFFERS.DASHBOARD.ALL-INCLUDED' | translate}}</p>
    </div>
    <div class="title" *ngIf="price === '600€' || price === '1 908€'">
      <p class="title-offer">{{'OFFERS.DASHBOARD.UNLIMITED' | translate}}</p>
    </div>
    <p class="description">{{'OFFERS.DASHBOARD.DESCRIPTION' | translate}}</p>

    <div class="wrapper">
      <div class="round">
        <span class="checkmark"></span>
      </div>
      <p class="offer" *ngIf="isAssoc">{{'OFFERS.DASHBOARD.ASSEMBLY' | translate}}</p>
      <p class="offer" *ngIf="!isAssoc">{{'OFFERS.DASHBOARD.TWO-ROUNDS' | translate}}</p>
    </div>

    <div class="wrapper">
      <div class="round">
        <span class="checkmark"></span>
      </div>
      <div *ngIf="isAssoc !== false; else company">
        <p class="offer">{{'OFFERS.DASHBOARD.PRICED' | translate}}{{'OFFERS.DASHBOARD.ASSO-OPTION' | translate}}</p>
      </div>
      <ng-template #company>
        <p class="offer">
          {{'OFFERS.DASHBOARD.LOW-PRICED' | translate}}<span class="important">{{'OFFERS.DASHBOARD.MID-COMPANY-OPTION' |
            translate}}</span>
        </p>
      </ng-template>
    </div>

    <div class="wrapper">
      <div class="round">
        <span class="checkmark"></span>
      </div>
      <p *ngIf="price == '990€' else help" class="offer">{{'OFFERS.DASHBOARD.TURNKEY' | translate}}</p>
      <ng-template #help>
        <p class="offer">{{'OFFERS.DASHBOARD.HELP' | translate}}</p>
      </ng-template>
    </div>

    <div *ngIf="source === 'dashboard'">
      <div *ngIf="price === '0€'; else sub">
        <button id="new-poll">{{'OFFERS.DASHBOARD.FREE-BUTTON' | translate}}</button>
      </div>
      <ng-template #sub>
        <div>
          <button *ngIf="!deactivated" id="new-poll" (click)="subscribe()">{{'OFFERS.DASHBOARD.SUBSCRIBE' | translate}}</button>
          <button *ngIf="deactivated" id="new-poll" style="cursor: default">{{'OFFERS.DASHBOARD.UNAVAILABLE' | translate}}</button>
        </div>
      </ng-template>
    </div>

    <div *ngIf="source === 'payment'">
      <div *ngIf="price === '0€'; else sub">
        <button id="new-poll">{{'OFFERS.DASHBOARD.FREE-BUTTON' | translate}}</button>
      </div>
      <ng-template #sub>
        <div>
          <button *ngIf="!deactivated" id="new-poll" (click)="subscribe()">{{'OFFERS.DASHBOARD.SUBSCRIBE' | translate}}</button>
          <button *ngIf="deactivated" id="new-poll" style="cursor: default">{{'OFFERS.DASHBOARD.UNAVAILABLE' | translate}}</button>
        </div>
      </ng-template>
    </div>

    <div *ngIf="source === 'home'">
      <div *ngIf="price === '0€'; else sub">
        <button id="new-poll">{{'OFFERS.HOME.FREE-BUTTON' | translate}}</button>
      </div>
      <ng-template #sub>
        <div>
          <button id="new-poll" (click)="subscribe()">{{'OFFERS.HOME.SUBSCRIBE-BUTTON' | translate}}</button>
        </div>
      </ng-template>
    </div>



  </div>
  <div class="options" (click)="getOptions()">
    <p>{{'OFFERS.DASHBOARD.OPTIONS' | translate}}</p>
  </div>
</div>
