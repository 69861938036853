import  apiURL from '../assets/config.json';
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAY8vB8O5oG4WwHUL310u_JwqtC1X4iApU",
    authDomain: "v8te-a9b50.firebaseapp.com",
    databaseURL: "https://v8te-a9b50.firebaseio.com",
    projectId: "v8te-a9b50",
    storageBucket: "v8te-a9b50.appspot.com",
    messagingSenderId: "418674463756",
    appId: "1:418674463756:web:8f110be6b454dca9bb0a29",
    measurementId: "G-P7SZ00CVHN"
  },
  superAdmin: "super_admin@v8te.com",
  settings: {
    api: 'https://api.beta.v8te.com',
    livews: 'wss://api.beta.v8te.com/ws/elections/',
    validation: 'https://validation.beta.v8te.com/',
    voteUrl: 'https://vote.beta.v8te.com/',
    firebase: {
      user: 'bogdandrb@gmail.com',
      pass: 'v8tepass'
    }
  },
  auth: {
    domain: "login.beta.v8te.com",
    clientId: "I4qWyVTvo0rL6o4kOODbFbkn0z4Vv6uT",
    audience: "https://api.v8te.com/",
    redirectUri: window.location.origin + '/connexion',
  },
  httpInterceptor: {
    allowedList: [`${apiURL}/*`],
  },
  stripeConstants:  {
    day: {
      association: "price_1MyaswEZ0Gpxq9WwrwIKOBgQ",
      associationSMS: "price_1IIJvSEZ0Gpxq9WwAJQcqeFw",
      associationMin: "price_1IIJzzEZ0Gpxq9Ww1MyuEbkR",
      associationSMSMin: "price_1IIKGsEZ0Gpxq9Ww2DewpZus",
      associationHussar: "price_1IF3w4EZ0Gpxq9WwbOi26goh",
      company: "price_1MgT0tEZ0Gpxq9WwSRhCXfz3",
      corp50: "price_1MgT0tEZ0Gpxq9WwSRhCXfz3",
      corp1000: "price_1J5uNUEZ0Gpxq9WwdCqMCCJF",
      companySMS: "price_1IIJXJEZ0Gpxq9WwzQgWjUtl",
      companyMin: "price_1IoSqBEZ0Gpxq9WwTNw5s8Li",
      companySMSMin: "price_1Ipc2eEZ0Gpxq9WwcqzHkXfs",
      companyHussar: "price_1IF3uPEZ0Gpxq9WwjMudrXGB"
    },
    month: {
      association: "price_1MlyUGEZ0Gpxq9WwlAtxeoJW",
      associationSMS: "price_1IPbdlEZ0Gpxq9WwcKLTAGzr",
      associationMin: "price_1IF1vWEZ0Gpxq9WwQmgLpSnm",
      associationSMSMin: "price_1IF1zGEZ0Gpxq9WwzG0KLby5",
      associationHussar: "price_1IF3xpEZ0Gpxq9WwjX0Zbk64",
      company: "price_1J5uNUEZ0Gpxq9WwdCqMCCJF",
      companySMS: "price_1IPbP3EZ0Gpxq9WwzmQoIOmk",
      companyMin: "price_1IIsqfEZ0Gpxq9WwM5TJ4SzN",
      companySMSMin: "price_1IF1zGEZ0Gpxq9WwuJkcinoi",
      companyHussar: "price_1IF3wyEZ0Gpxq9WwEQTnNbKQ"
    },
    year: {
      association: "price_1MlyTUEZ0Gpxq9Wwx4OprPsf",
      associationSMS: "price_1IPbsEEZ0Gpxq9WwCuKpHThX",
      associationMin: "price_1I8T9gEZ0Gpxq9WwwzOSE0se",
      associationSMSMin: "price_1I8T9gEZ0Gpxq9WwwzOSE0se",
      associationHussar: "price_1IF3z1EZ0Gpxq9WwswWQLQqZ",
      company: "price_1Myb4pEZ0Gpxq9Wwya6IcQPB",
      companySMS: "price_1IO6JCEZ0Gpxq9Wwzd9DA8V0",
      companyMin: "price_1I8T8xEZ0Gpxq9WwpxyhS5d9",
      companySMSMin: "price_1I8T8xEZ0Gpxq9WwpxyhS5d9",
      companyHussar: "price_1Myb4pEZ0Gpxq9Wwya6IcQPB"
    },
    event: {
      association: "price_1J5uNUEZ0Gpxq9Wwl3DGovll",
      entreprise: "price_1J5uNUEZ0Gpxq9WwdCqMCCJF"
    },
    sms: {
        assoc: {
          pack200: "price_1Jtpi8EZ0Gpxq9WwoibOqL7A",
          pack500: "price_1JEsojEZ0Gpxq9WwqODcT0Jr",
          pack1000: "price_1J5uPkEZ0Gpxq9Wwa0lSwXpL",
          pack5000: "price_1J5uQPEZ0Gpxq9WwDcatvUzE",
          pack10000: "price_1J5uRDEZ0Gpxq9WwQ40Nu4Gp",
          pack20000: "price_1J5uRDEZ0Gpxq9WwQpDEuh5s"
        },
        corp: {
          pack200: "price_1Jtpi8EZ0Gpxq9Ww2wOuI70X",
          pack500: "price_1JEsojEZ0Gpxq9WwM4dNJTCV",
          pack1000: "price_1J5uPkEZ0Gpxq9WwakDOJPoO",
          pack5000: "price_1J5uQPEZ0Gpxq9WwpmlmANKv",
          pack10000: "price_1J5uRDEZ0Gpxq9WwQpDEuh5s",
          pack20000: "price_1J5uRwEZ0Gpxq9WwtOIAXqq8"
        }
    },
    hotline: {
      assoc: {
        oneEvent: "price_1KSJbKEZ0Gpxq9WwBW7LU4OP",
        fiveEvents: "price_1KSJcvEZ0Gpxq9WwXbzK76sO",
        anual: "price_1KSJebEZ0Gpxq9WwnoYsqomp"
      },
      corp: {
        oneEvent: "price_1KSJbKEZ0Gpxq9WwxCPMQAB6",
        fiveEvents: "price_1KSJcvEZ0Gpxq9WwvWl31Iby",
        anual: "price_1KSJebEZ0Gpxq9WwPxHsRWmY"
      }
    },
    pack: {
      association: "price_1J5uO9EZ0Gpxq9Wwu5130TSZ",
      asso50: "price_1MyaswEZ0Gpxq9WwrwIKOBgQ",
      asso1000: "price_1MlyUGEZ0Gpxq9WwlAtxeoJW",
      asso2ag: "price_1MlyTUEZ0Gpxq9Wwx4OprPsf",
      corp50: "price_1Myb06EZ0Gpxq9WwZ9BAsL1V",
      corp1000: "price_1Myb4pEZ0Gpxq9Wwya6IcQPB"
    },
  constants: {
    accessAssoc: "price_1IHYNIEZ0Gpxq9WwHgMV2Ypg",
    accessCompany: "price_1IHYNIEZ0Gpxq9WwOjXPIHLi",
    cuponAssoc: "LOpdWER4",
    cuponCompany: "UGgd6K4q"
  },
  tax20: "txr_1INlMTEZ0Gpxq9WwTSa5jUyT",
  key: "pk_test_wLdjbtAixYTK7V7GF1PteB9n0096Sm5rkn"
  }
};
