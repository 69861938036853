import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent {
  @Input() errorMessage: string;
  @Input() errorDescription: string;
  @Output() isClosed = new EventEmitter<boolean>();

  onDismiss() {
    this.isClosed.emit(true);
  }
}
