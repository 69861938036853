<div class="form-holder">
  <vc-poll-card-header [image]="'assets/infos.svg'" [title]="'POLL-CREATION.QUESTIONS.TITLE-EVENT' | translate" [description]="'POLL-CREATION.FORM.INFORMATION' | translate">
  </vc-poll-card-header>
  <div class="safe-area">
    <h4 class="checkboxes-title" style="margin-bottom: 10px;">{{'POLL-CREATION.FORM.VOTE-TYPE' | translate}}</h4>
    <div id="type-holder">
      <div class="left-option" (click)="setPollMode('resolution')"
        [ngClass]="currentPoll.votingType == 'resolution' ? 'left-option-selected' : 'left-option'">
        <div class="icons">
          <img *ngIf="currentPoll.votingType == 'resolution'" class="selected-icon" src="assets/selection-checkbox.svg">
          <img [src]="currentPoll.votingType == 'resolution' ? 'assets/resolution-image-selected.svg' : 'assets/resolution-image.svg'"
          id="live-image" height="36" width="36">
          <h2 class="button-text" style="margin-bottom: 8px;">{{'POLL-CREATION.FORM.LABEL-RESOLUTIONS' | translate}}</h2>
      </div>
    </div>
      <div class="right-option" (click)="setPollMode('election')"
        [ngClass]="currentPoll.votingType == 'election' ? 'right-option-selected' : 'right-option'">
        <div class="icons">
          <img *ngIf="currentPoll.votingType == 'election'" class="selected-icon" src="assets/selection-checkbox.svg">
          <img [src]="currentPoll.votingType == 'election' ? 'assets/election-simple-selected.svg' : 'assets/election-simple.svg'"
            id="resolution-image" height="36" width="36">
          <h2 class="button-text">{{'POLL-CREATION.FORM.LABEL-CANDIDATES' | translate}}</h2>
        </div>
      </div>
      <div class="left-option" (click)="setPollMode('list')" *ngIf="currentPoll.temporality != 'live'"
        [ngClass]="currentPoll.votingType == 'list' ? 'left-option-selected' : 'left-option'">
        <div class="icons">
          <img *ngIf="currentPoll.votingType == 'list'" class="selected-icon" src="assets/selection-checkbox.svg">
          <img [src]="currentPoll.votingType == 'list' ? 'assets/guide-guides-selected.svg' : 'assets/guide-guides.svg'"
          id="live-image" height="36" width="36">
          <h2 class="button-text" style="margin-bottom: 8px;">{{'POLL-CREATION.FORM.LABEL-LIST' | translate}}</h2>
        </div>
      </div>
    </div>
    <p *ngIf="currentPoll.votingType == 'resolution'" class="description" [innerHTML]="'POLL-CREATION.MAIN.RESOLUTION-VOTE' | translate"></p>
    <p *ngIf="currentPoll.votingType == 'election'" class="description" [innerHTML]="'POLL-CREATION.MAIN.ELECTION-VOTE' | translate"></p>
    <p *ngIf="currentPoll.votingType == 'list' && currentPoll.temporality != 'live'" class="description" [innerHTML]="'POLL-CREATION.MAIN.CORPORATE-VOTE' | translate"></p>
    <div style="width: 100%;">
      <h4 class="checkboxes-title">{{'POLL-CREATION.FORM.EVENT-TITLE' | translate}} <span class="description">{{'POLL-CREATION.FORM.MANDATORY' | translate}}</span></h4>
      <div style="display: flex; justify-content: space-between;">
        <textarea id="subject" class="name" (keyup)="onTitleInput($event)" (input)="common.autoHeight($event, 10)" maxlength="140"
        placeholder="{{'POLL-CREATION.FORM.EVENT-PLACEHOLDER' | translate}}" value="{{currentPoll.title}}" (focusout)="markSave()"></textarea>
      </div>
      <div [ngClass]="titleCharacters == 140 ? 'character-counter-red' : 'character-counter'">{{titleCharacters}}/140</div>
    </div>
    <div [hidden]="currentPoll.temporality == 'live'" style="width: 100%;">
      <h4 class="checkboxes-title" style="width: max-content;">{{'POLL-CREATION.FORM.POLL-DESCRIPTION' | translate}} <span class="description">{{'POLL-CREATION.FORM.MANDATORY' | translate}}</span></h4>
      <div style="display: flex; justify-content: space-between;">
        <textarea class="name" id="arguments" (keyup)="onDescriptionInput($event)" (input)="common.autoHeight($event, 10)" maxlength="1000"
        placeholder="{{'POLL-CREATION.FORM.POLL-DESCRIPTION-PLACEHOLDER' | translate}}" value="{{currentPoll.description}}" (focusout)="markSave()"></textarea>
      </div>
      <div [ngClass]="descriptionCharacters == 500 ? 'character-counter-red' : 'character-counter'">{{descriptionCharacters}}/1000</div>
    </div>
    <div style="width: 100%;">
      <h4 class="checkboxes-title" style="margin-top: 15px;">{{'POLL-CREATION.VOTERS.SENDER' | translate}}</h4>
      <div style="display: flex; justify-content: space-between;">
        <textarea id="sender" class="name" (keyup)="senderInput($event)" (input)="common.autoHeight($event, 10)" maxlength="140"
        placeholder="{{'POLL-CREATION.VOTERS.SENDER-PLACEHOLDER' | translate}}" value="{{currentPoll.sender}}" (focusout)="markSave()"></textarea>
      </div>
      <div [ngClass]="senderCharacters == 140 ? 'character-counter-red' : 'character-counter'">{{senderCharacters}}/140</div>
      <p class="description" [innerHTML]="'POLL-CREATION.MAIN.SENDER-WARNING' | translate"></p>
    </div>
    <h4 class="checkboxes-title" style="margin-top: 15px;">{{'POLL-CREATION.MAIN.IMAGE-TITLE' | translate}}</h4>
    <div id="upload-panel" style="width: 100%;">
      <div>

        <div class="upload-button" (click)="fileInput.click()" appDragDrop (onFileDropped)="onFileChanged($event)">
          <img src="assets/image_upload.svg" id="upload-image-icon">
          <p id="description-upload">{{'POLL-CREATION.MAIN.IMPORT' | translate}}</p>
          <input style="display: none" type="file" accept=".jpeg, .jpeg, .gif, .png"  data-cy="imageInput"
          (change)="onFileChanged($event.target.files); fileInput.value=''" #fileInput>
        </div>
      </div>
    </div>
    <div id="upload-image-wrapper">
      <div id="uploaded-panel" (click)="fileInput.click()" appDragDrop (onFileDropped)="onFileChanged($event)">
        <img id="uploaded" src="#" alt="">
        <div>
          <p id="image-name">{{'POLL-CREATION.MAIN.CHANGE-IMAGE' | translate}}</p>
        </div>
      </div>
      <div class="icon-container">
        <img id="delete-button" src="assets/deletebutton.svg" (click)="deleteImage()">
      </div>
    </div>

    <p class="description" [innerHTML]="'POLL-CREATION.MAIN.IMAGE-WARNING' | translate"></p>
    <div style="width: 100%;">
      <h4 class="checkboxes-title" style="margin-top: 15px;">{{'POLL-CREATION.VOTERS.DOCUMENTS' | translate}}</h4>
      <div style="display: flex; justify-content: space-between;">
        <textarea id="documents" class="name" type="url" (keyup)="linkInput($event)" (input)="common.autoHeight($event, 10)"
        maxlength="200" placeholder="{{'POLL-CREATION.VOTERS.DOCUMENTS-PLACEHOLDER' | translate}}"
        value="{{currentPoll.sharedLinks}}" (focusout)="markSave()"></textarea>
      </div>
      <div [ngClass]="documentsCharacters == 200 ? 'character-counter-red' : 'character-counter'">{{documentsCharacters}}/200</div>
    </div>
    <h4 class="checkboxes-title">{{'POLL-CREATION.FORM.CONSULTATION-MODE' | translate}}</h4>
    <p *ngIf="currentPoll.isUncrypted" class="description" [innerHTML]="'POLL-CREATION.MAIN.CONSULTATION-DESCRIPTION' | translate"></p>
    <p *ngIf="!currentPoll.isUncrypted && currentPoll.votingType != 'list'" class="description" [innerHTML]="'POLL-CREATION.MAIN.SECRET-DESCRIPTION' | translate"></p>
    <p *ngIf="!currentPoll.isUncrypted && currentPoll.votingType == 'list'" class="description" [innerHTML]="'POLL-CREATION.MAIN.SECRET-LIST-DESCRIPTION' | translate"></p>
    <div class="checkbox-holder">
      <span [ngClass]="currentPoll.isUncrypted ? 'checkbox-label-left-gray' : 'checkbox-label-left'">{{'COMMON.SECRET' | translate}}</span>
      <label class="switch">
        <input *ngIf="currentPoll.votingType != 'list'" type="checkbox" [(ngModel)]="currentPoll.isUncrypted"  data-md-icheck (change)="setCrypto($event)">
        <input *ngIf="currentPoll.votingType == 'list'" type="checkbox" [(ngModel)]="currentPoll.isUncrypted"  data-md-icheck disabled style="cursor: not-allowed;">
        <span class="slider round" [ngClass]="{'list-slide': currentPoll.votingType == 'list'}"></span>
      </label>
      <span [ngClass]="currentPoll.isUncrypted ? 'checkbox-label' : 'checkbox-label-gray'">{{'COMMON.PUBLIC' | translate}}</span>
    </div>
  </div>
</div>

<!--
-- POPUP FOR BOTH MODE
-->
<vc-warning-popup class="image-error" *ngIf="displayImageError"
  [understoodButton]="true" (endDetailsPopup)="dismissPayPopup()"
  title="{{'UPLOAD-IMAGES.POPUP-TEXT' | translate}}">
</vc-warning-popup>
