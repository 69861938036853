import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { Poll } from '../../models/Poll';
import { Store } from '@ngrx/store';
import {
  setActivateAssessorsAction,
  setEndDateAction,
  setEndTimeAction,
  setStartDateAction,
  setStartTimeAction,
  setTemporalityAction
} from 'src/app/states/creationActions';

@Component({
  selector: 'vc-poll-creation-form',
  templateUrl: 'poll-creation-form.component.html',
  styleUrls: ['./poll-creation-form.component.less']
})

export class PollCreationFormComponent implements OnInit, OnChanges {
  @Input() shouldResume: boolean;
  @Input() editionMode: boolean;
  @Input() disableTemporalitySelection = false;
  createdPoll: Poll;
  isValidStartDate = false;
  isValidStartTime = false;
  isValidEndDate = false;
  isValidEndTime = false;

  limitDate: string;
  limitEndDate: string;

  constructor(private store: Store) {}

  setTemporality(temporality: string) {
    if (temporality === 'live') { this.store.dispatch(setActivateAssessorsAction({ isAssessors: false })); }
    this.store.dispatch(setTemporalityAction({ temporality }));
  }

  getISODateStart(date: Date): string { return date.toISOString().slice(0, 10); }

  onStartTime(event) {
    this.store.dispatch(setStartTimeAction({ startTime: event.target.value }));
    this.validateDates();
    this.setupDates();
    this.store.dispatch(setEndTimeAction({ endTime : this.createdPoll.endTime }));
  }

  isSameDay() {
    const startDate = new Date(this.createdPoll.startDate);
    const endDate = new Date(this.createdPoll.endDate);
    return (startDate.getTime() < endDate.getTime());
  }

  onStartDate(event) {
    this.store.dispatch(setStartDateAction({startDate : event.target.value}));
    const limitEndDate = new Date(this.createdPoll.startDate);
    const isoLimit = this.getISODateStart(limitEndDate);
    if (!this.isSameDay()) {
      this.createdPoll.endDate = isoLimit;
      (document.getElementById('end-date') as HTMLInputElement).value = isoLimit;
      this.store.dispatch(setEndDateAction({endDate : this.createdPoll.endDate}));
    }
    this.limitEndDate = isoLimit;
    this.validateDates();
    this.setupDates();
  }

  setupDates() {
    this.createdPoll.startTime = (document.getElementById('start-time') as HTMLInputElement).value;
    this.createdPoll.startDate = (document.getElementById('start-date') as HTMLInputElement).value;
    this.createdPoll.endTime = (document.getElementById('end-time') as HTMLInputElement).value;
    this.createdPoll.endDate = (document.getElementById('end-date') as HTMLInputElement).value;
  }

  onEndTime(event) {
    this.createdPoll.endTime = event.target.value;
    this.store.dispatch(setEndTimeAction({ endTime : this.createdPoll.endTime }));
    this.validateDates();
    if (this.createdPoll.startDate !== undefined && this.createdPoll.startTime !== undefined) { this.setupDates(); }
  }

  onEndDate(event) {
    this.createdPoll.endDate = event.target.value;
    this.validateDates();
    this.setupDates();
    this.store.dispatch(setEndDateAction({ endDate : this.createdPoll.endDate }));
  }

  validateDates() {
    const currentDate = this.setDateWith0Hours(new Date());
    this.isValidStartDate = new Date(this.createdPoll.startDate) >= currentDate;
    this.isValidStartTime = new Date(this.createdPoll.startDate + ' ' + this.createdPoll.startTime) >= new Date() &&
                          (this.createdPoll.startTime != null);
    this.isValidEndDate = (new Date(this.createdPoll.endDate) >= new Date(this.createdPoll.startDate)) &&
                          (new Date(this.createdPoll.endDate) >= currentDate);
    this.isValidEndTime = (new Date(this.createdPoll.endDate + ' ' + this.createdPoll.endTime) >= new Date(this.createdPoll.startDate + ' ' + this.createdPoll.startTime)) &&
                          (new Date(this.createdPoll.endDate + ' ' + this.createdPoll.endTime) >= new Date()) &&
                          (this.createdPoll.endTime != null);
  }

  resumeFromPayment() {
    this.createdPoll = JSON.parse(localStorage.getItem('editedPoll'));
    if (this.createdPoll.temporality === 'planned') {
      (document.getElementById('start-date') as HTMLInputElement).value = this.createdPoll.startDate;
      (document.getElementById('start-time') as HTMLInputElement).value = this.createdPoll.startTime;
      (document.getElementById('end-date') as HTMLInputElement).value = this.createdPoll.endDate;
      (document.getElementById('end-time') as HTMLInputElement).value = this.createdPoll.endTime;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shouldResume'] != null && changes['shouldResume'].currentValue === true && changes['shouldResume'].previousValue === false) {
      this.resumeFromPayment();
    }
  }

  setStoreObserver() {
    this.store.select((state: any) => state.creation.poll).subscribe(res => {
      this.createdPoll.temporality = res.temporality;
      this.createdPoll.startDate = res.startDate;
      this.createdPoll.startTime = res.startTime;
      this.createdPoll.endDate = res.endDate;
      this.createdPoll.endTime = res.endTime;
      this.validateDates();
    });
  }

  getMaxStartDate() {
    const date = new Date();
    const input = document.getElementById('start-date') as HTMLInputElement;
    input.setAttribute('max', this.getISODateStart(new Date(date.setDate(date.getDate() + 31))));
  }

  setDateWith0Hours(date: Date): Date { return new Date(date.setHours(0, 0, 0, 0)); }

  ngOnInit() {
    if (this.shouldResume === true ) {
      this.resumeFromPayment();
    } else {
        this.createdPoll = new Poll();
        this.createdPoll.temporality = this.disableTemporalitySelection ? 'planned' : 'live';
    }
    this.setStoreObserver();
    this.limitDate = this.getISODateStart(new Date());
    if (this.createdPoll.startDate === undefined) {
      this.limitEndDate = this.getISODateStart(new Date(this.createdPoll.startDate));
    } else {
      this.limitEndDate = this.limitDate;
    }
    this.getMaxStartDate();
  }
}
