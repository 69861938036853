import {Action, ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {creationReducer, CreationState, metaReducer} from './creationReducers';
import {liveReducer, LiveState, metaLiveReducer} from './livePollReducers';

export interface AppState {
  live: LiveState;
  creation: CreationState;
}

const reducers: ActionReducerMap<AppState> = {
    live: liveReducer,
    creation: creationReducer,
};

export function logger(reducer: ActionReducer<AppState, Action>) : ActionReducer<AppState, Action> {
    return (state, action) => {
      return reducer(state, action)
    }
}

export const metaReducers: MetaReducer<AppState, Action>[] = [logger, metaReducer, metaLiveReducer];

export const appReducer = {
  reducers,
  metaReducers,
};
