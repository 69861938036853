import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {PollChoice, EditedPollChoice} from '../../../models/PollChoice';
import { Common } from "../../../shared/common";

@Component({
  selector: 'vc-input-assessor',
  templateUrl: 'input-assessor.component.html',
  styleUrls: ['./input-assessor.component.less']
})

export class InputAssessorComponent implements OnInit {
  @Input() index: number
  @Input() choice: PollChoice
  @Input() name: string
  @Input() mail: string
  @Input() assessorsSize: number
  @Input() isValidMail: boolean = true
  @Input() isValidName: boolean = true
  @Input() displayError: boolean = false;

  @Output() sendNamesEvent = new EventEmitter<string>();
  @Output() sendMailsEvent = new EventEmitter<string[]>();
  @Output() deleteEvent = new EventEmitter<number>();
  @Output() sendSaveEvent = new EventEmitter<boolean>();
  nameCharacters: number = 0;
  mailCharacters: number = 0;

  constructor(
    public common: Common) {}

  saveAssessorToDraft() {
    this.sendSaveEvent.emit(true);
  }

  isCorrectMail($event) {
    if ($event.target.value.localeCompare("") != 0) {
      this.common.validateEmail($event.target.value) == true ? this.isValidMail = true : this.isValidMail = false
      if (this.isValidMail && this.displayError) {
        this.displayError = false
        this.isValidName = !(this.name.localeCompare("") == 0 || this.name.length >= 3)
      }
    }
  }

  isCorrectName($event) {
    if ($event.target.value.localeCompare("") != 0) {
      this.isValidName = $event.target.value.length >= 3
      if (this.isValidName && this.displayError) {
        this.displayError = false
        this.isValidMail = !(this.mail.localeCompare("") == 0 || this.common.validateEmail(this.mail))
      }
    }
  }

  onNameInput($event) {
    if ($event.target.value.length >= 3)
      this.isCorrectName($event)
    this.sendNamesEvent.emit($event.target.value)
    this.nameCharacters = $event.target.value.length;
  }

  onMailInput($event) {
    if ($event.target.className.includes("mail-error")) {
      this.isCorrectMail($event)
    }
    if (!this.isValidMail)
      this.isCorrectMail($event)
    this.sendMailsEvent.emit($event.target.value)
    this.mailCharacters = $event.target.value.length;
  }

  delete() {
    this.deleteEvent.emit(this.index)
  }

  ngOnChanges() {
    this.mailCharacters = this.mail.length;
    this.nameCharacters = this.name.length;
  }

  ngOnInit() {
  }
}
