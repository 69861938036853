<div class="form-holder">
  <div class="safe-area">
    <h1 class="objective" [innerHTML]="replaceURLs(description)"></h1>
    <h1 class="objective" [innerHTML]="replaceURLs(sharedLinks)"></h1>
    <div class="rejoin-holder">
      <span class="status">{{'POLL-PLANNED.TITLE' | translate}}</span>
    </div>
    <div class="separator"></div>
    <div class="infos-holder">
      <div class="small-holder">
        <img class="description-icon" src="assets/calendar-small.svg">
        <div>
          <h3 class="descriptor">{{'POLL-PLANNED.START' | translate}}</h3>
          <h3 class="info">{{startDate}}</h3>
        </div>
      </div>
      <div>
        <h3 class="descriptor">{{'POLL-PLANNED.END' | translate}}</h3>
        <h3 class="info">{{endDate}}</h3>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/election-simple.svg">
        <div>
          <h3 class="descriptor">{{'POLL-PLANNED.VOTERS' | translate}}</h3>
          <h3 class="info-bold">{{votersCount}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
