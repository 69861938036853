import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LicenseResponse, LicenseData } from '../models/LicenseData';
import { ApiService } from './api.service';
import {environment} from '../../environments/environment';
import {BoughtItem, ShoppingCart} from '../models/PaymentData';
import {ContactData} from '../models/ContactData';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  public license: Observable<LicenseData>;
  private licenseSubject: BehaviorSubject<LicenseData>;
  private stripe: stripe.Stripe;
  private stripeConstants = environment.stripeConstants;

  constructor(private apiService: ApiService) {
    this.licenseSubject = new BehaviorSubject<LicenseData>(JSON.parse(localStorage.getItem('currentLicense')));
    this.license = this.licenseSubject.asObservable();
    this.refreshLicense();
    this.stripe = Stripe(this.stripeConstants.key);
  }

  refreshLicense() {
    this.apiService.getLicenses().subscribe((res: LicenseResponse) => {
      const updatedLicense = res.licences;
      localStorage.setItem('currentLicense', JSON.stringify(updatedLicense));
      this.licenseSubject.next(updatedLicense);
    });
  }

  buyHotline(transactionID: string) {
    const items = [new BoughtItem(transactionID, 1)];
    const cart = new ShoppingCart(
      items,
      this.stripeConstants.tax20,
      null
    );
    this.apiService.createCheckoutSessionDashboard(cart).subscribe((res) => {
      const sessionKey = 'sessionId';
      this.stripe
        .redirectToCheckout({ sessionId: res[sessionKey] })
        .then((result) => {
          console.log(result);
        });
    });
  }



  setHotlineOption($event: number, isAssoc: boolean) {
    switch ($event) {
      case 0: {
        const hotlineCode: string = isAssoc ? this.stripeConstants.hotline.assoc.oneEvent : this.stripeConstants.hotline.corp.oneEvent;
        this.buyHotline(hotlineCode);
        break;
      }
      case 1: {
        const hotlineCode = isAssoc ? this.stripeConstants.hotline.assoc.fiveEvents : this.stripeConstants.hotline.corp.fiveEvents;
        this.buyHotline(hotlineCode);
        break;
      }
      case 2: {
        const hotlineCode = isAssoc ? this.stripeConstants.hotline.assoc.anual : this.stripeConstants.hotline.corp.anual;
        this.buyHotline(hotlineCode);
        break;
      }
    }
  }

  buyOffer($event: ShoppingCart) {
    this.apiService.createCheckoutSessionDashboard($event).subscribe((res) => {
      const sessionKey = 'sessionId';
      this.stripe
        .redirectToCheckout({ sessionId: res[sessionKey] })
        .then((result) => {
          console.log(result);
        });
    });
  }

  createCart(offer: string, smsCode: string = '', elseCondition: () => void) {
    const items = offer.length > 0 ? [new BoughtItem(offer, 1)] : [];
    if (smsCode !== null && smsCode.length > 0) { items.push(new BoughtItem(smsCode, 1)); }
    if (items.length > 0) {
      const cart = new ShoppingCart(
        items,
        this.stripeConstants.tax20,
        null
      );
      this.buyOffer(cart);
    } else {
      elseCondition(); }
  }

  setSMSOption($event: number, selectedOffer: number, isAssoc: boolean, closure: () => void) {
    let smsCode = null;
    switch ($event) {
      case 0: {
        smsCode = null;
        break;
      }
      case 1: {
        smsCode = isAssoc !== false ? environment.stripeConstants.sms.assoc.pack200 : environment.stripeConstants.sms.corp.pack200;
        break;
      }
      case 2: {
        smsCode = isAssoc !== false ? environment.stripeConstants.sms.assoc.pack500 : environment.stripeConstants.sms.corp.pack500;
        break;
      }
      case 3: {
        smsCode = isAssoc !== false ? environment.stripeConstants.sms.assoc.pack1000 : environment.stripeConstants.sms.corp.pack1000;
        break;
      }
      case 4: {
        smsCode = isAssoc !== false ? environment.stripeConstants.sms.assoc.pack5000 : environment.stripeConstants.sms.corp.pack5000;
        break;
      }
    }
    this.selectOffer(selectedOffer, smsCode, closure);
  }

  selectOffer(index: number, smsOption: string = '', closure: () => void) {
    let price = '';
    switch (index) {
      case 0: { price = this.stripeConstants.event.association; break; }
      case 1: { price = this.stripeConstants.pack.asso50; break; }
      case 2: { price = this.stripeConstants.pack.asso1000; break; }
      case 3: { price = this.stripeConstants.pack.asso2ag; }
    }
    this.createCart(price, smsOption, closure);
  }

  getContactMailObject(selectedOffer: number, accountName: string, isAssoc: boolean, $event: string): ContactData {
    let offer: string;
    switch (selectedOffer) {
      case 1: {
        offer = 'Pack 1 evenement';
        break;
      }
      case 2: {
        offer = 'Pack 5 evenements';
        break;
      }
      case 3: {
        offer = 'Abonnement annuel';
        break;
      }
      default: {
        offer = 'Offre gratuite';
      }
    }
    const mailString =
      'Changement d\'offre<br><br>E-mail: ' +
      accountName +
      '<br>Association: ' +
      isAssoc +
      '<br>Numéro de téléphone: ' +
      $event +
      '<br>Offre: ' +
      offer;
    return new ContactData(mailString);
  }

}
