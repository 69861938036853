
<h2 *ngIf="!calculating" class="title-result">{{'POLL-FINISHED.TIMER-TITLE' | translate}}</h2>
<div *ngIf="assessorsValidated || !hasAssessors">
  <div class="form-holder" *ngIf="!(type === 'list')">
    <div class="safe-area">
        <div *ngIf="calculating" class="calculating">
          <h2 class="title">{{'POLL-FINISHED.CALCULATING' | translate}}</h2>
        </div>
        <div *ngIf="isResolution === false && !calculating">
          <vc-poll-results-list *ngFor="let results of tmpResults ; let i = index"
            [question]="pollData.rounds[0].displayedQuestions[i].question"
            [results]="results" [index]="i">
          </vc-poll-results-list>
        </div>
        <div *ngIf="isResolution === true && displayIndex === -1 && !calculating">
          <vc-poll-results-resolution-list
            *ngFor="let result of resolutionResults; let i = index"
            [index]="i" [result]="result">
          </vc-poll-results-resolution-list>
        </div>
        <div *ngIf="isResolution === true && displayIndex != -1 && !calculating">
          <vc-poll-results-resolution-list [index]="displayIndex"
            [result]="resolutionResults[displayIndex]">
          </vc-poll-results-resolution-list>
        </div>
    </div>
    <div class="delete-button-container" *ngIf="!isActive && !calculating">
      <button class="delete-button" (click)="deletePopup()">
        {{'POLL-DELETION.DELETE' | translate}}
      </button>
    </div>
  </div>
  <div class="form-holder" *ngIf="calculating && type === 'list'">
    <div class="safe-area">
      <div  class="calculating">
        <h2 class="title">{{'POLL-FINISHED.CALCULATING' | translate}}</h2>
      </div>
    </div>
  </div>
  <div *ngIf="type === 'list' && !calculating && pollData.rounds.length >= 1">
    <div *ngFor="let result of listResults; let i = index" class="list-holder">
      <div class="form-holder">
        <div class="safe-area">
          <h2 *ngIf="!calculating" class="title">{{pollData.rounds[i].title}}</h2>
          <a href="{{blockchainLink}}/{{i}}" target="_blank" rel="noopener noreferrer" *ngIf="type == 'list'" class="blockchain-link">
            <img src="assets/global.svg" class="global-icon" /> {{'POLL-PLANNED.POLL-BLOCKCHAIN' | translate}}
          </a>
          <app-list-results-list [results]="result">
          </app-list-results-list>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="global-container" *ngIf="!assessorsValidated && hasAssessors">
  <div class="gray-container">
    <div class="text-container">
      <h1 class="missing-assessors-title">{{'POLL-WARNING.CATCH' | translate}}</h1>
      <p class="missing-assessors-description">{{'ASSESSORS.ASSESSORS-UNVALIDATED' | translate}}</p>
    </div>
  </div>
  <p *ngIf="hasAssessorsButtons" class="resolutions-participation">
    <span class="assessor-title" style="margin-right: 10px;">{{'ASSESSORS.ASSESSOR-TITLE' | translate}}</span>
    <span class="participation-label">{{assessorCount - inactiveAssessorCount}}/{{assessorCount}}</span>
    <progress max="100" value={{assessorValidationPercentage}}> 70% </progress>
  </p>
  <div class="button-container">
    <button class="white-button" (click)="listAssessors()">
      {{'TIMER.ASS-LISTE' | translate}}
    </button>
    <button *ngIf="canResendAssessors" class="blue-button" (click)="relaunchAssessors()">
      {{'TIMER.ASS-RELAUNCH' | translate}}
    </button>
    <button *ngIf="!canResendAssessors" class="inactive-button">
      {{'TIMER.ASS-RELAUNCH' | translate}}
    </button>
  </div>
  <div *ngIf="hasAssessorsButtons == true && canResendAssessors == false" style="text-align: center;">
    <div *ngIf="remainingAssessorTime != null" class="clock-holder">
      <img class="clock-icon" src="assets/horologe.svg">
      <p class="description">{{'POLL-PLANNED.ASS-RESEND-TIME' | translate}}{{remainingAssessorTime}}</p>
    </div>
  </div>
</div>
<vc-warning-popup *ngIf="warningPopup" title="{{'POLL-DELETION.COMPLETE' | translate}}" [confirmButton]="true" [cancelButton]="true" (endDetailsPopup)="dismissDeletePopup($event)" [separator]="false">
</vc-warning-popup>
