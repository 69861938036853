<div class="div-arriere-plan-action" (click)="closeValidationPopup()"></div>
<div class="center-square">
  <img class="close-icon" src="assets/close_24px.svg" (click)="closeValidationPopup()">
  <img src="assets/voted-ok.svg" class="result-image">
  <h1 class="header_title">
    {{'CHANGE-VOTER.VALIDATION.TITLE' | translate}}
  </h1>
  <h1 class="header_description">
    {{'CHANGE-VOTER.VALIDATION.DESCRIPTION' | translate}}
  </h1>
</div>
