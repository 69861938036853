<div class="line-holder">
  <h3 class="resolution-index" id="resolution-{{index}}" (click)="fileInput.click()">{{index+1}}
    <input style="display: none" type="file" accept=".jpeg, .jpeg, .gif, .png" (change)="onFileChanged($event.target.files); fileInput.value=''" #fileInput>
  </h3>
  <div id="uploaded-wrapper-{{index}}" class="resolution-images-wrapper">
    <img id="uploaded-{{index}}" class="resolution-images" src="#" alt="">
  </div>
  <textarea type="text" id="name" (keyup)="onNameInput($event)" (input)="common.autoHeight($event, 10)" maxlength="255" placeholder="{{'LIVE.POLL-RESOLUTION-PLACEHOLDER' | translate}}"
    [ngClass]="isValid == true ? 'valid-input' : 'invalid-input'" style="overflow:hidden"></textarea>
    <div class="link-icon">
      <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="out()" id="input-{{index}}"/>
        <img *ngIf="!blueLinkIcon" class="icons" (click)="getInput()" style="margin-bottom: 1px;" src="assets/grey-link.svg">
        <img *ngIf="blueLinkIcon" class="icons" (click)="getInput()" style="margin-bottom: 1px;" src="assets/blue-link.svg">
    </div>
  <div class="delete-container">
    <div class="icon-container">
      <img id="delete-button" (click)="delete()" src="assets/deletebutton.svg">
    </div>
  </div>
</div>
<div [ngClass]="eventTitleCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{eventTitleCharacters}}/255</div>
<div class="second-line-holder">
  <button  *ngIf="(!status || status.status < 1) && isValid" type="button" class="start-button-active" (click)="startResolution()">{{'LIVE.CONFIRM-ADD-RESOLUTION' | translate}}</button>
  <button  *ngIf="(!status || status.status < 1) && !isValid" type="button" class="inactive-button" style="margin-right: 38px;">{{'LIVE.CONFIRM-ADD-RESOLUTION' | translate}}</button>
</div>
<input type="text" id="argument" (keyup)="onArgumentInput($event)" placeholder="Argumentaire">

<!--
-- POPUP FOR BOTH MODE
-->
<vc-warning-popup class="image-error" *ngIf="displayImageError"
  [understoodButton]="true" (endDetailsPopup)="dismissPayPopup()"
  title="{{'UPLOAD-IMAGES.POPUP-TEXT' | translate}}">
</vc-warning-popup>