<div class="div-arriere-plan-action"></div>
<div class="div-global-action">
  <div id="center-square">
    <div id="blue-lines"></div>
    <div style="display: flex; justify-content: space-between; flex-direction: column; height: 100%">
      <div *ngIf="!isAssessors">
        <h3 class="header_title" *ngIf="isResend">{{'TIMER.RESEND-WARNING' | translate}}</h3>
        <h3 class="header_title" *ngIf="!isResend">{{'TIMER.CONVOCATION-WARNING' | translate}}</h3>
        <div class="blue-line"></div>
      </div>
      <div *ngIf="isAssessors">
        <h3 class="header_title">{{'TIMER.ASSESSORS' | translate}}</h3>
        <div class="blue-line"></div>
      </div>
      <div style="display: flex; justify-content: space-between; flex-direction: column; min-height: 120px;
      margin-bottom: 12px;">
        <button *ngIf="isResend" data-cy="resend-invites-btn" type="button" class="button-see-more" (click)=onDismiss(true)>{{'UNDERSTOOD' | translate}}</button>
        <button *ngIf="isResend" data-cy="resend-invites-btn" type="button" class="button-cancel" (click)=onDismiss(false)>{{'DETAILS-CONTACT.CANCEL' | translate}}</button>
        <button *ngIf="!isResend" data-cy="resend-invites-btn" type="button" class="button-see-more" (click)=onDismiss(true)>{{'TIMER.CONVOCATION-LAUNCH' | translate}}</button>
        <button *ngIf="!isResend" data-cy="resend-invites-btn" type="button" class="button-cancel" (click)=onDismiss(false)>{{'TIMER.CONVOCATION-CANCEL' | translate}}</button>
        <!-- <p class="disclaimer">{{'TIMER.DISCLAIMER' | translate}}</p> -->
      </div>
    </div>
  </div>
</div>
