import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LiveQuestion, LiveResolution, Resolution,} from './../models/PollChoice';
import {ReceivedPoll} from '../models/ReceivedPoll';
import {AddedPollQuestion, PollQuestion} from './../models/PollCreationData';
import {ApiService} from '../shared/api.service';
import {LiveService} from '../shared/live.service';
import {WebsocketService} from '../shared/websocket.service';
import {LicenseData} from './../models/LicenseData';
import {LicenseService} from '../shared/licens.service';
import {Poll} from './../models/Poll';
import {AuthenticationService} from '../shared/authentication.service';
import { v4 } from "uuid";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase";
import { Store } from '@ngrx/store';
import { LiveState } from '../states/livePollReducers';
import { CreationState } from '../states/creationReducers';
import {Common} from '../shared/common';

@Component({
  selector: 'app-live-creation',
  templateUrl: 'poll-live.component.html',
  styleUrls: ['./poll-live.component.less'],
  providers: [WebsocketService, LiveService],
})

export class LivePollComponent implements OnInit, OnDestroy {
  electionUpdate = false;
  votersCount = 0;
  isError = false;
  errorMessage: string;
  isLiveOpen = true;
  livePollId: string;
  isClosing = false;
  resolutions = [];
  choices: Array<PollQuestion> = [];
  liveResolutions = [new LiveResolution(0, new Resolution(true), 0)];
  liveChoiceEvents: Array<LiveQuestion> = [];
  pollStatus: Array<number>;
  activeIndex = 0;
  timer: any;
  canBeClosed = true;
  poll: Poll;
  maxVoters: number;
  maxSMSVoters: number;
  licenseTime: string;
  eventCounter: number;
  licenceEnd: Date;
  clientType = 'assoc';
  canAddQuestions = true;
  accountName: string;
  loader = false;
  deleteRoundPopup: boolean;
  roundToDelete: number;
  imageBlob: Blob;
  pollImageExt: any;
  candidatesImagesExt: Array<string> = [];
  newResolution: Resolution;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
    private liveService: LiveService,
    private licenseService: LicenseService,
    private store: Store<LiveState>,
    private common: Common,
  ) {}

  navigateBack() {
    window.history.back();
  }

  navigateHome() {
    this.router.navigate(['/polls']);
  }

  confirmDeletion($event) {
    $event ? this.deletePendingRound(this.roundToDelete) : this.closeDeletePopup();
  }

  popupEvent(data: boolean) {
    if (data) {
      this.liveService.send('close', this.livePollId);
      this.isClosing = false;
      this.liveService.send('status', this.livePollId);
      this.loader = true;
      this.apiService.closeElection(this.livePollId).subscribe((res) => {
        this.loader = false;
        this.canAddQuestions = false;
        this.canBeClosed = false;
        this.router.navigateByUrl('/poll-result?poll=' + this.livePollId);
      });
    } else {
      this.isClosing = false;
    }
  }

  closePoll() {
    this.isClosing = true;
    this.isLiveOpen = false;
  }

  getCommand(data) {
    this.loader = true;
    if (data.command === 'open') { this.electionUpdate = !this.electionUpdate; }
    this.liveService.send(data.command + ' ' + data.index, this.livePollId);
    console.log(data.command + ' ' + data.index, this.livePollId);

    this.liveService.send('status', this.livePollId);
  }

  getResults(data: number) {
    if (this.poll.votingType === 'resolution' || this.poll.votingType === 'live' ||
        this.poll.votingType === 'liveCandidates' || this.poll.votingType === 'election') {
      if (( (this.poll.votingType === 'resolution' || this.poll.votingType === 'live') && this.liveResolutions[data].status === 2)
      || ((this.poll.votingType === 'liveCandidates' || this.poll.votingType === 'election') && this.liveChoiceEvents[data].status === 2)) {
        this.router.navigateByUrl(
          '/poll-result?poll=' + this.livePollId + '&resolution=' + data
        );
      } else {
          this.router.navigateByUrl('/poll-ongoing/' + this.livePollId + '?resolution=' + data);
      }
    }
  }

  triggerDeletePopup($event: number) {
    this.deleteRoundPopup = true;
    this.roundToDelete = $event;
  }

  closeDeletePopup() {
    this.deleteRoundPopup = false;
  }

  deletePendingRound($event) {
    this.deleteRoundPopup = false;
    this.apiService.deletePendingRound(
      this.livePollId, $event
    ).subscribe((res) => {
      this.getPoll();
    });
  }

  sendNewResolution() {
    const choices: Array<string> = [];
    for (const choice of this.newResolution.choices) {
      choices.push(choice.name);
    }

    const createdQuestion = new AddedPollQuestion(
      new PollQuestion(this.newResolution.name, choices, 1, [this.newResolution.link], null, [this.newResolution.image]),
      1
    );

    const sentResolution = JSON.stringify(createdQuestion);
    this.loader = true;

    this.apiService
      .updatePoll(this.livePollId, sentResolution)
      .subscribe((res) => {
        this.getPoll();
        this.liveService.send('status', this.livePollId);
    });
  }

  sendNewCandidate(data: AddedPollQuestion) {
    if (data.question.answers.length >= 1) {
      data.question.listImages = this.candidatesImagesExt;
      this.apiService
        .updatePoll(this.livePollId, JSON.stringify(data))
        .subscribe((res) => {
          this.candidatesImagesExt = [];
          this.getPoll();
        });
    }
  }

  dismissError() {
    this.isError = false;
  }

  setupLicenceInfo() {
    this.licenseService.license.subscribe((res: LicenseData) => {
      this.maxVoters = res.votes;
      this.maxSMSVoters = res.sms;
      this.eventCounter = res.events;
      this.licenceEnd = res.endDate;
      this.clientType = res.client;
      this.licenseTime = this.common.licenseResponseGetTime(res);
      this.accountName = this.authenticationService.getUserData();
    });
    this.licenseService.refreshLicense();
  }

  uploadImage($event) {
    this.imageBlob = $event[1]
    this.pollImageExt = $event[0].name.substring($event[0].name.lastIndexOf('.'), $event[0].name.length)
  }

  saveCandidateBlob($event) {
    console.log($event)
    const pollPayload = new FormData();
    const ext = $event[0].name.substring($event[0].name.lastIndexOf('.'), $event[0].name.length);
    const name = v4() + ext;
    pollPayload.append(name, $event[1]);
    this.apiService.saveElectionImage(pollPayload).subscribe((res) => {
      getDownloadURL(ref(storage, res[0])).then((url) => {
        this.candidatesImagesExt[$event[2]] = url;
      })
    })
  }

  getPoll() {
    this.apiService.getPoll(this.livePollId).subscribe(
    (res: ReceivedPoll) => {
      console.log(res);
      this.poll.temporality = res.isLive ? 'live' : 'planned';
      if (this.poll.temporality !== 'live') {
        this.router.navigate(['/polls']);
      }

      this.votersCount = res.nbVoters;
      this.poll.title = res.name;
      this.poll.votingType = res.type;
      this.poll.isSMS = res.smsOption;
      this.poll.is2FA = res.is2FA;
      this.poll.isUncrypted = res.publicVote;
      const startDate = new Date(res.startDate);
      this.canBeClosed = res.hasBeenClosed !== true;
      this.canAddQuestions = (startDate.getTime() + 24 * 60 * 60 * 1000) > new Date().getTime();
      this.canAddQuestions = this.canAddQuestions && this.canBeClosed;
      this.poll.resolutions = [];
      this.resolutions = [];
      this.poll.liveResolutions = [];
      if (res.type === 'live' || res.type === 'resolution') {
        for (const round of res.rounds) {
          if (!round.questions[0].deleted) {

            const newResolution = new Resolution(true);
            newResolution.name = round.questions[0].question;
            this.resolutions.push(newResolution);
            const newLiveResolution = new LiveResolution(
              round.round,
              new Resolution(true),
              0
            );
            this.liveResolutions[this.resolutions.length - 1] =
              newLiveResolution;
            this.poll.resolutions = this.resolutions;
            this.poll.liveResolutions = this.liveResolutions;
          }
        }
      } else {
        this.choices = [];
        this.poll.liveQuestions = [];
        this.liveChoiceEvents = [];
        for (const question of res.rounds) {
          const questionMask: PollQuestion = new PollQuestion(question.displayedQuestions[0].question, question.displayedQuestions[0].answers, question.displayedQuestions[0].numberOfVote)

          const newQuestion = questionMask != null && questionMask !== undefined ? questionMask : question?.displayedQuestions[0];

          if (newQuestion.numberOfVote === 0) { newQuestion.numberOfVote = 1; }


          //if (!question.deleted) {
            this.choices.push(questionMask);
            const newLiveChoice = new LiveQuestion(
              question.round,
              questionMask.answers,
              questionMask.question,
              0
            );
            this.liveChoiceEvents.push(newLiveChoice);
            this.poll.liveQuestions = this.liveChoiceEvents;
          //}
        }
      }
      this.liveService.connect(this.livePollId).subscribe((msg) => {
        if (msg.split(' ')[0] === '200') { this.loader = false; }
        const message = msg.split(' ')[1];
        if (msg.split(' ')[0] === '206') {
          // TODO: add here treatment if someomeen voted
        } else if (msg.split(' ')[0] === '200' && (message === 'participation' || message === 'round')) {
          this.liveService.send('status', this.livePollId);
        }
        if (message.charAt(0) === '[') {
          this.pollStatus = JSON.parse(message);
          for (let i in this.liveResolutions) {
            this.liveResolutions[i].status = this.pollStatus[i];
          }
          for (let i in this.liveChoiceEvents) {
            this.liveChoiceEvents[i].status = this.pollStatus[i];
          }
          this.activeIndex = this.pollStatus.findIndex(
            (element) => element < 2
          );
        }
      });
      this.liveService.send('status', this.livePollId);
      this.poll = JSON.parse(JSON.stringify(this.poll));
      this.timer = setInterval(() => {
        this.liveService.send('status', this.livePollId);
      }, 30000);
    },
    (err: any) => {
      this.isError = true;
      this.errorMessage =
        'Nous sommes désolés mais vous ne pouvez accéder au contenu \
         erreur ' +
        err.status;
      }
    );
  }

  setStoreObserver() {
    this.store.select((state: any) => state.live.poll).subscribe(res => {
      console.log("selecteur");
      console.log(res);

      this.newResolution = res.resolutions
      console.log(this.newResolution);
    })
  }

  ngOnInit() {
    this.setStoreObserver()
    this.poll = new Poll();
    this.livePollId = this.router.url.split('/')[2];
    this.getPoll();
    this.setupLicenceInfo();
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
