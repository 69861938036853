import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {TimeDelta} from '../../models/TimeDelta';

@Component({
  selector: 'vc-poll-ongoing-timer',
  templateUrl: 'poll-ongoing-timer.component.html',
  styleUrls: ['./poll-ongoing-timer.component.less', '../../shared/common.less']
})

export class PollOngoingTimerComponent implements OnInit {
  @Input() timeDelta: TimeDelta;
  @Input() isPublic: boolean;
  @Input() isLive: boolean;
  @Input() sent: boolean;
  @Input() inviteDate: string;
  @Input() timeDiffHours: number;
  @Input() displayChangeVoters = false;
  @Output() shouldDisplayVoters = new EventEmitter<boolean>();
  @Output() shouldRelaunchInvitations = new EventEmitter<boolean>();
  @Output() shouldChangeVoters = new EventEmitter<boolean>();

  constructor() {
  }

  changeVoters() {
    this.shouldChangeVoters.emit(true);
  }

  showVoters() {
    this.shouldDisplayVoters.emit(true);
  }

  relaunchVoters() {
    this.shouldRelaunchInvitations.emit(true);
  }

  ngOnInit() {
  }
}
