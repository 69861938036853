import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Voter } from './../models/Voter';

@Component({
  selector: 'vc-voters-display',
  templateUrl: './voters-display.component.html',
  styleUrls: ['./voters-display.component.less']
})
export class VotersDisplayComponent implements OnInit {
  @Input() voters: Array<Voter>;
  @Input() isImportError: boolean;
  @Output() isClosed = new EventEmitter<boolean>();
  title: string;

  onDismiss() {
    this.isClosed.emit(true);
  }

  ngOnInit() {
    this.title = this.isImportError ? "Erreurs d'import" : "Liste votants";
  }
}
