<div id="page-container">
  <div id="content-wrap">
    <div class="fullScreen">
      <div class="account-header">
        <div *ngIf="maxVoters === 10" style="display: flex; justify-content: space-between; height: 100%">
          <span>
            <p class="banner-text" id="txt">
              {{'POLL-DASHBOARD.HEADBAND.MESSAGE-CREATION' | translate}}
            </p>
          </span>
          <div style="height: 100%; margin-right: 24px; display: flex;">
            <span >
              <p style="margin: 0;" class="banner-text" id="txt2">
                {{accountName}}
              </p>
            </span>
            <img ngSrc="assets/power_settings_new_24px.svg" class="power-button" (click)="logout()"
                 style="cursor: pointer; margin-left: 20px;" loading="lazy" height="18" width="20">
          </div>
        </div>

        <div *ngIf="maxVoters > 10" style="margin-right: 24px; height: 100%; display: flex; justify-content: space-between;">
          <div *ngIf="hasHotline else sub">
            <p class="banner-text hotline-display">
              {{'POLL-DASHBOARD.HEADBAND.PHONE' | translate}}
            </p>
          </div>
          <ng-template #sub>
            <div></div>
          </ng-template>

          <span>
            <p class="banner-text" *ngIf="licenseTime == 'D'">
              {{accountName}} - {{'POLL-DASHBOARD.HEADBAND.EVENTS' | translate}}
            </p>
            <p class="banner-text" *ngIf="licenseTime == 'DM'">
              {{accountName}} - {{eventCounter}} {{'POLL-DASHBOARD.HEADBAND.PACK' | translate}}
            </p>
            <p class="banner-text" *ngIf="licenseTime == 'Y'">
              {{accountName}} - {{'POLL-DASHBOARD.HEADBAND.YEARLY' | translate}}
            </p>
            <img src="assets/power_settings_new_24px.svg" (click)="logout()" loading="lazy"
            style="cursor: pointer; margin-left: 20px; height: 24px; width:24px; margin-top: 7px">
          </span>

        </div>
      </div>
      <img src="assets/v8te.png" id="logo" (click)="goToPoll()" loading="lazy">
      <div class="arrow-container">
        <img class="arrow" (click)="backArrow()" data-cy="back-button" src="assets/arrow-left.svg" style="cursor: pointer;" loading="lazy">
        <h3 class="title" style="margin-top: 20px;">{{'POLL-CREATION.MAIN.TITLE' | translate}}</h3>
      </div>

    </div>
    <div class="container-creation">
      <div class="creation" [hidden]="shouldDisplayLive">
        <vc-poll-creation-form [disableTemporalitySelection]="disableTemporalitySelection"[shouldResume]="shouldResume" [editionMode]=false>
        </vc-poll-creation-form>
        <vc-voters-list (sendVotersList)="getVoters($event)" (sendEmailError)="invalidEmail()"
          (viewVoters)="displayVoters()" (isOutdatedFile)="displayOutdatedFileError($event)" [isSMS]="currentPoll.isSMS"
          [shouldResume]="shouldResume" [editVoters]="currentPoll.voters" [is2FA]="currentPoll.is2FA">
        </vc-voters-list>
        <vc-type-vote *ngIf="currentPoll.temporality != 'live'" [shouldResume]="shouldResume" [currentPoll]="currentPoll"
          [editMode]=true (sendPollImage)="setImageName($event)"
          (sendDraftSave)="markPollChanges()">
        </vc-type-vote>

        <vc-voted-list class="planned-resolution" *ngIf="currentPoll.temporality != 'live' && currentPoll.votingType == 'resolution'"
          [shouldResume]="shouldResume" (sendCommand)="getCommand($event)" [editMode]=false [currentPoll]="currentPoll"
          (numberOfVote)="numberOfVote" (sendResolutionImageEvent)="saveResolutionImage($event)" (sendDeselect)="markPollChanges()">
        </vc-voted-list>

        <vc-assessors-toggle class="assessor-toggle" *ngIf="currentPoll.temporality != 'live' && currentPoll.votingType != 'resolution'" >
        </vc-assessors-toggle>
        <vc-assessors-fields *ngIf="currentPoll.temporality != 'live' && currentPoll.votingType != 'resolution' && currentPoll.isAssessors"
          class="assessor-fields" (saveAssessorData)="markPollChanges()" [displayError]="assessorError">
        </vc-assessors-fields>

        <div *ngFor="let liveCandidateEvent of currentPoll.liveQuestions; let i = index; trackBy: trackByFn">
          <vc-voted-list *ngIf="currentPoll.temporality != 'live' && currentPoll.votingType == 'election'"
            class="election-pannel" [subject]="currentPoll.liveQuestions[i].title" [shouldResume]="shouldResume"
            [choices]="currentPoll.choices[i]" [index]="i" (sendCommand)="getCommand($event)"
            [numberOfVote]="currentPoll.liveQuestions[i].numberOfVote" (sendDeselect)="markPollChanges()"
            [currentPoll]="currentPoll" [editMode]=shouldResume (sendCandidatesImageEvent)="setCandidatesImage($event)"
            [deleteEnabled]="i > 0 || currentPoll.liveQuestions.length > 1" (deleteQuestions)="deleteQuestion($event)">
          </vc-voted-list>
          <vc-voted-list *ngIf="currentPoll.temporality != 'live' && currentPoll.votingType == 'list'"
            class="corporate-pannel" [index]="i" [currentPoll]="currentPoll" [subject]="currentPoll.liveQuestions[i].title" [editMode]=shouldResume
            [isMainList]="currentPoll.electionIndexes.includes(i)" [numberOfList]="currentPoll.electionIndexes.indexOf(i) + 1"
            (sendListImage)="setListImage($event)" (sendCandidatesImageEvent)="setPropositionsImage($event)" (sendDeselect)="markPollChanges()">
          </vc-voted-list>
        </div>
        <div *ngIf="currentPoll.votingType == 'election' && currentPoll.temporality != 'live' && !hideAddElectionButton"
            class="add-live-candidate-container">
            <button *ngIf="currentPoll.votingType == 'election'" class="add-candidate-event" (click)="addEvent()">
              +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.ADD-ELECTION' | translate}}
            </button>
          </div>
        <div *ngIf="currentPoll.votingType == 'list'" class="add-live-candidate-container">
          <div id="buttons-wrapper">
            <div class="left-button-block">
              <button class="add-candidate-event" (click)="addEvent()">
                +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.ADD-LIST' | translate}}
              </button>
              <button *ngIf="currentPoll.votingType == 'list' && currentPoll.electionIndexes[currentPoll.electionIndexes.length - 1] != currentPoll.liveQuestions.length - 1"
                id="delete-candidate-event" (click)="deleteList()">
                <img src="assets/red-trash.svg" class="trash" loading="lazy">
                &nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.LIST' | translate}}
              </button>
            </div>
            <div class="left-button-block">
              <button *ngIf="currentPoll.votingType == 'list' && currentPoll.electionIndexes.length > 1" id="delete-election-event" (click)="deleteElection()">
                <img src="assets/white-trash.svg" class="trash" loading="lazy">
                &nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.DELETE-ELECTION' | translate}}
              </button>
            </div>
          </div>
          <span class="separator"></span>
          <button *ngIf="currentPoll.votingType == 'list' && currentPoll.electionIndexes.length < 4" id="add-election-event" (click)="confirmCreationElection(true)">
            +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.ADD-ELECTION-LIST' | translate}}
          </button>
        </div>
      </div>
        <div [hidden]="!shouldDisplayLive" id="live-launcher">
          <vc-type-vote class="live-vote" [currentPoll]="currentPoll" [shouldResume]="shouldResume"
            (sendPollImage)="setImageName($event)" (sendDraftSave)="markPollChanges()">
          </vc-type-vote>
          <!-- Voted type triggered for live resolution -->
          <vc-voted-list class="live-resolution" *ngIf="isLiveResolution" [isLaunchEnabled]="isLaunchEnabled"
            [currentPoll]="currentPoll" [shouldResume]="shouldResume" (sendDeselect)="markPollChanges()" [index]="0"
            (sendCommand)="getCommand($event)" [editMode]=shouldResume (sendResolutionImageEvent)="saveResolutionImage($event)">
          </vc-voted-list>

          <!-- Voted type triggered for live election -->
          <div *ngIf="currentPoll.votingType == 'election' && currentPoll.temporality == 'live'">
            <div *ngFor="let liveCandidateEvent of currentPoll.liveQuestions; let i = index; trackBy: trackByFn">
              <vc-voted-list class="live-election" [subject]="currentPoll.liveQuestions[i].title"
                [shouldResume]="shouldResume" [editMode]=shouldResume [currentPoll]="currentPoll"
                (sendCommand)="getCommand($event)" [index]="i" [isLaunchEnabled]="isLaunchEnabled && i == 0"
                (sendCandidatesImageEvent)="setCandidatesImage($event)" (sendDeselect)="markPollChanges()"
                [deleteEnabled]="i > 0 || currentPoll.liveQuestions.length > 1" (deleteQuestions)="deleteQuestion($event)">
              </vc-voted-list>
            </div>
          </div>

          <div *ngIf="currentPoll.votingType == 'election'" class="add-live-candidate-container">
          <button class ="add-candidate-event" (click)="addEvent()">
            +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.ADD-ELECTION' | translate}}
          </button>
          </div>
        </div>

        <app-confirmation-component [votersCount]="votersCount" [smsVoterCount]='smsVoterCount'
          (sendLive)="getLiveCommand($event)" [shouldDisplayLive]="shouldDisplayLive" [listElectionCount]="currentPoll.electionIndexes.length"
          (sendPoll)="displayLaunchWarning()" (sendLogout)="goToPoll()" [isPollComplete]="isPollComplete"
          [canLaunchLive]="canLaunchLive" [maxVoters]="maxVoters" [poll]="currentPoll" [maxSMSVoters]="maxSMSVoters"
          [licenceTime]="licenseTime" [eventCounter]="eventCounter" [licenceEnd]="licenceEnd"
          [editionMode]=false [numberOfAssessors]="numberValidAssessors">
        </app-confirmation-component>
      </div>
    <div *ngIf="isError">
      <vc-warning-popup (endDetailsPopup)="dismissError()" [title]='errorMessage' [firstText]='errorDescription' [understoodButton]=true>
      </vc-warning-popup>
    </div>

    <!--
     -- POPUP FOR BOTH MODE
     -->
     <vc-warning-popup class="voter-error" *ngIf="isPaymentError && changeOfferReason == 'addVoters'"
      [phoneInput]="true" [callButton]="true" [cancelButton]="true" (endDetailsPopup)="dismissPayPopup()"
      title="{{'DETAILS-CONTACT.MORE-PHONE' | translate}}" (contactPhone)="getContactPhone($event, 'phone')">
    </vc-warning-popup>

    <vc-warning-popup *ngIf="startDateError" class="edition-date-warning"
      title="{{'EDIT-POPUP.START-DATE' | translate}}" [separator]="true" [understoodButton]="true"
      (endDetailsPopup)="dismissError()">
    </vc-warning-popup>

    <!--
     -- POPUP FOR CREATION MODE
     -->

    <vc-offers-popup *ngIf="isPayment && !paymentPopup" source="{{'payment'}}" [voters]="currentPoll.voters" (isClosed)="dismissPay()" (selectedOffer)="buyOffer($event)"
    (moreInfoEmitter)="displayMoreInfo($event)" (displaySMSEmitter)="displaySMSOption($event)" [isAssoc]="isAssoc">
    </vc-offers-popup>

    <!-- Popup to select an SMS option with the package -->
    <vc-warning-popup *ngIf="smsAdditionalOption" class="trigger-sms-popup-option" [isAssoc]="isAssoc"
      [SMSOffers]="true" title="{{'PRICE-DETAILS.SMS-TITLE' | translate}}" [separator]="true"
      [noSMSButton]="true" (SMSOptionEmitter)="setSMSOption($event)" (endDetailsPopup)="dismissInitialPopup()"
      [voters]="currentPoll.voters" [isSMS]="currentPoll.isSMS" [is2FA]="currentPoll.is2FA">
    </vc-warning-popup>

    <!-- Popup to select an SMS option without a package -->
    <vc-warning-popup *ngIf="smsOption || (isPaymentError && changeOfferReason == 'addSMS')"
      class="trigger-sms-popup" [isAssoc]="isAssoc" [separator]="true" [SMSOffers]="true"
      title="{{'PRICE-DETAILS.SMS-ONLY-TITLE' | translate}}"  (SMSOptionEmitter)="setOnlySMSOption($event)"
      [noSMSButton]="true" (endDetailsPopup)="setOnlySMSOption(0)"
      [voters]="currentPoll.voters" [isSMS]="currentPoll.isSMS" [is2FA]="currentPoll.is2FA" [SMSOwned]="maxSMSVoters">
    </vc-warning-popup>

    <!-- Popup to display more options -->
    <vc-warning-popup *ngIf="moreOption" title="{{'PRICE-DETAILS.TITLE' | translate}}"
    [callButton]="true" [separator]="false" [cancelButton]="true" (endDetailsPopup)="openOffers($event)"
    [isAssoc]="isAssoc" [index]="selectedOffer"></vc-warning-popup>

    <!-- Popup to display error that there are not enough voters for list -->
    <vc-warning-popup *ngIf="shouldDisplayListWarning" title="{{'POLL-WARNING.LIST-VOTERS' | translate}}"
      [understoodButton]="true" [separator]="false" (endDetailsPopup)="closeListMessageError()">
    </vc-warning-popup>

    <!-- Popup to get contacted by v8te for more information -->
    <vc-warning-popup *ngIf="getContacted" [index]="-1" (endDetailsPopup)="dismissInitialPopup()"
      title="{{'DETAILS-CONTACT.TITLE' | translate}}" [phoneInput]="true" [understoodEndButton]="true"
      (contactPhone)="getContactForOption($event)">
    </vc-warning-popup>

    <!-- Popup to confirm that the user will be contacted -->
    <vc-warning-popup *ngIf="confirmContact" title="{{'DETAILS-CONTACT.THANK' | translate}}"
      firstText="{{'DETAILS-CONTACT.CONFIRMATION' | translate}}" secondText="{{'DETAILS-CONTACT.CONTACT' | translate}}"
      [understoodEndButton]="true" (endDetailsPopup)="dismissInitialPopup()" [separator]="false">
    </vc-warning-popup>

    <vc-warning-popup *ngIf="shouldDisplay2FAWarning" title="{{'POLL-WARNING.CATCH' | translate}}" firstText="{{'POLL-WARNING.2FA-VOTERS' | translate}}"
      [continueButton]="true" [changeVoters]="true" (endDetailsPopup)="handleLaunchWarning($event)">
    </vc-warning-popup>

    <div *ngIf="shouldDisplayLaunchWarning  && currentPoll.temporality != 'live'">
      <vc-warning-popup class="launch-planned" title="{{'POLL-WARNING.PLANNED' | translate}}"
        [understoodButton]="true" [cancelButton]="true" (endDetailsPopup)="handleLaunchWarning($event)">
      </vc-warning-popup>
    </div>

    <div *ngIf="shouldDisplayLaunchWarning && currentPoll.temporality == 'live'">
      <vc-warning-popup class="launch-live" title="{{'POLL-WARNING.CATCH' | translate}}" firstText="{{'POLL-WARNING.SUBTITLE' | translate}}"
        [understoodButton]="true" [cancelButton]="true" (endDetailsPopup)="handleLaunchWarning($event)">
      </vc-warning-popup>
    </div>

    <div *ngIf="paymentPopup && !isImportError">
      <vc-warning-popup class="buy-sms" *ngIf="warning2FA && maxSMSVoters === 10 && currentPoll.voters.length > maxSMSVoters; else simple"
        (endDetailsPopup)="handlePaymentWarning($event)" [validateButton]="true" [cancelButton]="true" title="{{'OFFERS.WARNING.CATCH' | translate}}"
        firstText="{{'OFFERS.WARNING.DESCRIPTION' | translate}}" (payment)="displayLaunchWarning()">
      </vc-warning-popup>
      <ng-template #simple>
        <vc-warning-popup class="more-voter-popup" (endDetailsPopup)="handlePaymentWarning($event)" title="{{'OFFERS.WARNING.CATCH' | translate}}"
          firstText="{{'OFFERS.WARNING.DESCRIPTION' | translate}}" [validateButton]="true" [cancelButton]="true"
          (payment)="displayLaunchWarning()">
        </vc-warning-popup>
      </ng-template>
    </div>

    <div *ngIf="plurinominalError">
      <vc-warning-popup class="plurinominal-error" [understoodButton]="true" (endDetailsPopup)="dismissWarning()"
        title="{{'POLL-WARNING.CATCH' | translate}}" firstText="{{'POLL-WARNING.PLURINOMINAL' | translate}}">
      </vc-warning-popup>
    </div>

    <vc-warning-popup class="TEST3" *ngIf="(changeOfferReason == 'addVoters' || changeOfferReason == 'addSMS') &&
    confirmContact && !contactedPopup" [understoodButton]="true"
    (endDetailsPopup)="dismissMoreSMSPopup()" title="{{'DETAILS-CONTACT.CALL' | translate}}">
    </vc-warning-popup>

    <vc-warning-popup *ngIf="isOutdatedImportFileError" class="outdated-file-warning"
      title="{{'POLL-WARNING.OUTDATED-FILE' | translate}}" [separator]="true" [understoodButton]="true" (endDetailsPopup)="dismissError()">
    </vc-warning-popup>
    <div *ngIf="shouldDisplayVoters">
      <vc-warning-popup *ngIf="!isImportError" title="{{getVoterListTitle()}}" (endDetailsPopup)="dismissError()"
        [isImportError]="isImportError" [voters]="displayedVoters" [separator]="false" [understoodButton]="true">
      </vc-warning-popup>

      <vc-warning-popup *ngIf="isImportError" class="import-error" (endDetailsPopup)="dismissError()" [isImportError]="isImportError"
        [voters]="displayedVoters" [understoodButton]="true" [isSMSError]="isSMSError" [is2FA]="currentPoll.is2FA  && isSMSError"
        [isPonderationError]="isPonderationError" title="{{'VOTERS-INVALID-POPUP.TITLE-1' | translate}}">
      </vc-warning-popup>
    </div>

    <vc-warning-popup class="contact-confirmation" *ngIf="contactedPopup"
    (endDetailsPopup)="dismissError()" [understoodButton]="true" title="{{'DETAILS-CONTACT.CALL' | translate}}">
    </vc-warning-popup>

    <div *ngIf="isLoading">
      <vc-loader messageToTheUser="{{'POLL-CREATION.LOADER' | translate}}">
      </vc-loader>
    </div>
    <app-poll-import (sendUpload)="fileUploaded($event)"></app-poll-import>
    <div *ngIf="shouldDisplayNewLicences">
      <vc-confirm-popup (shouldCancelDisplay)="cancelNewLicencesPopup()"></vc-confirm-popup>
    </div>
    <div id="snackbar">
      <div class="snackbar-wrapper">
        <p style="margin:0px">{{'DRAFTS.TOAST' | translate}}</p>
      </div>
    </div>
  </div>
</div>
