<div class="form-holder">
  <vc-poll-card-header [image]="'assets/assesseur-inputs-icon.svg'" [title]="'ASSESSORS.ASSESSOR-TITLE' | translate"
  [description]="'ASSESSORS.ASSESSOR-DESCRIPTION' | translate">
  </vc-poll-card-header>
  <div class="safe-area">
    <p class="description" [innerHTML]="'ASSESSORS.ASSESSORS-INVITES-DESCRIPTION' | translate"></p>
    <div>
      <vc-input-assessor *ngFor="let name of assessorsCpy; let assessorIndex = index; trackBy: trackByFn" [assessorsSize]="assessorsCpy.length"
        [index]="assessorIndex" (deleteEvent)="deleteAssessor($event)" (sendNamesEvent)="addAssessorName($event, assessorIndex)"
        (sendMailsEvent)="addAssessorMail($event, assessorIndex)" [name]="assessorsNames[assessorIndex]" [mail]="assessorsMails[assessorIndex]"
        [displayError]="displayError" (sendSaveEvent)="saveAssessors()">
      </vc-input-assessor>
    </div>
    <div class="button-container" style="margin-right: 65px;margin-left: 27px;display: flex;justify-content: space-between;">
      <div style="width: 45px;"></div>
      <div style="width: 338px;">
        <button *ngIf="assessorsMails.length < 6" id="add-assessor-button" (click)="addAssessor()">
          +&nbsp;&nbsp;&nbsp;&nbsp;{{'ASSESSORS.ADD-ASSESSOR-BUTTON' | translate}}
        </button>
      </div>
      <div style="width: 24px;"></div>
    </div>
  </div>
</div>
