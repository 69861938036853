<div id="page-container">
  <div id="content-wrap" *ngIf="poll != undefined">
    <app-assessor-validation-header [title]="poll.name" [isLive]="poll.type === 'live' || poll.type === 'liveCandidates'" [isPublic]="poll.publicVote"
                                    [SMSOption]="poll.smsOption" [is2FA]="poll.is2FA" [isAssessors]="poll.assessors > 0"></app-assessor-validation-header>
    <vc-poll-ongoing-summary [description]="poll.description" [startDate]="startDate" [endDate]="endDate" [votersCount]="poll.nbVoters" [votersVoted]="poll.votes"
                             [isSms]="poll.smsOption" [isPublic]="poll.publicVote" [is2FA]="poll.is2FA"  [sharedLinks]="poll.sharedLinks" [participation]="participation">
    </vc-poll-ongoing-summary>
    <vc-poll-ongoing-timer [timeDelta]="timeDifference" (shouldDisplayVoters)="displayVoters()">
    </vc-poll-ongoing-timer>

    <div *ngIf="poll.type == 'resolution'">
      <vc-poll-planned-list class="resolutions" [votingType]="poll.type" [answers]="answers" [shouldEdit]="true">
      </vc-poll-planned-list>
    </div>
    <div *ngIf="poll.type == 'election'">
      <!-- Condition should edit a revoir potentiellement -->
      <!-- numberOfVote -->
      <vc-poll-planned-list *ngFor="let candidat of candidates, let index = index" class="election" [votingType]="poll.type" [candidates]="candidat"
                            [answers]="answers[0]" [numberOfVote]="answers[0].numberOfVote" [shouldEdit]="answers[0].displayedQuestions.length <= 1"
                            [index]="index">
      </vc-poll-planned-list>
    </div>
    <div *ngIf="poll.type == 'list'">
      <vc-poll-planned-list *ngFor="let list of lists" class="election" [votingType]="poll.type" [titles]="titles"
                            [answers]="list" [numberOfVote]="list.numberOfVote" [shouldEdit]="false">
      </vc-poll-planned-list>
    </div>
  </div>
</div>
<div *ngIf="isError">
  <app-error
    (isClosed)="navigateHome()"
    [errorMessage]="errorMessage">
  </app-error>
</div>
<div *ngIf="shouldDisplayVoters">
  <vc-warning-popup title="{{'REMAINING-VOTERS' | translate}}" [mails]="remainingVoters" [understoodEndButton]="true"
                    (endDetailsPopup)="dismissVoters()" [separator]="false" [downloadButton]="true" (sendDownloadOrder)="downloadVoterFile()">
  </vc-warning-popup>
