import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {TimeDelta} from '../models/TimeDelta';
import {LicenseData} from '../models/LicenseData';


@Injectable({
    providedIn: 'root',
})

export class Common {

    constructor(private router: Router) {}

    autoHeight(elem, gap: number) {
        elem.target.style.height = '14px';
        elem.target.style.height = elem.target.scrollHeight - gap + 'px';
    }

    validateEmail(email: string) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    checkIfSafari() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return false;
      } else {
        return navigator.vendor &&
          navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;

      }
    }

    isPhoneValid(phone: string) {
      let re =
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
      phone = (phone + '').trim();
      if (phone.substring(0, 2) === '33') {
        re = /33(\d{9})$/;
        return re.test(String(phone).toLowerCase());
      }
      if (
        ['590', '594', '596', '262', '242', '269'].includes(
          phone.substring(0, 3)
        ) == true
      ) {
        re = /\d{3}(\d{9})$/;
        return re.test(String(phone).toLowerCase());
      }
      return re.test(String(phone).toLowerCase());
    }

    navigateToPolls() {
      this.router.navigate(['/polls'])
    }

    getLangCode(): string {
      const userLang = navigator.language || navigator['userLanguage'];
      return userLang.substring(0, 2);
    }

    localizeAnswer(answer: string): string {
      const userLang = navigator.language || navigator['userLanguage'];
      if (answer === 'Vote blanc') {
        switch (userLang.substring(0, 2)) {
            case 'es': return 'Voto en blanco';
            case 'en': return 'Blank vote';
        }
      }
      return answer;
    }

    isNotNullOrUndefined(value) { return value !== null && value !== undefined; }

    isNullOrUndefined(value) { return !this.isNotNullOrUndefined(value); }

    isNotEmptyString(value: string) { return value != null && value != ''; }

    getTimeDelta(timeDiff: number): TimeDelta {
      if (timeDiff < 0) { timeDiff = 0; }
      const days  = Math.floor(timeDiff / 24 / 60 / 60 / 1000);
      const hours = Math.floor((timeDiff - (days * 24 * 60 * 1000 * 60)) / 60 / 60 / 1000 );
      const minutes = Math.floor((timeDiff - (hours * 60 * 60 * 1000) - (days * 24 * 60 * 1000 * 60)) / 60 / 1000);
      const seconds = Math.floor((timeDiff - (hours * 60 * 60000) - (days * 24 * 60000 * 60) - (minutes * 60 * 1000)) / 1000);
      return new TimeDelta(days, hours, minutes, seconds);
    }

    licenseResponseGetTime(res: LicenseData): string {
      switch (res.duration) {
        case 'Y': return  'Y';
        case 'DM': return 'DM';
        default: return 'D';
      }
    }

  getVotersExcelName(ext: string, language?: string): string {
    switch (language) {
      case 'fr': return  `Votants.${ext}`;
      case 'es': return `Votantes.${ext}`;
      default: return  `Voters.${ext}`;
    }
  }
}
