<div [ngClass]="index != 0 ? 'line-holder line-holder-special' : 'line-holder'">
  <div (click)="fileInput.click()">
    <input style="display: none" type="file" accept=".jpeg, .jpeg, .gif, .png" (change)="onFileChanged($event.target.files); fileInput.value=''" #fileInput>
    <h3 *ngIf="index >= 0 else emptyindex" class="resolution-index" id="candidate-index-{{parentIdentifier}}-{{index}}" >{{index+1}}
    </h3>
    <ng-template #emptyindex>
      <h3 class="resolution-index"></h3>
    </ng-template>
    <div id="uploaded-wrapper-{{parentIdentifier}}-{{index}}" class="candadate-images-wrapper">
      <img id="uploaded-{{parentIdentifier}}-{{index}}" class="candadate-images" src="#" alt="">
    </div>
  </div>
  <textarea type="text" id="{{ parentIdentifier + '-candidate-' + index }}" class="names" (keyup)="onNameInput($event)"
  (keyup)="common.autoHeight($event, 10)" value="{{choice.name}}" maxlength="255" style="height: 26px;"
  placeholder="{{inputPlaceholder}}" (focusout)="markDeselect()"></textarea>
  <div class="delete-container">
    <div class="icon-container">
      <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="closeInput()" id="input-{{parentIdentifier}}-{{index}}"/>
      <img *ngIf="!blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/grey-link.svg">
      <img *ngIf="blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/blue-link.svg">
    </div>
  </div>
  <div *ngIf="index > 0" class="delete-container">
    <div class="icon-container">
      <img class="icons" (click)="delete()" src="assets/deletebutton.svg">
    </div>
  </div>

  <div *ngIf="index < 1 || index == undefined" class="delete-placeholder"></div>
</div>
<div [ngClass]="resolutionCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{resolutionCharacters}}/255</div>

<!--
-- POPUP FOR BOTH MODE
-->
<vc-warning-popup class="image-error" *ngIf="displayImageError"
  [understoodButton]="true" (endDetailsPopup)="dismissPayPopup()"
  title="{{'UPLOAD-IMAGES.POPUP-TEXT' | translate}}">
</vc-warning-popup>
