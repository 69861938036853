import { Component, Output, EventEmitter } from '@angular/core';
import { ContactFormData } from '../../models/ContactData';
import { AuthenticationService } from '../../shared/authentication.service';
import { Common } from 'src/app/shared/common';

@Component({
    selector: 'vc-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.less']
})

export class ContactForm {
    emailInput: string = "";
    phoneInput: string = "";
    isEmail: boolean = false;
    isNumber: boolean = true;
    errorMessage: string = "";
    canSendMail = true;
    @Output() shouldDisplayPopup = new EventEmitter<boolean>();

    constructor(
        private authenticationService: AuthenticationService,
        public common: Common
        ) { }

    isValid(validation: boolean, field: string) {
        if (!validation) {
            document.getElementById(field).className = "w-input error-input"
        } else {
            document.getElementById(field).className = "w-input"
        }
    }

    onContact() {
        let campagne = (<HTMLTextAreaElement>document.getElementById('campaign')).value
        let firstName = (<HTMLInputElement>document.getElementById('Pr-nom')).value
        let lastName = (<HTMLInputElement>document.getElementById('Nom-de-famille')).value
        let phone = (<HTMLInputElement>document.getElementById('Phone')).value
        let mail = (<HTMLInputElement>document.getElementById('Email')).value
        let message = (<HTMLTextAreaElement>document.getElementById('Message')).value
        let type = (<HTMLInputElement>document.querySelector('input[name="type"]:checked'))
        if (firstName.length > 1 && lastName.length > 1 && this.isEmail && message.length > 1 &&
            type != null && parseInt(campagne) > 0 && this.isNumber && this.phoneInput.length > 0) {
          if (this.canSendMail) {
              this.sendContact(firstName, lastName, phone, mail, message, type.value, campagne);
          }
        } else {
            this.errorMessage = ""
            // Get the snackbar DIV
            var x = document.getElementById("snackbar");

            if (firstName.length < 1 || lastName.length < 1 || message.length < 1 ||
                type == null || campagne.length == 0 || parseInt(campagne) < 1) {
                this.errorMessage += `Vérifiez que tous les champs soient remplis.`
            }
            else if (!this.isEmail) {
                this.errorMessage += "L'adresse e-mail est incorrecte."
            }
            else if (!this.isNumber) {
                this.errorMessage += "Le numéro de téléphone est incorrect."
            } else if (this.phoneInput.length < 1) {
                this.errorMessage += "Le numéro de téléphone est vide."
            }

            // Add the "show" class to DIV
            x.className = "show";
            // After 3 seconds, remove the show class from DIV
            setTimeout(function(){ x.className = x.className.replace("show", "");}, 3000);
        }
      }

      sendContact(firstName: string, lastName: string, phone: string, mail: string, message: string, type: string, campagne: string) {
        this.canSendMail = false;
        let mailString = "Form<br>Contact Form<br><br>Site<br>V8TE<br><br>Submitted content<br>Prenom: " +
            firstName + "<br>Nom de famille: " + lastName + "<br>E-mail: " + mail +
            "<br>Numéro de téléphone: " + phone + "<br>Type d'organisme: " + type +
            "<br>Nombre de campagnes: " + campagne + "<br>Message: " + message;
        let mailData = new ContactFormData(firstName, lastName, mail, phone, campagne, type, message);
        this.authenticationService.sendContactForm(mailData).subscribe((res) => {
          this.shouldDisplayPopup.emit(true);
          (<HTMLInputElement>document.getElementById('Pr-nom')).value = "";
          (<HTMLInputElement>document.getElementById('Nom-de-famille')).value = "";
          (<HTMLInputElement>document.getElementById('Phone')).value = "";
          (<HTMLInputElement>document.getElementById('Email')).value = "";
          (<HTMLInputElement>document.querySelector('input[name="type"]:checked')).checked = false;
          (<HTMLTextAreaElement>document.getElementById('Message')).value = "";
          (<HTMLTextAreaElement>document.getElementById('campaign')).value = "";
          this.canSendMail = true;
        });
      }

    ngOnInit() {
    }
}
