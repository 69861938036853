import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WsCommand, LiveQuestion } from '../../models/PollChoice';
import { PollQuestion, AddedPollQuestion } from './../../models/PollCreationData';

@Component({
  selector: 'app-live-candidates-list',
  templateUrl: 'live-candidates-list.component.html',
  styleUrls: ['./live-candidates-list.component.less']
})

export class LiveCandidatesListComponent {
  @Input() votingType: string;
  @Input() isPublic: boolean;
  @Input() isLiveOpen: boolean;
  @Input() liveChoiceEvents: Array<LiveQuestion>;
  @Input() choices: Array<PollQuestion>;
  @Input() activeIndex: number;
  @Output() sendLiveResolutions = new EventEmitter<Array<LiveQuestion>>();
  @Output() sendCommand = new EventEmitter<WsCommand>();
  @Output() sendResults = new EventEmitter<number>();
  @Output() submitCandidate = new EventEmitter<AddedPollQuestion>();
  @Output() deleteQuestionEvent = new EventEmitter<number>();
  @Input() canAddQuestions: boolean;
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  @Output() linkEvent = new EventEmitter<[number, string]>()
  addRound = false;
  constructor() {
  }

  getResults(data: number) {
    this.sendResults.emit(data);
  }

  wsCommand(data: WsCommand) {
    this.sendCommand.emit(data);
  }

  sendImageEvent($event) {
    this.sendAnswerImage.emit($event)
  }

  sendLink($event) {
    this.linkEvent.emit($event)
  }

  addEvent() {
    this.addRound = true;
  }

  deleteQuestion($event) {
    this.deleteQuestionEvent.emit($event);
  }

  addResolution(data: PollQuestion) {
    this.submitCandidate.emit(new AddedPollQuestion(data, 1));
    this.addRound = false;
  }

  addChoicedEvent(data: AddedPollQuestion) {
    this.submitCandidate.emit(data);
    this.addRound = false;
  }
}
