import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OfferCommand, OfferInfo } from '../../models/Enums';

@Component({
  selector: 'vc-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.less', '../../shared/common.less'],
})
export class OfferCard implements OnInit {
  @Input() source: string;
  @Input() price: string;
  @Input() special: string;
  @Input() index: number;
  @Input() isAssoc: boolean;
  @Input() deactivated = false;
  @Output() sendSelection = new EventEmitter<OfferCommand>();
  taxes = '';

  constructor() {}

  getOptions() {
    this.sendSelection.emit(new OfferCommand(OfferInfo.MoreInfo, this.index));
  }

  subscribe() {
    this.sendSelection.emit(new OfferCommand(OfferInfo.Buy, this.index));
  }

  signup() {
    this.sendSelection.emit(new OfferCommand(OfferInfo.SignUp, this.index));
  }

  freeTrial() {
    this.sendSelection.emit(new OfferCommand(OfferInfo.FreeTest, this.index));
  }

  ngOnInit() {
    if (this.price !== '0€') {
      this.isAssoc === false ? this.taxes = 'HT' : this.taxes = 'TTC';
    }
  }
}
