import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {PaymentData, ShoppingCart, AssoConnectData, ChangeVoterForm, ValidateVoterChangeForm} from '../models/PaymentData';
import { DraftForm } from '../models/Poll';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {Voter} from '../models/Voter';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiURL: string;
  resultsURL: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    }),
  };
  multipartHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }),
  };
  httpOptionstmp: any;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public resendMail(email: string) {
    const mailRequest = {email: email};
    return this.httpClient.post<any>(
      `${environment.settings.api}/auth/resendMail`,
      mailRequest
    );
  }

  public createPoll(pollCreationData: string) {
    return this.httpClient.post(
      `${environment.settings.api}/elections`,
      pollCreationData,
      this.httpOptions
    );
  }

  public deletePendingRound(id: string, round: string) {
    return this.httpClient.patch(
      `${environment.settings.api}/elections/${id}/${round}`,
      this.httpOptions
    );
  }

  public deletePoll(id: string) {
    return this.httpClient.patch(
      `${environment.settings.api}/elections/${id}/delete`,
      id,
      this.httpOptions
    );
  }

  public saveElectionImage(pollBlob: FormData) {
    return this.httpClient.post<any>(
      `${environment.settings.api}/elections/pollImage`,
      pollBlob
    );
  }

  public updatePoll(id: string, pollUpdateData: string) {
    return this.httpClient.patch(
      `${environment.settings.api}/elections/${id}`,
      pollUpdateData,
      this.httpOptions
    );
  }

  public login(email: string, password: string) {
    const fd = new FormData();
    fd.append('email', email);
    fd.append('password', password);
    return this.httpClient.post<any>(
      `${environment.settings.api}/auth/login`,
      fd
    );
  }

  public forgotPassword(email: string) {
    const fd = new FormData();
    fd.append('email', email);

    return this.httpClient.get<any>(
      `${environment.settings.api}/auth/resendPassword/${email}`,
      this.httpOptions
    );
  }

  public getPoll(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}`,
      this.httpOptions
    );
  }

  public getPollForAssessor(id: string, code: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/${code}/assessorVerify`,
      this.httpOptions
    );
  }

  public getVoterFileAssessor(id: string, code: string, language: string): Observable<any> {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/${code}/assessorDownload?language=${language}`,
      { responseType: 'blob' }
    );
  }

  public getCloningPoll(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/clone`,
      this.httpOptions
    );
  }

  public getDraft(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/drafts/${id}`,
      this.httpOptions
    );
  }

  public getDraftId() {
    return this.httpClient.get(
      `${environment.settings.api}/drafts/start`,
      this.httpOptions
    );
  }

  public saveDraft(form: DraftForm) {
    return this.httpClient.post<DraftForm>(
      `${environment.settings.api}/drafts/${form.id}`,
      form
    );
  }

  public updateDraft(form: DraftForm) {
    return this.httpClient.patch<DraftForm>(
      `${environment.settings.api}/drafts/${form.id}`,
      form
    );
  }

  public deleteDraft(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/drafts/${id}/delete`,
      this.httpOptions
    );
  }

  public getPollPreview(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/preview`,
      this.httpOptions
    );
  }

  public getPollData(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/pollPages`,
      this.httpOptions
    );
  }

  public sendReferral(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/pay/referral/${id}`,
      this.httpOptionstmp
    );
  }

  public getDynamicPolls(name: string) {

    return this.httpClient.get(
      `${environment.settings.api}/elections/${name}/search`,
      this.httpOptions
    );
  }

  public getPolls(lang: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.settings.api}/elections?lang=${lang}`,
      this.httpOptions
    );
  }

  public getParticipation(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/participation`,
      this.httpOptions
    );
  }

  public closeElection(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/close`,
      this.httpOptions
    );
  }

  public getParticipationRound(id: string, round: number) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/participation?round=${round}`,
      this.httpOptions
    );
  }

  public getResults(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/result`
    );
  }

  public getRRemaining(id: string): Observable<any> {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/remaining`
    );
  }

  public getVotersFile(id: string, language: string): Observable<any> {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/invited?language=${language}`,
      { responseType: 'blob' }
    );
  }

  public getRemainingAssessorValidation(id: string, code: string): Observable<any> {
    return this.httpClient.get(
      `${environment.settings.api}/assessors/verify/remaining/${id}/${code}`
    );
  }

  public getResultsCsv(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/result.csv`,
      { responseType: "text" }
    );
  }

  public getResultsXlsx(id: string, language: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/result.xlsx?language=${language}`,
      { responseType: 'blob' }
    );
  }

  public getResultsPdf(id: string, language: string) {
    return this.httpClient.get(`${environment.settings.api}/elections/${id}/result.pdf?language=${language}`, {responseType: 'blob'});
  }

  public getResultsXlsxRound(id: string, round: number, language: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/result.xlsx?round=${round}&language=${language}`,
      { responseType: 'blob' }
    );
  }

  public getResultsPdfRound(id: string, round: number, language: string) {
    return this.httpClient.get(`${environment.settings.api}/elections/${id}/result.pdf?round=${round}&language=${language}`, { responseType: 'blob' });
  }

  public getResultsCsvRound(id: string, round: number) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/result.csv?round=${round}`,
      { responseType: "text" }
    );
  }

  public getVotersCsv(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/voters.csv`,
      { responseType: "text" }
    );
  }

  public getVotersXlsx(id: string, language: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/voters.xlsx?language=${language}`,
      { responseType: 'blob' }
    );
  }

  public getVotersXlsxRound(id: string, round: number, language: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/voters.xlsx?round=${round}&language=${language}`,
      { responseType: 'blob' }
    );
  }

  public getVotersPdf(id: string) {
    return this.httpClient.get(`${environment.settings.api}/elections/${id}/voters.pdf`, {responseType: 'blob'});
  }

  public getVotersPdfRound(id: string, round: number) {
    return this.httpClient.get(`${environment.settings.api}/elections/${id}/voters.pdf?round=${round}`, { responseType: 'blob' });
  }

  public getVotersCsvRound(id: string, round: number) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/voters.csv?round=${round}`,
      { responseType: "text" }
    );
  }

  public createSubscription(paymentData: PaymentData) {
    return this.httpClient.post(
      `${environment.settings.api}/pay/subscribe `,
      paymentData,
      this.httpOptions
    );
  }

  public createCheckoutSession(cart: ShoppingCart) {
    return this.httpClient.post(
      `${environment.settings.api}/pay/checkout-session`,
      cart,
      this.httpOptions
    );
  }

  public changeVoter(form: ChangeVoterForm, pollId: string) {
    return this.httpClient.post(
      `${environment.settings.api}/elections/${pollId}/changeVoter`,
      form,
      this.httpOptions
    );
  }

  public changeAssessor(form: ChangeVoterForm, pollId: string) {
    return this.httpClient.post(
      `${environment.settings.api}/assessors/${pollId}/changeAssessor`,
      form,
      this.httpOptions
    );
  }

  public validateVoterChange(form: ValidateVoterChangeForm, pollId: string) {
    return this.httpClient.post(
      `${environment.settings.api}/elections/${pollId}/verifyVoterChange`,
      form,
      this.httpOptions
    );
  }

  public validateAssessorChange(form: ValidateVoterChangeForm, pollId: string) {
    return this.httpClient.post(
      `${environment.settings.api}/assessors/${pollId}/verifyAssessorChange`,
      form,
      this.httpOptions
    );
  }

  public createCheckoutSessionDashboard(cart: ShoppingCart) {
    return this.httpClient.post(
      `${environment.settings.api}/pay/checkout-session-dashboard`,
      cart,
      this.httpOptions
    );
  }

  public getLicenses() {
    return this.httpClient.get(
      `${environment.settings.api}/auth/licences`,
      this.httpOptions
    );
  }

  public deleteUser() {
    return this.httpClient.get(
      `${environment.settings.api}/auth/delete`,
      this.httpOptions
    );
  }

  public getInvoices() {
    return this.httpClient.get(
      `${environment.settings.api}/auth/invoices`,
      this.httpOptions
    );
  }

  public getSubscriptions() {
    return this.httpClient.get(
      `${environment.settings.api}/pay/subscriptions`,
      this.httpOptions
    );
  }

  public getLicensesDate(date: string) {
    return this.httpClient.get(
      `${environment.settings.api}/auth/licences/${date}`,
      this.httpOptions
    );
  }

  public getSubscriptionData(subscription: string) {
    return this.httpClient.get(
      `${environment.settings.api}/auth/subscription/${subscription}`,
      this.httpOptions
    );
  }

  public updateNewsletter(mail: String): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.settings.api}/setNewsletter/${mail}/`,
      this.httpOptions
    );
  }

  public resendInvitations(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/inviterelaunch`
    );
  }

  public canResend(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/canresend`
    );
  }

  public canUseConvocation(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/convocationstatus`
    );
  }

  public canUseAssessorSend(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/assessorstatus`
    );
  }

  public canUseAssessorOpeningSend(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/openingAssessorstatus`
    );
  }

  public sendConvocations(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/sendconvocations`
    );
  }

  public getNameFromEncoded(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/validate/key/${id}`
    );
  }

  public setPublish(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/publishresults`
    );
  }

  public validatePhones(voters: Array<Voter>) {
    return this.httpClient.post(
      `${environment.settings.api}/elections/validatePhones`, voters
    );
  }

  public validateAssoconnect(token: string, id: string, ulid: string) {
    const data = new AssoConnectData(id, ulid, token);
    return this.httpClient.post(
      `${environment.settings.api}/validate/assoconnect`, data
    );
  }

  public getInactiveAssessors(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/assessors/list/${id}`
    );
  }

  public getInactiveAssessorValidation(id: string, code: string) {
    return this.httpClient.get(
      `${environment.settings.api}/assessors/verify/list/${id}/${code}`
    );
  }

  public getNonClosingAssessors(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/assessors/non-completed/${id}`
    );
  }

  public relunchNonClosingAssessors(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/assessors/relaunchCloseInvites/${id}`
    );
  }
  public getExportTokens(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/elections/${id}/tokens`
    );
  }

  public relunchAssessors(id: string) {
    return this.httpClient.get(
      `${environment.settings.api}/assessors/relaunch/${id}`
    );
  }
}
