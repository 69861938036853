import { Injectable } from "@angular/core";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

@Injectable()
export class WebsocketService {
  constructor() {}

  private subject: WebSocketSubject<string>

  public connect(url): WebSocketSubject<string> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected: " + url);
    }
    return this.subject;
  }

  private create(url): WebSocketSubject<string>  {
    this.subject = webSocket({
      url: url,
      deserializer: e => e.data,
      serializer: (value: any) => value
    });
    this.subject.asObservable().subscribe(
       msg => {        
         console.log(msg)
       },
       // Called whenever there is a message from the server
       err => {
         console.log(err)
         if (err.code === 1006) {
           this.subject = this.create(url);
         }
       },
       () => console.log('complete')
       // Called when connection is closed (for whatever reason)
     );
     return this.subject
   }
 }
