<div class="form-holder">
  <div class="safe-area">
    <h1 *ngIf="description != ' '" class="objective" [innerHTML]="replaceURLs(description)"></h1>
    <!-- <a href="{{blockchainLink}}" target="_blank" rel="noopener noreferrer" *ngIf="type == 'list'" class="blockchain-link"> -->
      <!-- <img src="assets/global.svg" class="global-icon" /> {{'POLL-PLANNED.POLL-BLOCKCHAIN' | translate}} -->
    <!-- </a> -->
    <div class="status-holder">
      <span class="status">{{'POLL-FINISHED.TITLE' | translate}}</span>
      <button class="blue-button" style="margin-top: 3px;" *ngIf="(type == 'liveCandidates' || type == 'election') && isActive" (click)=rejoin()>{{'POLL-ONGOING.REJOIN-ELECTION' | translate}}</button>
      <button class="blue-button" style="margin-top: 3px;" *ngIf="(type == 'live' || type == 'resolution') && isActive" (click)=rejoin()>{{'POLL-ONGOING.REJOIN-RESOLUTION' | translate}}</button>
      <div style="display: flex; align-items: center;">
        <div *ngIf="!calculating" class="download-button" (click)="download()">
          <img src="assets/certificate-dl.svg">
          <span class="download-text">{{'POLL-FINISHED.DOWNLOAD' | translate}}</span>
        </div>
        <div *ngIf="(!resultsShared && isShareEnabled) && !calculating" class="download-button" (click)="shareResult()"  style="margin-left: 20px;">
          <img src="assets/share_24px_blue.svg">
          <span class="download-text">{{'POLL-FINISHED.SHARE' | translate}}</span>
        </div>
        <div *ngIf="resultsShared && isShareEnabled" class="download-button" style="margin-left: 20px;">
          <img src="assets/share_24px_rounded.svg">
          <span class="download-text-inactive">{{'POLL-FINISHED.SHARE' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="infos-holder">
      <div class="small-holder">
        <img class="description-icon" src="assets/calendar-small.svg">
        <div>
          <h3 class="descriptor">{{'POLL-FINISHED.START' | translate}}</h3>
          <h3 class="info">{{startDate}}</h3>
        </div>
      </div>
      <div>
        <h3 class="descriptor">{{'POLL-FINISHED.END' | translate}}</h3>
        <h3 class="info">{{endDate}}</h3>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/election-simple.svg">
        <div>
          <h3 class="descriptor">{{'POLL-FINISHED.ELECTORS' | translate}}</h3>
          <h3 class="info-bold">{{votersCount}}</h3>
        </div>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/tot-voters.svg">
        <div>
          <h3 class="descriptor">{{'POLL-FINISHED.VOTERS' | translate}}</h3>
          <h3 class="info-bold">{{votersVoted}}</h3>
        </div>
      </div>
      <div class="small-holder">
        <img class="description-icon" src="assets/participation.svg">
        <div>
          <h3 class="descriptor">{{'POLL-FINISHED.PARTICIPATION' | translate}}</h3>
          <h3 class="info-bold">{{participation}}%</h3>
        </div>
      </div>
    </div>
  </div>
</div>
