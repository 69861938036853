import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Resolution, EditedResolution} from '../../../models/PollChoice';
import { Common } from '../../../shared/common';

@Component({
  selector: 'vc-input-resolution',
  templateUrl: 'input-resolution.component.html',
  styleUrls: ['./input-resolution.component.less']
})

export class InputResolutionComponent implements OnInit {
  @Input() index: number;
  @Input() resolution: Resolution;
  @Output() sendResolution = new EventEmitter<EditedResolution>();
  @Output() deleteEvent = new EventEmitter<number>();
  editedObject: EditedResolution;
  isValid = false;

  constructor(public common: Common) {}

  onNameInput(event: { target: { value: string; }; }) {
    this.editedObject.resolution.name = event.target.value;
    this.isValid = this.editedObject.resolution.name != null && this.editedObject.resolution.name != '';
    this.sendResolution.emit(this.editedObject);
  }
  delete() {
    this.deleteEvent.emit(this.index);
  }

  ngOnInit() {
    this.editedObject = new EditedResolution(this.index, this.resolution);
    if (this.resolution.name != undefined) {
        if ((document.getElementById('name-' + this.index) as HTMLTextAreaElement) != null) {
          (document.getElementById('name-' + this.index) as HTMLTextAreaElement).value = this.resolution.name;
        }
    }
  }
}
