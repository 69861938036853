import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { PollItemData } from '../models/PollItemData';
import { PollDashboardResponse } from '../models/PollDashBoardResponse';
import { AuthenticationService } from '../shared/authentication.service';
import { LicenseService } from '../shared/licens.service';
import { SubsciptionData, LicenseData } from '../models/LicenseData';
import {Common} from '../shared/common';

@Component({
  selector: 'app-poll-dashboard',
  templateUrl: 'poll-dashboard.component.html',
  styleUrls: ['./poll-dashboard.component.less', '../shared/common.less'],
})

export class PollDashboardComponent implements OnInit {
  // Toutes les élections
  plannedPolls: Array<PollItemData> = [];
  runningPolls: Array<PollItemData> = [];
  closedPolls: Array<PollItemData> = [];
  draftPolls: Array<PollItemData> = [];

  // Permet de developper les colonnes d'élections crées
  isExpanded = [false, false, false, false];

  // Data about the user on ngOnInit
  accountName: string;
  maxVoters = 10;
  sms = '';
  licenseTime: string;
  newLicences: Array<SubsciptionData> = [];
  clientType = 'assoc';
  language = 'fr';
  // variables for popup
  shouldBuyCredits = false;
  shouldDisplayPopup = false;
  hasSmsVoters = false;
  shouldDisplayNewLicences = false;
  shoulDisplayChangePopup = false;
  smsAdditionalOption = false;
  moreOption = false;
  selectedOffer: number;
  isAssoc = true;
  getContacted = false;
  confirmContact = false;
  licencesReady = false;
  editionDonePopup: boolean;
  remainingLicense: number;
  hotlinePopup = false;
  hasHotline = false;
  displayHotline = false;
  hotlineConfirmation = false;

  constructor(
    public auth: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    public licenseService: LicenseService,
    private common: Common
  ) {}

  isClientAssoc() {
    if (this.clientType !== null) { this.isAssoc = this.clientType !== 'corp'; }
    else { this.isAssoc = true; }
  }

  displaySMSOption($event) {
    this.isClientAssoc();
    this.selectedOffer = $event;
    this.smsAdditionalOption = true;
  }

  newPoll() {
    this.router.navigate(['/poll-creation']);
  }

  buyCredits() {
    this.shouldBuyCredits = true;
  }

  seeVideos() {
    window.open('https://www.v8te.com/fr/tutoriels', '_blank');
  }

  downloadDocuments() {
    window.open('https://drive.google.com/drive/folders/1sP4ShTvOKcAU0fkFeNoibJCLao-pZfQC?usp=sharing', '_blank');
  }

  downloadGuide() {
    switch (this.language) {
      case 'fr': { window.open('https://medium.com/fr-guides-v8te', '_blank'); return; }
      case 'es': { window.open('https://medium.com/es-gu%C3%ADas-v8te', '_blank'); return; }
      default:  { window.open('https://medium.com/en-v8te-guides', '_blank'); return; }
    }

  }

  contactMail() {
    const mailText = 'mailto:support_client@v8te.com';
    window.open(mailText);
  }

  dismissInitialPopup() {
    this.hotlinePopup = false;
    this.confirmContact = false;
    this.getContacted = false;
    this.moreOption = false;
    this.smsAdditionalOption = false;
    this.shouldDisplayPopup = false;
    this.shoulDisplayChangePopup = false;
    this.editionDonePopup = false;
  }

  dismissPayPopup($event: boolean) {
    if ($event === false) { this.isClientAssoc(); }
    this.shouldBuyCredits = false;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/connexion']);
  }

  cancelNewLicencesPopup() {
    this.hotlineConfirmation = false;
    this.shouldDisplayNewLicences = false;
  }

  changeSubscription() {
    this.shoulDisplayChangePopup = true;
    this.smsAdditionalOption = true;
  }

  dismissChangePopup() {
    window.location.reload();
  }

  getContactPhone($event: string) {
    const mailData = this.licenseService.getContactMailObject(this.selectedOffer, this.accountName, this.isAssoc, $event);
    this.auth.sendContactMail(mailData).subscribe((res) => {
      this.confirmContact = true;
      this.getContacted = false;
    });
  }

  openOffers($event) {
    if ($event) { this.getContacted = true; }
    else {
      this.shouldBuyCredits = true;
    }
    this.moreOption = false;
  }

  displayMoreInfo($event) {
    this.moreOption = true;
    this.selectedOffer = $event[0];
    this.isAssoc = $event[1] === 1;
  }

  setSMSOption($event: number) {
    this.licenseService.setSMSOption($event, this.selectedOffer, this.isAssoc, this.dismissChangePopup);
  }

  triggerHotlinePopup() {
    this.hotlinePopup = true;
  }

  setupColumnHeight() {
    const maxElements = Math.max(this.plannedPolls.length, this.draftPolls.length, this.runningPolls.length, this.closedPolls.length);
    if (maxElements > 3) {
      const columns = document.getElementsByClassName('holder-column') as HTMLCollectionOf<HTMLDivElement>;
      setTimeout(() => {
        const maxHeight  = Math.max(columns[0].offsetHeight, columns[1].offsetHeight, columns[2].offsetHeight, columns[3].offsetHeight);
        const colArray = Array.from(columns);
        // line height 14 margin 1.67em => 23.38
        colArray.forEach((column, index) => {
          switch (index) {
            case 0: {
              if (this.draftPolls.length > 5 && maxHeight > column.offsetHeight) {
                const delta = maxHeight - column.offsetHeight + 23.38;
                document.getElementById(`expand-${index}`).style.marginTop = delta + 'px';
              }
              break;
            }
            case 1: {
              if (this.plannedPolls.length > 5 && maxHeight > column.offsetHeight) {
                const delta = maxHeight - column.offsetHeight + 23.38;
                document.getElementById(`expand-${index}`).style.marginTop = delta + 'px';
              }
              break;
            }
            case 2: {
              if (this.runningPolls.length > 5 && maxHeight > column.offsetHeight) {
                const delta = maxHeight - column.offsetHeight + 23.38;
                document.getElementById(`expand-${index}`).style.marginTop = delta + 'px';
              }
              break;
            }
            case 3: {
              if (this.closedPolls.length > 5 && maxHeight > column.offsetHeight) {
                const delta = maxHeight - column.offsetHeight + 23.38;
                document.getElementById(`expand-${index}`).style.marginTop = delta + 'px';
              }
              break;
            }
          }
          column.style.minHeight = maxHeight + 'px';
        });
      }, 200);
    }
  }

  initData() {
    this.licenseService.license.subscribe((res: LicenseData) => {
      if (res != null) {
        this.remainingLicense = res.events;
        this.licencesReady = true;
        this.maxVoters = res.votes;
        this.hasHotline = res.hasHotline;
        if (res.hasSMS === true) { this.sms = '- ' + res.sms + ' SMS'; }
        else { this.sms = ''; }
        this.clientType = res.client;
        if (res.client !== null) { this.isAssoc = res.client !== 'corp'; }
        this.licenseTime = this.common.licenseResponseGetTime(res);
        this.hasSmsVoters = res.sms > 10;
      }
      this.auth.currentUser.subscribe((usr) => {
        this.accountName = this.auth.getUserData();
      });
    });
    this.licenseService.refreshLicense();
    this.apiService.getPolls(this.common.getLangCode()).subscribe((res: PollDashboardResponse) => {
      this.route.queryParams.subscribe((params) => {
        if (params.session_id === undefined) {
          this.shouldDisplayPopup = res.finished.length === 0 && res.ongoing.length === 0 && res.planned.length === 0;
        }
      });
      this.runningPolls = res.ongoing;
      this.closedPolls = res.finished;
      this.plannedPolls = res.planned;
      this.draftPolls = res.drafts;
      this.setupColumnHeight();
    });
    this.route.queryParams.subscribe((params) => {
      if (params.session_id != null) {
        this.apiService
          .getSubscriptionData(params.session_id)
          .subscribe((res: Array<SubsciptionData>) => {
            if (res.length > 0 && res[0].type === 'H') { this.hotlineConfirmation = true; }
            else { this.shouldDisplayNewLicences = true; }
            this.newLicences = res;
          });
      }
    });
  }

  ngOnInit() {
    if (this.router.url.includes('edited')) {
      this.editionDonePopup = true;
    }
    this.licencesReady = false;
    this.isAssoc = true;
    const languageKey = 'userLanguage';
    const userLang = navigator.language || navigator[languageKey];
    this.language = userLang.substring(0, 2);
    const referral = localStorage.getItem('referral');
    if (referral != null) {
      this.apiService.sendReferral(referral).subscribe((res) => {
        localStorage.removeItem('referral');
        this.initData();
      },
        (err) => {
          //  TO DO: display error
          localStorage.removeItem('referral');
          this.initData();
        }
      );
    } else {
      this.initData();
    }
  }
}
