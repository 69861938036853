<div class="line-holder"
[ngClass]="index != 0 ? 'line-holder line-holder-two-icons' : 'line-holder'">
  <h3 class="resolution-index" (click)="fileInput.click()" id="candidate-index-{{index}}">
    {{index+1}}
    <input style="display: none" type="file" accept=".jpeg, .jpeg, .gif, .png" 
    (change)="onFileChanged($event.target.files); fileInput.value=''" #fileInput>
  </h3>
  <div id="uploaded-wrapper-{{index}}" class="candadate-images-wrapper" (click)="fileInput.click()">
    <img id="uploaded-{{index}}" class="candadate-images" src="#" alt="">
  </div>
  <textarea type="text" id="name" (keyup)="onNameInput($event)" maxlength="255" 
    placeholder="{{'POLL-CREATION.QUESTIONS.CANDIDATE-INPUT-PLACEHOLDER' | translate}}"></textarea>
  <div class="delete-container">
    <div class="icon-container">
      <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="closeInput()" id="input-{{index}}"/>
      <img *ngIf="!blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/grey-link.svg">
      <img *ngIf="blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/blue-link.svg">
    </div>
  </div>
  <div *ngIf="index != 0" class="delete-container">
    <div class="icon-container">
      <img id="delete-button" (click)="delete()" src="assets/deletebutton.svg">
    </div>
  </div>
</div>
<div [ngClass]="eventTitleCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{eventTitleCharacters}}/255</div>

<!--
-- POPUP FOR BOTH MODE
-->
<vc-warning-popup class="image-error" *ngIf="displayImageError"
  [understoodButton]="true" (endDetailsPopup)="dismissPayPopup()"
  title="{{'UPLOAD-IMAGES.POPUP-TEXT' | translate}}">
</vc-warning-popup>