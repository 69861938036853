import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReceivedPoll } from '../models/ReceivedPoll';
import { ApiService } from '../shared/api.service';
import { Common } from '../shared/common';
import {ShortVoter} from '../models/ShortVoter';
import {TimeDelta} from '../models/TimeDelta';

@Component({
  selector: 'app-poll-planned',
  templateUrl: 'poll-planned.component.html',
  styleUrls: ['./poll-planned.component.less']
})

export class PollPlannedComponent implements OnInit, OnDestroy {
  pollId: string;
  title: string;
  titles: Array<string> = [];
  startDate = '';
  endDate = '';
  votersCount = 0;
  description = '';
  isError = false;
  errorMessage: string;
  timeDifference = new TimeDelta(0, 0, 0, 0);
  timer: any;
  openAssessorTimer: any;
  isSms = false;
  is2FA = false;
  isPublic = false;
  votingType: any;
  answers: Array<any>;
  isLive: boolean;
  canSendConvocation: boolean;
  canResendAssessors: boolean;
  shouldDisplayConvocationWarning = false;
  shouldAssessorConvocationWarning = false;
  remainingTime: string;
  remainingAssessorTime: string;
  lists = [];
  inactiveAssessors: Array<ShortVoter>;
  assessors = 0;
  remainingAssessors: number;
  shouldDisplayAssessors = false;
  hasAssessorsButtons = false;
  sharedLinks: any;
  candidates: any;
  displayChangeAssessor = false;
  shouldDisplayChangeAssessorValidation = false;

  constructor(private router: Router,
              private apiService: ApiService,
              public common: Common) {
  }

  editPoll() {
    this.router
      .navigateByUrl('/vc-poll-edition', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['poll-creation/' + this.pollId]);
      });
  }

  handleConvocationStatus() {
    this.apiService.canUseConvocation(this.pollId).subscribe((res) => {
      this.canSendConvocation = true;
    }, (err: any) => {
      this.canSendConvocation = false;
      if (err.status === 403) {
        const inviteTresholdTimestamp = new Date(err.error).getTime();
        const timeDiffTimestamp = inviteTresholdTimestamp - new Date().getTime();
        const displayedHours = Math.floor(timeDiffTimestamp / 1000 / 60 / 60);
        const displayedMinutes = Math.floor((timeDiffTimestamp - (displayedHours * 1000 * 60 * 60)) / 1000 / 60);
        const displayedHrString = displayedHours > 9 ? displayedHours.toString() : '0' + displayedHours;
        const displayedMinString = displayedMinutes > 9 ? displayedMinutes.toString() : '0' + displayedMinutes;
        this.remainingTime = `${displayedHrString}h${displayedMinString}m`;
      }
      if (err.status === 410) {
        this.remainingTime = null;
      }
    });
  }

  handleAssessorSendStatus() {
    this.apiService.canUseAssessorSend(this.pollId).subscribe((res) => {
      this.canResendAssessors = true;
    }, (err: any) => {
      this.canResendAssessors = false;
      if (err.status === 403) {
        const inviteTresholdTimestamp = new Date(err.error).getTime();
        const timeDiffTimestamp = inviteTresholdTimestamp - new Date().getTime();
        const displayedHours = Math.floor(timeDiffTimestamp / 1000 / 60 / 60);
        const displayedMinutes = Math.floor((timeDiffTimestamp - (displayedHours * 1000 * 60 * 60)) / 1000 / 60);
        const displayedHrString = displayedHours > 9 ? displayedHours.toString() : '0' + displayedHours;
        const displayedMinString = displayedMinutes > 9 ? displayedMinutes.toString() : '0' + displayedMinutes;
        this.remainingAssessorTime = `${displayedHrString}h${displayedMinString}m`;
      }
      if (err.status === 410) {
        this.remainingAssessorTime = null;
      }
    });
  }

  displayConvocationPopup() {
    this.shouldDisplayConvocationWarning = true;
  }

  dismiss1Assessors() {
    this.shouldDisplayAssessors = false;
  }

  relaunchAssessors() {
    this.shouldAssessorConvocationWarning = true;
  }

  launchAssessorsRelaunch($event: boolean) {
    if (!$event) { this.shouldAssessorConvocationWarning = false; }
    else {
      this.apiService.relunchAssessors(this.pollId).subscribe(res => {
        this.shouldAssessorConvocationWarning = false;
        this.handleAssessorSendStatus();
        }, (err: any) => {
        this.shouldAssessorConvocationWarning = false;
        console.log(err);
      });
    }
  }

  handleConvocations($event: boolean) {
    if (!$event) { this.shouldDisplayConvocationWarning = false; }
    else {
      this.apiService.sendConvocations(this.pollId).subscribe((res) => {
        this.shouldDisplayConvocationWarning = false;
        this.handleConvocationStatus();
      }, (err: any) => {
        this.shouldDisplayConvocationWarning = false;
        console.log(err);
      });
    }
  }

  refreshPollOpenData() {
    this.apiService.getPoll(this.pollId).subscribe((res: ReceivedPoll) => {
      const timerDiff = (new Date(res.startDate)).getTime() - (new Date()).getTime();
      if (timerDiff < 0 && (!this.hasAssessorsButtons || res.opened === true)) {
          this.router.navigate(['/poll-ongoing/' + this.pollId]);
      }
    });
  }

  ngOnInit() {
    this.pollId = this.router.url.split('/')[2];
    this.getRemainingAssessors();

    this.apiService.getPoll(this.pollId).subscribe((res: ReceivedPoll) => {

      this.votingType = res.type;
      if (this.votingType.localeCompare('resolution') === 0) {
        this.answers = res.rounds[0].displayedQuestions;
        this.lists = res.rounds;
      }
      if (this.votingType.localeCompare('election') === 0) {
        this.answers = res.rounds;
        if (res.rounds[0].displayedQuestions.length > 0) {
          this.answers = res.rounds;
          this.candidates = this.answers[0].displayedQuestions;
        }
      }
      if (this.votingType.localeCompare('list') === 0) {
        this.answers = res.rounds;
        this.lists = res.rounds;
        res.rounds.forEach(element => {
          this.titles.push(element.title);
        });
      }
      this.title = res.name;
      this.votersCount = res.nbVoters;
      this.description = res.description;
      this.startDate = this.startDate + new Date(res.startDate).toLocaleString();
      this.endDate = this.endDate + new Date(res.endDate).toLocaleString();
      this.isLive = res.type === 'live' || res.type === 'liveCandidates';
      this.isSms = res.smsOption;
      this.is2FA = res.is2FA;
      this.sharedLinks = res.sharedLinks;
      this.isPublic = res.publicVote;
      this.hasAssessorsButtons = res.assessors > 0;
      this.assessors = res.assessors;
      const timeDiff = (new Date(res.startDate)).getTime() - (new Date()).getTime();
      this.timeDifference = this.common.getTimeDelta(timeDiff);
      this.handleConvocationStatus();
      this.timer = setInterval(() => {
        const timerDiff = (new Date(res.startDate)).getTime() - (new Date()).getTime();
        if (timerDiff < 0 && (!this.hasAssessorsButtons || res.opened === true)) {
            this.router.navigate(['/poll-ongoing/' + this.pollId]);
        }
        this.timeDifference = this.common.getTimeDelta(timerDiff);
      }, 1000);
      if (this.hasAssessorsButtons && !res.opened) {
        this.openAssessorTimer = setInterval(() => {
          this.refreshPollOpenData();
        }, 60 * 1000);
      }
      if (this.hasAssessorsButtons) {
        this.handleAssessorSendStatus();
      }

     }, (err: any) => {
       this.isError = true;
       this.errorMessage = 'Nous sommes désolés mais vous ne pouvez accéder au contenu \nerreur' + err.status;
     });
  }

  displayAssessors() {
    this.apiService.getInactiveAssessors(this.pollId).subscribe((res: Array<string>) => {
      this.inactiveAssessors = res.map( x => ShortVoter.withEmail(x));
      this.remainingAssessors = this.inactiveAssessors.length;
      this.shouldDisplayAssessors = true;
    });
  }

  getRemainingAssessors() {
    this.apiService.getInactiveAssessors(this.pollId).subscribe((res: Array<string>) => {
      this.inactiveAssessors = res.map( x => ShortVoter.withEmail(x));
      if (this.inactiveAssessors != undefined) {
        this.remainingAssessors = this.inactiveAssessors.length;
      }
    });
  }

  showChangeAssessors() {
    this.shouldDisplayAssessors = false;
    this.displayChangeAssessor = true;
  }

  closeChangeAssessor(event: boolean) {
    this.displayChangeAssessor = false;
    if (event) { this.shouldDisplayChangeAssessorValidation = true; }
  }

  closeChangeValidation() {
    this.shouldDisplayChangeAssessorValidation = false;
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      }
    if (this.openAssessorTimer) {
      clearInterval(this.openAssessorTimer);
      }
    }
}
