import { createAction, props } from '@ngrx/store';
import { Voter } from '../models/Voter';
import { LiveQuestion, PollChoice, Resolution } from '../models/PollChoice';

export const creationAction = createAction('init action');
export const resetAction = createAction('reset action');

export const setVotingTypeAction = createAction('Change votingType', props<{votingType: string}>())
export const setTitleAction = createAction('Change title', props<{title: string}>())
export const setDescriptionAction = createAction('Change description', props<{description: string}>())
export const setSenderAction = createAction('Change sender', props<{sender: string}>())
export const setDocumentAction = createAction('Change document', props<{sharedLinks: string}>())
export const setPublicVoteAction = createAction('Change Privacy', props<{isUncrypted: boolean}>())
export const setSMSAction = createAction('Change SMS', props<{isSMS: boolean}>())
export const set2FAAction = createAction('Change 2FA', props<{is2FA: boolean}>())
export const setTemporalityAction = createAction('Change temporality', props<{temporality: string}>())
export const setStartDateAction = createAction('Change start date', props<{startDate: string}>())
export const setStartTimeAction = createAction('Change start hour', props<{startTime: string}>())
export const setEndDateAction = createAction('Change end date', props<{endDate: string}>())
export const setEndTimeAction = createAction('Change end hour', props<{endTime: string}>())
export const setVotersAction = createAction('Change voters', props<{voters: Array<Voter>}>())
export const setResolutionsAction = createAction('Change resolutions', props<{resolutions: Array<Resolution>}>())
export const setActivateAssessorsAction = createAction('Change assessors toggle', props<{isAssessors: boolean}>())
export const setAssessorsNamesAction = createAction('Change assessors names', props<{assessorsNames: Array<string>}>())
export const setAssessorsMailsAction = createAction('Change assessors mails', props<{assessorsMails: Array<string>}>())
export const setLiveQuestionAction = createAction('Change election title', props<{liveQuestions: Array<LiveQuestion>}>())
export const setNumberOfVoteAction = createAction('Change number of vote', props<{numberOfVote: number}>())
export const setChoicesAction = createAction('Change choices', props<{choices: Array<Array<PollChoice>>}>())
export const setDraftFlagAction = createAction('Change draft flag', props<{draftSaved: boolean}>())
export const setPollImageAction = createAction('Change poll image', props<{imageId: string}>());
