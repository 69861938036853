import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WebsocketService } from "./websocket.service";
import { environment } from './../../environments/environment';
import { AuthenticationService } from './authentication.service'
import { User } from "../models/User";

export interface Message {
  author: string;
  message: string;
}

@Injectable()
export class LiveService {
  public messages: Subject<Message>;
  currentUser: User;

  constructor(private wsService: WebsocketService,
              private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  connect(pollId: string) {
    return this.wsService.connect(`${environment.settings.livews}${pollId}?token=Bearer%20${this.currentUser.token}`);
  }

  send(data: string, pollId: string) {
    const connection$ = this.wsService.connect(`${environment.settings.livews}${pollId}?token=Bearer%20${this.currentUser.token}`);
    if (connection$) {
      console.log(data)
      connection$.next(data);
      console.log('Data sent');
    } else {
      console.error('Did not send data, open a connection first');
    }
  }
}
