import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { saveAs } from 'file-saver';
import { Common } from '../shared/common';
import { ReceivedPoll } from '../models/ReceivedPoll';
import { ParticipationResponse } from '../models/ParticipationResponse';
import {ShortVoter} from "../models/ShortVoter";
import {AssessorData} from "../models/AssessorData";


@Component({
  selector: 'app-poll-finished',
  templateUrl: 'poll-finished.component.html',
  styleUrls: ['./poll-finished.component.less', '../shared/common.less']
})

export class PollFinishedComponent implements OnInit {
  pollId: string;
  title: string;
  pollData: ReceivedPoll;
  votingType: string;
  startDate = '';
  endDate = '';
  votersCount = 0;
  description = '';
  votes = 0;
  percentage = 0;
  isLive: boolean;
  isPublic: boolean;
  SMSOption: boolean;
  isSending = false;
  percentageString = '0';
  isError = false;
  errorMessage: string;
  displayIndex: number;
  realIndex: number;
  shouldReturnToPoll = false;
  resultsShared = false;
  shouldDisplaySharePopup = false;
  isShareEnabled = true;
  is2FA: any;
  endDateFormat: Date;
  language = 'fr';
  hasAssessors = false;
  calculating: boolean;
  timer: any;
  assessorsValidated: boolean;
  canResendAssessors: boolean;
  remainingAssessorTime: string;
  shouldAssessorConvocationWarning: boolean;
  inactiveAssessors: ShortVoter[];
  remainingAssessors: number;
  displayAssessorPopup: boolean;
  assessors: number;

  constructor(private router: Router,
              private apiService: ApiService,
              private route: ActivatedRoute,
              public common: Common
              ) { }

  downloadDocuments() {
    this.downloadCsv();
    this.downloadVoters();
    this.downloadVotersPdf();
    this.downloadResultsPdf();
  }

  handleAssessorSendStatus() {
    this.apiService.canUseAssessorOpeningSend(this.pollId).subscribe((res) => {
      this.canResendAssessors = true;
    }, (err: any) => {
      this.canResendAssessors = false;
      if (err.status === 403) {
        const inviteTresholdTimestamp = new Date(err.error).getTime();
        const timeDiffTimestamp = inviteTresholdTimestamp - new Date().getTime();
        const displayedHours = Math.floor(timeDiffTimestamp / 1000 / 60 / 60);
        const displayedMinutes = Math.floor((timeDiffTimestamp - (displayedHours * 1000 * 60 * 60)) / 1000 / 60);
        const displayedHrString = displayedHours > 9 ? displayedHours.toString() : '0' + displayedHours;
        const displayedMinString = displayedMinutes > 9 ? displayedMinutes.toString() : '0' + displayedMinutes;
        this.remainingAssessorTime = `${displayedHrString}h${displayedMinString}m`;
      }
      if (err.status === 410) {
        this.remainingAssessorTime = null;
      }
    });
  }

  getRemainingAssessors() {
    this.apiService.getNonClosingAssessors(this.pollId).subscribe((res: Array<AssessorData>) => {
      this.inactiveAssessors = res.map( x => ShortVoter.withEmailAndName(x.email, x.name));
      if (this.inactiveAssessors != undefined) {
        this.displayAssessorPopup = true
        this.remainingAssessors = this.inactiveAssessors.length
      }
    });
  }

  dismissAssessorsPopup() {
    this.displayAssessorPopup = false;
  }

  relaunchAssessors() {
    this.apiService.relunchNonClosingAssessors(this.pollId).subscribe(res => {
      this.shouldAssessorConvocationWarning = false;
      this.handleAssessorSendStatus()
      }, (err: any) => {
      console.log(err);
    });
  }
  downloadResultsPdf() {
    const name = this.getResultExcelName('pdf');
    if (this.displayIndex < 0 ) {
      if (this.votingType === 'list' && this.pollData.rounds.length > 1) {
        for (let round of this.pollData.rounds) {
          this.apiService.getResultsPdfRound(this.pollId, round.round, this.language).subscribe((res) => {
            const blob = new Blob([res], { type: 'application/pdf\n' });
            saveAs(blob, round.title+' '+name);
          });
        }
      } else {
        this.apiService.getResultsPdf(this.pollId, this.language).subscribe((res) => {
          const blob = new Blob([res], {type: 'application/pdf\n'});
          saveAs(blob, name);
        });
      }
    } else {
      this.apiService.getResultsPdfRound(this.pollId, this.realIndex, this.language).subscribe((res) => {
        const blob = new Blob([res], { type: 'application/pdf\n' });
        saveAs(blob, name);
      });
    }
  }

  downloadVotersPdf() {
    const name = this.common.getVotersExcelName('pdf', this.language);
    if (this.displayIndex < 0 ) {
      this.apiService.getVotersPdf(this.pollId).subscribe((res) => {
        const blob = new Blob([res], {type: 'application/pdf\n'});
        saveAs(blob, name);
      });
    } else {
      this.apiService.getVotersPdfRound(this.pollId, this.displayIndex).subscribe((res) => {
        const blob = new Blob([res], {type: 'application/pdf\n'});
        saveAs(blob, name);
      });
    }
  }

  getResultExcelName(ext: string) {
    switch (this.language) {
      case 'fr': return  `Resultats.${ext}`;
      case 'es': return `Resultados.${ext}`;
      default: return `Results.${ext}`;
    }
  }

  downloadCsv() {
    const name = this.getResultExcelName('xlsx');
    if (this.displayIndex < 0 ) {
      if (this.votingType === 'list' && this.pollData.rounds.length > 1) {
          for (let round of this.pollData.rounds) {
            this.apiService.getResultsXlsxRound(this.pollId, round.round, this.language).subscribe((res) => {
              const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n' });
              saveAs(blob, round.title+' '+name);
            });
          }
      } else {
        this.apiService.getResultsXlsx(this.pollId, this.language).subscribe((res) => {
          const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n'});
          saveAs(blob, name);
        });
      }
    } else {
      this.apiService.getResultsXlsxRound(this.pollId, this.realIndex, this.language).subscribe((res) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n' });
        saveAs(blob, name);
      });
    }
  }

  downloadVoters() {
    const name = this.common.getVotersExcelName('xlsx', this.language);
    if (this.displayIndex < 0 ) {
      if (this.votingType === 'list' && this.pollData.rounds.length > 1) {
          for (const round of this.pollData.rounds) {
            this.apiService.getVotersXlsxRound(this.pollId, round.round, this.language).subscribe((res) => {
              const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n'});
              saveAs(blob, round.title + ' ' + name);
            });
          }
        } else {
          this.apiService.getVotersXlsx(this.pollId, this.language).subscribe((res) => {
            const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n'});
            saveAs(blob, name);
          });
        }
    } else {
      this.apiService.getVotersXlsxRound(this.pollId, this.realIndex, this.language).subscribe((res) => {
        const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n'});
        saveAs(blob, name);
      });
    }
  }

  shareResults() {
    this.shouldDisplaySharePopup = true;
  }
  dismissSharePopup(event: boolean) {
    if (event) {
      this.isSending = true;
      this.shouldDisplaySharePopup = false;
      this.apiService.setPublish(this.pollId).subscribe((res) => {
        this.isSending = false;
        this.resultsShared = true;
      });
    } else {
      this.shouldDisplaySharePopup = false;
    }
  }

  rejoinPoll() {
    this.router.navigate(['/poll-live/' + this.pollId]);
  }

  getElectionCalculated() {
    this.apiService.getResults(this.pollId).subscribe((res) => {
      if (Object.keys(res).length === 0){
        this.calculating = true;
        const self = this;
        this.timer = setTimeout(() => {
            self.getElectionCalculated();
          }, 30000);
      } else {
        clearTimeout(this.timer)
        this.calculating = false;
      }
    });
  }

  initData() {
    this.apiService.getPoll(this.pollId).subscribe((res: ReceivedPoll) => {
      this.apiService.getPollPreview(this.pollId).subscribe((assessors: any) => {
        this.assessorsValidated = assessors.validatedAssessors >= 2;
      })
      this.isPublic = res.publicVote;
        this.is2FA = res.is2FA;
        this.title = res.name;
        this.votersCount = res.nbVoters;
        this.description = res.description;
        this.votingType = res.type;
        this.isLive = res.isLive;
        if (!this.isLive && res.assessors > 0) {
          this.handleAssessorSendStatus();
          this.canResendAssessors = false;
        }
        this.SMSOption = res.smsOption;
        this.startDate = this.startDate + new Date(res.startDate).toLocaleString();
        this.endDateFormat = new Date(res.endDate);
        this.endDate = this.endDate + new Date(res.endDate).toLocaleString();
        this.resultsShared = res.shareResults;
        this.hasAssessors = res.assessors > 0;
        this.assessors = res.assessors;
        this.shouldReturnToPoll = res.hasBeenClosed !== true && (new Date(res.startDate).getTime() + 3600000 * 24 > new Date().getTime());
        this.isShareEnabled = res.isLive !== true || (res.hasBeenClosed === true) || (new Date(res.endDate) < new Date());
        this.pollData = res;
        if (this.displayIndex === -1) {
          this.apiService.getParticipation(this.pollId).subscribe((partRes: ParticipationResponse) => {
            if (partRes.votes != null ) {
              this.votes = partRes.votes;
            }
            this.percentage = (this.votes / this.votersCount) * 100;
            this.percentageString = parseFloat(this.percentage.toFixed(2)).toLocaleString();
          }, (err: any) => {
            this.isError = true;
            this.errorMessage = 'Nous sommes désolés mais vous ne pouvez accéder au contenu \
                erreur ' + err.status;
          });
        } else {
          this.realIndex = this.isLive && res.rounds.length >= this.displayIndex ?
                                res.rounds[this.displayIndex].round : this.displayIndex;
          this.apiService.getParticipationRound(this.pollId, this.realIndex).subscribe((partRes: ParticipationResponse) => {
            if (partRes.votes != null ) {
              this.votes = partRes.votes;
            }
            this.percentage = (this.votes / this.votersCount) * 100;
            this.percentageString = parseFloat(this.percentage.toFixed(2)).toLocaleString();
          }, (err: any) => {
            this.isError = true;
            this.errorMessage = 'Nous sommes désolés mais vous ne pouvez accéder au contenu \
                erreur ' + err.status;
          });
        }
        if (this.hasAssessors) {
          this.apiService.getNonClosingAssessors(this.pollId).subscribe((res: Array<AssessorData>) => {
            this.remainingAssessors = res.length;
          });
        }
     }, (err: any) => {
       this.isError = true;
       this.errorMessage = 'Nous sommes désolés mais vous ne pouvez accéder au contenu \
           erreur ' + err.status;
     });
     this.getElectionCalculated()
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.pollId = params.poll;
        if (params.resolution == null) {
          this.displayIndex = -1 ;
        } else {
          this.displayIndex = parseInt(params.resolution, 10);
        }
        this.realIndex = this.displayIndex;
        this.initData();
      });
    const languageKey = 'userLanguage';
    const userLang = navigator.language || navigator[languageKey];
    this.language = userLang.substring(0, 2);
  }
}
