import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Resolution, EditedResolution, WsCommand, LiveResolution } from '../../models/PollChoice';
import {ApiService} from "../../shared/api.service";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../firebase";
import { v4 } from "uuid";

@Component({
  selector: 'vc-live-polls-list',
  templateUrl: 'live-polls-list.component.html',
  styleUrls: ['./live-polls-list.component.less']
})

export class LivePollsListComponent {
  @Input() votingType: string;
  @Input() isPublic: boolean;
  @Input() livePolls: Array<LiveResolution>;
  @Input() resolutions: Array<Resolution>;
  @Input() activeIndex: number;
  @Input() canAddQuestions: boolean;
  @Output() sendCommand = new EventEmitter<WsCommand>();
  @Output() sendResults = new EventEmitter<number>();
  @Output() submitResolution = new EventEmitter<Resolution>();
  @Output() deleteResolutionEvent = new EventEmitter<number>();
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  liveResolutions = [new LiveResolution(0, new Resolution(true), 0)];
  addedResolution: Resolution = null;
  constructor(private apiService: ApiService) {
  }
  
  getResults(data: number) {
    this.sendResults.emit(data)
  }

  wsCommand(data) {
    this.sendCommand.emit(data);
  }

  addChoice() {
    this.addedResolution = new Resolution(true);
  }

  deleteExistingResolution($event) {
    this.deleteResolutionEvent.emit($event);
  }

  getImageData($event) {
    const pollPayload = new FormData();
    const ext = $event[0].name.substring($event[0].name.lastIndexOf('.'), $event[0].name.length);
    const name = v4() + ext;
    pollPayload.append(name, $event[1]);
    this.apiService.saveElectionImage(pollPayload).subscribe((res) => {
      getDownloadURL(ref(storage, res[0])).then((url) => {
        this.addedResolution.image = url;
      })
    })
  }

  deleteResolution() {
    this.addedResolution = null;
  }

  addResolution() {
    this.submitResolution.emit();
    this.deleteResolution();
  }
}
