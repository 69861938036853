import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PollItemData } from "../../models/PollItemData";
import { Router } from "@angular/router";
import { ApiService } from "../../shared/api.service";

@Component({
  selector: "vc-poll-dashboard-item",
  templateUrl: "poll-dashboard-item.component.html",
  styleUrls: ["./poll-dashboard-item.component.less"],
})
export class PollDashboardItemComponent implements OnInit {
  @Input() pollData: PollItemData;
  @Input() column: number;
  @Input() super: boolean = false;
  warningPopup = false;
  startDate: string;
  endDate: string;
  @Output() openPollEvent = new EventEmitter<boolean>();
  @Output() deletePollEvent = new EventEmitter<boolean>();
  @Output() editPollEvent = new EventEmitter<boolean>();
  @Output() clonePollEvent = new EventEmitter<boolean>();
  hideEditButton = false;

  constructor(private router: Router, private apiService: ApiService) {}

  openPoll($event) {
    if ($event.target.id.localeCompare('delete-icon') != 0 &&
        $event.target.id.localeCompare("edit-icon") != 0 &&
        $event.target.id.localeCompare("clone-icon") != 0&&
        $event.target.id.localeCompare("export-icon") != 0) {
      this.openPollEvent.emit(true);
    }
  }

  deletePoll() {
    if(this.column !== 0) {
      this.apiService.deletePoll(this.pollData.id).subscribe(
        (res) => {
          this.deletePollEvent.emit(true);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.apiService.deleteDraft(this.pollData.id).subscribe(
        (res) => {
          this.deletePollEvent.emit(true);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  editPopup() {
    this.editPollEvent.emit(true);
  }

  deletePopup() {
    this.warningPopup = true;
  }

  cloneAction() {
    this.clonePollEvent.emit(true);
  }

  exportAction() {
    this.apiService.getExportTokens(this.pollData.id).subscribe();
  }

  dismissDeletePopup($event) {
    this.warningPopup = false;
    if ($event) { this.deletePoll(); }
  }

  ngOnInit() {
    this.hideEditButton = !this.pollData.editable;
    this.startDate = new Date(this.pollData.startDate).toLocaleDateString();
    this.endDate = new Date(this.pollData.endDate).toLocaleDateString();
  }
}
