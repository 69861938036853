<div *ngIf="isImportError">
  <h1 *ngIf="!isPonderationError && isSMSError && !is2FA && !(numberOfVoters > 1)" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-SMS' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT' |
    translate}}
  </h1>
  <h1 *ngIf="!isPonderationError && isSMSError && !is2FA && numberOfVoters > 1" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-SMS-PL' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT-PL' |
    translate}}
  </h1>
  <h1 *ngIf="!isPonderationError && !isSMSError && !is2FA && !(numberOfVoters > 1)" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-MAIL' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT' |
    translate}}
  </h1>
  <h1 *ngIf="!isPonderationError && !isSMSError && !is2FA && numberOfVoters > 1" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-MAIL-PL' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT-PL' |
    translate}}
  </h1>
  <h1 *ngIf="!isPonderationError && is2FA && !(numberOfVoters > 1)" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-SMS' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT-2FA' |
    translate}}
  </h1>
  <h1 *ngIf="!isPonderationError && is2FA && numberOfVoters > 1" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-SMS-PL' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT-2FA-PL' |
    translate}}
  </h1>
  <h1 *ngIf="isPonderationError && !(numberOfVoters > 1)" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-PONDERATION' | translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT' |
    translate}}
  </h1>
  <h1 *ngIf="isPonderationError && numberOfVoters > 1" class="header_title">
    {{'VOTERS-INVALID-POPUP.TITLE-PONDERATION-PL' |
    translate}}{{messageString}}{{'VOTERS-INVALID-POPUP.INCORRECT-PL'
    | translate}}
  </h1>
  <h1 *ngIf="!(numberOfVoters > 1) && ((is2FA && isPonderationError) || !is2FA)" class="header_title">
    {{'VOTERS-INVALID-POPUP.DESCRIPTION' | translate}}
  </h1>
  <h1 *ngIf="numberOfVoters > 1 && ((is2FA && isPonderationError) || !is2FA)" class="header_title">
    {{'VOTERS-INVALID-POPUP.DESCRIPTION-PL' | translate}}
  </h1>
  <h1 *ngIf="numberOfVoters == 1 && is2FA && !isPonderationError" class="header_title">
    {{'VOTERS-INVALID-POPUP.DESCRIPTION-2FA' | translate}}
  </h1>
  <h1 *ngIf="numberOfVoters > 1 && is2FA && !isPonderationError" class="header_title">
    {{'VOTERS-INVALID-POPUP.DESCRIPTION-2FA-PL' | translate}}
  </h1>
</div>