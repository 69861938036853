<div class="form-holder">
  <vc-poll-card-header [image]="'assets/electeurs.svg'" [title]="'POLL-CREATION.VOTERS.TITLE' | translate" [description]="'POLL-CREATION.VOTERS.DESCRIPTION' | translate">
  </vc-poll-card-header>
  <div class="safe-area">
    <mat-button-toggle-group value="true" appearance="legacy" (change)="changed($event)" style="border-radius: 6px;">
      <mat-button-toggle id="left-toggle" value="true">{{'POLL-CREATION.VOTERS.EXCEL-BUTTON' | translate}}</mat-button-toggle>
      <mat-button-toggle id="right-toggle" value="false">{{'POLL-CREATION.VOTERS.FAST-IMPORT-BUTTON' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>
    <div [hidden]="isExcelUpload === 'false'">
      <div class="upload-description">{{'POLL-CREATION.VOTERS.UPLOAD-DESCRIPTION' | translate}}</div>
      <button type="button" id="template-download" (click)="openModel()">
        <img src="assets/icon_download.svg" id="model-icon">
        <span>{{'POLL-CREATION.VOTERS.VOTERS-MODEL' | translate}}</span>
      </button>
      <div *ngIf="!isVotersValid">
        <div class="upload-button" (click)="fileInput.click()" appDragDrop (onFileDropped)="onFileDropped($event)">
          <img src="assets/file-excel.png" id="background-button">
          <p class="description-upload">{{'POLL-CREATION.VOTERS.IMPORT' | translate}}</p>
          <input style="display: none"
            type="file" accept=".xlsx, .xls" (change)="onFileChanged($event); fileInput.value=''" #fileInput>
        </div>
      </div>
      <div *ngIf="isVotersValid" id="resume-holder">
        <p>
          <span id="voters-count">{{votersCountString}}</span>
          <span id="voters-description">{{'POLL-CREATION.VOTERS.VOTERS-DESCRIPTION' | translate}}</span>
        </p>
        <div id="uploaded-buttons">
          <button type="button" id="display-voters" (click)="displayVoters()">{{'POLL-CREATION.VOTERS.VERIFY' | translate}}</button>
          <div id="file-change" (click)="fileInput.click()">
            <body class="change-text">{{'POLL-CREATION.VOTERS.CHANGE-FILE' | translate}}</body>
            <input style="display: none"
              type="file" accept=".xlsx, .xls" (change)="onFileChanged($event); fileInput.value=''" #fileInput>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="isExcelUpload === 'true'">
      <div class="upload-description">{{'POLL-CREATION.VOTERS.IMPORT-DESCRIPTION' | translate}}</div>
      <div *ngFor="let voter of voters; let i = index; trackBy: trackVoter" style="display: flex; justify-content: space-between;  width: 360px">
        <input type="email" class="mail-input" (keyup)="onMailInput($event, i)" maxlength="255" placeholder="{{'POLL-CREATION.VOTERS.EMAIL-PLACEHOLDER' | translate}}" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" value = {{voter.email}}>

        <div *ngIf="i != 0" class="delete-container">
          <div class="icon-container">
            <img id="delete-button" (click)="deleteVoter(i)" src="assets/deletebutton.svg">
          </div>
        </div>

      </div>
      <button *ngIf="voters.length < 10" id="add-voter" (click)=addVoter()>
        +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.VOTERS.ADD-MAIL' | translate}}
      </button>
      <div *ngIf="voters.length >= 9" class="separator"></div>
    </div>
    <h4 class="checkboxes-title">{{'POLL-CREATION.VOTERS.INVITE-TYPE' | translate}}</h4>
    <p class="description">{{'POLL-CREATION.VOTERS.SMS' | translate}}</p>

    <div *ngIf="!is2FA" class="checkbox-holder">
      <span id="span-mail" [ngClass]="isSMS ? 'checkbox-label-left-gray' : 'checkbox-label-left'">{{'COMMON.MAIL' | translate}}</span>
      <label class="switch">
        <input type="checkbox" id="sms-button" [(ngModel)]="isSMS"  data-md-icheck (change)="setSMS($event)">
        <span class="slider round"></span>
      </label>
      <span  [ngClass]="isSMS ? 'checkbox-label' : 'checkbox-label-gray'">{{'COMMON.MAIL-SMS' | translate}}</span>
    </div>
    <div *ngIf="!is2FA" class="disclaimers-holder">
      <span class="sms-disclaimer-left"></span>
      <div class="spacer-44"></div>
      <span class="sms-disclaimer">{{'POLL-CREATION.VOTERS.DELIVRABILITY' | translate}}</span>
    </div>

    <div  *ngIf="is2FA" class="checkbox-holder" style="opacity: 30%;">
      <span [ngClass]="isSMS ? 'checkbox-label-left-gray' : 'checkbox-label-left'">{{'COMMON.MAIL' | translate}}</span>
      <label class="switch">
        <span style="cursor:default" class="slider round"></span>
      </label>
      <span [ngClass]="isSMS ? 'checkbox-label' : 'checkbox-label-gray'">{{'COMMON.MAIL-SMS' | translate}}</span>
    </div>
    <div  *ngIf="is2FA" class="disclaimers-holder" style="opacity: 30%;">
      <span class="sms-disclaimer-left"></span>
      <div class="spacer-44"></div>
      <span class="sms-disclaimer">{{'POLL-CREATION.VOTERS.DELIVRABILITY' | translate}}</span>
    </div>

    <div class="checkbox-holder">
      <span [ngClass]="is2FA ? 'checkbox-label-left-gray' : 'checkbox-label-left'">{{'POLL-CREATION.VOTERS.1FA' | translate}}</span>
      <label class="switch">
        <input type="checkbox" id="2fa-button" [(ngModel)]="is2FA"  data-md-icheck (change)="set2FA($event)">
        <span class="slider round"></span>
      </label>
      <span [ngClass]="is2FA ? 'checkbox-label' : 'checkbox-label-gray'" style="white-space: nowrap;">{{'POLL-CREATION.VOTERS.2FA' | translate}}</span>
    </div>
    <div class="disclaimers-holder second-disclaimer">
      <span class="sms-disclaimer-left"></span>
      <div class="spacer-44"></div>
      <span class="auth-disclaimer">{{'POLL-CREATION.VOTERS.DOUBLE' | translate}}</span>
    </div>
    <div style="height: 20px;"></div>
  </div>
</div>
