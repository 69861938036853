import {Component, Input} from '@angular/core';
import {Result} from '../../../models/Poll';

@Component({
  selector: 'vc-poll-results-result',
  templateUrl: './poll-results-result.component.html',
  styleUrls: ['./poll-results-result.component.less'],
})
export class PollResultsResultComponent {
  @Input() result: Result;
  choice: string;

  constructor() {
  }

  ngOnInit() {
    var userLang = navigator.language || navigator['userLanguage'];
    switch (this.result.text) {
      case "POUR": {
        switch (userLang.substring(0,2)) {
          case 'fr': { this.choice = 'POUR'; break; }
          case 'es': { this.choice = 'A FAVOR'; break; }
          default: this.choice = 'FOR';
        }
        break;
      }
      case "CONTRE": {
        switch (userLang.substring(0,2)) {
          case 'fr': { this.choice = 'CONTRE'; break; }
          case 'es': { this.choice = 'EN CONTRA'; break; }
          default: this.choice = 'AGAINST';
        }
        break
      }
      case "ABSTENTION": {
        switch (userLang.substring(0,2)) {
          case 'es': { this.choice = 'ABSTENCIÓN'; break; }
          default: this.choice = 'ABSTENTION';
        }
        break
      }
      default:
        this.choice = this.result.text;
        break
    }
  }

}
