import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Common } from '../shared/common';
import { ParticipationResponse } from '../models/ParticipationResponse';
import { Round } from '../models/ReceivedPoll';
import { AuthenticationService } from '../shared/authentication.service';
import { environment } from '../../environments/environment';
import { LicenseData } from '../models/LicenseData';
import { LicenseService } from '../shared/licens.service';
import {ShortVoter} from '../models/ShortVoter';
import {TimeDelta} from '../models/TimeDelta';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-poll-ongoing',
  templateUrl: 'poll-ongoing.component.html',
  styleUrls: ['./poll-ongoing.component.less', '../shared/common.less']
})

export class PollOngoingComponent implements OnInit, OnDestroy {
  candidates: any;
  startDate = '';
  stripe: stripe.Stripe;
  endDate = '';
  pollId: string;
  title: string;
  isOpen: boolean;
  votersCount = 0;
  description = '';
  votes = 0;
  percentage = 0;
  percentageString = '0';
  isError = false;
  isLive = false;
  resolutions: Array<string> = [];
  errorMessage: string;
  isPublic: boolean;
  stripeConstants = environment.stripeConstants;
  timeDifference = new TimeDelta(0, 0, 0, 0);
  timer: any;
  shouldDisplayVoters = false;
  remainingVoters: Array<ShortVoter>;
  shouldDisplayRelaunchWarning = false;
  isSms = false;
  is2FA = false;
  emailsSent = false;
  inviteDate = '';
  remainingTime: string;
  answers: Array<any>;
  votingType: string;
  lists = [];
  resendOngoingLoader = false;
  sharedLinks: any;
  hasAssessorsButtons = false;
  electionList: any;
  displayedAnswers: Array<Array<string>> = [];
  subjects: any;
  titles: Array<string> = [];
  maxVoters = 10;
  accountName = '';
  hasHotline: any;
  licenseTime: string;
  eventCounter = 0;
  licencesReady = false;
  isAssoc: boolean;
  clientType = 'assoc';
  rounds: Array<Round>;
  shouldChangeVoter = false;
  shouldDisplayChangeVoterValidation = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public common: Common,
    public route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private licenseService: LicenseService
    ) {
  }

  refreshData() {
    this.route.queryParams
          .subscribe(qParams => {
            if (qParams.resolution == null) {
              this.apiService.getParticipation(this.pollId).subscribe((partRes: ParticipationResponse) => {
                this.setupParticipation(partRes);
              });
            } else {
              const index = parseInt(qParams.resolution, 10);
              const realIndex = this.isLive && this.rounds.length >= index ? this.rounds[index].round : index;
              this.apiService.getParticipationRound(this.pollId, realIndex).subscribe((partRes: ParticipationResponse) => {
                this.setupParticipation(partRes);
              });
            }
          });
  }

  rejoinPoll() {
      this.router.navigate(['/poll-live/' + this.pollId]);
  }

  displayVoters() {
    this.apiService.getRRemaining(this.pollId).subscribe((res: Array<ShortVoter>) => {
      this.remainingVoters = res;
      this.shouldDisplayVoters = true;
    });
  }

  dismissVoters() {
    this.shouldDisplayVoters = false;
  }

  downloadVoterFile() {
    this.apiService.getVotersFile(this.pollId, this.common.getLangCode()).subscribe((res) => {
      const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n'});
      saveAs(blob, this.common.getVotersExcelName('xlsx', this.common.getLangCode()));
    });
  }

  displayRelaunchWarning() {
    this.shouldDisplayRelaunchWarning = true;
  }

  handleRelaunch($event: boolean) {
    if (!$event) { this.shouldDisplayRelaunchWarning = false; }
    else {
      this.resendOngoingLoader = true;
      this.shouldDisplayRelaunchWarning = false;
      this.apiService.resendInvitations(this.pollId).subscribe((res) => {
        this.resendOngoingLoader = false;
        this.emailsSent = true;
        this.shouldDisplayRelaunchWarning = false;
        this.handleResend();
      }, (err: any) => {
        this.resendOngoingLoader = false;
        this.shouldDisplayRelaunchWarning = false;
        console.log(err);
      });
    }
  }

  handleResend() {
    this.apiService.canResend(this.pollId).subscribe((res) => {
      console.log(res);
    }, (err: any) => {
      this.emailsSent = true;
      if (err.status === 403) {
        this.inviteDate = new Date(err.error).toLocaleString();
        const inviteTresholdTimestamp = new Date(err.error).getTime();
        const timeDiffTimestamp = inviteTresholdTimestamp - new Date().getTime();
        const displayedHours = Math.floor(timeDiffTimestamp / 1000 / 60 / 60);
        const displayedMinutes = Math.floor((timeDiffTimestamp - (displayedHours * 1000 * 60 * 60)) / 1000 / 60);
        const displayedHrString = displayedHours > 9 ? displayedHours.toString() : '0' + displayedHours;
        const displayedMinString = displayedMinutes > 9 ? displayedMinutes.toString() : '0' + displayedMinutes;
        this.remainingTime = `${displayedHrString}h${displayedMinString}m`;
      }
      if (err.status === 410) {
        this.remainingTime = null;
      }
    });
  }

  editPoll() {
    this.router
      .navigateByUrl('/vc-poll-edition', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['poll-creation/' + this.pollId]);
      });
  }

  electionToListData() {
    // Match subject from the first index of this.answers to the lists from following indexes
    this.electionList.forEach(rounds => {

      rounds.displayedQuestions.forEach((element, index) => {

        if (element.parent === this.displayedAnswers.length && rounds.displayedQuestions.length - 1 !== index) {
          this.displayedAnswers [element.parent] = [];
        }

        if (rounds.displayedQuestions.length - 1 !== index) {
          index === 0 ? this.subjects = element.answers : this.displayedAnswers[element.parent].push(element.question);
        }
      });
    });
  }

  changeVoter() {
    this.shouldChangeVoter = true;
  }

  setupParticipation(partRes: ParticipationResponse) {
    if (partRes.votes != null ) {
      this.votes = partRes.votes;
    }
    this.percentage = (this.votes / this.votersCount) * 100;
    if (this.percentage === 100) {
      this.percentageString = this.percentage.toPrecision(3);
    }
    else {
      this.percentageString = this.percentage.toFixed(2);
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.pollId = params.get('id');
      this.handleResend();
      this.apiService.getPollData(this.pollId).subscribe((res: any) => {
        this.votingType = res.type;
        this.isOpen = res.opened;
        this.title = res.name;
        this.lists = res.rounds;
        res.rounds.forEach(element => {
          this.titles.push(element.title);
        });
        this.isLive = res.isLive;
        this.votersCount = res.nbVoters;
        this.description = res.description;
        this.startDate = this.startDate + new Date(res.startDate).toLocaleString();
        this.endDate = this.isLive ? '-' : this.endDate + new Date(res.endDate).toLocaleString();
        this.isPublic = res.publicVote;
        this.isSms = res.smsOption;
        this.is2FA = res.is2FA;
        this.hasAssessorsButtons = res.assessors > 0;
        this.sharedLinks = res.sharedLinks;
        this.rounds = res.rounds;
        if (this.votingType.localeCompare('resolution') === 0) {
          this.answers = res.rounds[0].questions;
          if (res.rounds[0].displayedQuestions.length > 0) {
            this.answers = res.rounds[0].displayedQuestions;
          }
        } else {
          this.electionList = res.rounds;
          if (res.rounds[0].displayedQuestions.length > 0) {
            this.answers = res.rounds;
            this.candidates = this.answers[0].displayedQuestions;
          }
        }
        if ((res.type === 'resolution' || res.type === 'live') && this.isLive) {
          for (const resolution of res.rounds[0].questions) {
            this.resolutions.push(resolution.question);
          }
          const timeDiff = (new Date(res.endDate)).getTime() - (new Date()).getTime();
          this.timeDifference = this.common.getTimeDelta(timeDiff);
        }
        this.timer = setInterval(() => {
          const timeDiff = (new Date(res.endDate)).getTime() - (new Date()).getTime();
          if (timeDiff < 0) {
              this.router.navigateByUrl('/poll-result?poll=' + this.pollId);
          }
          this.timeDifference = this.common.getTimeDelta(timeDiff);
        }, 1000);
        this.route.queryParams
          .subscribe(qParams => {
            if (qParams.resolution == null) {
              this.apiService.getParticipation(this.pollId).subscribe((partRes: ParticipationResponse) => {
                this.setupParticipation(partRes);
              });
            } else {
              const index = parseInt(qParams.resolution, 10);
              const realIndex = this.isLive && res.rounds.length >= index ? res.rounds[index].round : index;
              this.apiService.getParticipationRound(this.pollId, realIndex).subscribe((partRes: ParticipationResponse) => {
                this.setupParticipation(partRes);
              });
            }
          });
        if (this.votingType === 'list') { this.electionToListData(); }
      }, (err: any) => {
        this.isError = true;
        this.errorMessage = 'Nous sommes désolés mais vous ne pouvez accéder au contenu \
            erreur ' + err.status;
      });
    });
    this.stripe = Stripe(this.stripeConstants.key);

    this.licenseService.license.subscribe((res: LicenseData) => {
      this.licencesReady = true;
      this.hasHotline = res.hasHotline;
      this.maxVoters = res.votes;
      this.eventCounter = res.events;
      if (res.client !== null) { this.isAssoc = !(res.client !== 'corp'); }
      this.clientType = res.client;
      this.licenseTime = this.common.licenseResponseGetTime(res);
      this.accountName = this.authenticationService.getUserData();
    });
  }

  closeChangeVoter(event: boolean) {
    this.shouldChangeVoter = false;
    if (event) { this.shouldDisplayChangeVoterValidation = true; }
  }

  closeChangeValidation() {
    this.shouldDisplayChangeVoterValidation = false;
  }

  ngOnDestroy() {
  if (this.timer) {
    clearInterval(this.timer);
    }
  }
}
