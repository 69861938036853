import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {TimeDelta} from '../../models/TimeDelta';

@Component({
  selector: 'app-poll-planned-timer',
  templateUrl: 'poll-planned-timer.component.html',
  styleUrls: ['./poll-planned-timer.component.less', '../../shared/common.less']
})

export class PollPlannedTimerComponent implements OnInit, OnChanges {
  @Input() timeDelta: TimeDelta;
  @Input() hasAssessorsButtons: boolean;
  @Input() canSendConvocations: boolean;
  @Input() canResendAssessors: boolean;
  @Input() isAssessorPreview = false;
  @Input() inactiveAssessors: number;
  @Output() shouldLaunchConvocations = new EventEmitter<boolean>();
  @Output() shouldGetAssessors = new EventEmitter<boolean>();
  @Output() shouldRelaunchAssessors = new EventEmitter<boolean>();
  @Output() shouldNavigateToOpening = new EventEmitter<boolean>();
  @Output() sendChangeAssessor = new EventEmitter();
  @Input() timeDiffHours: number;
  @Input() remainingAssessorTime: string;
  @Input() assessorCount = 0;
  participation = 0;

  constructor() {
  }

  launchConvocations() {
    this.shouldLaunchConvocations.emit(true);
  }

  relaunchAssessors() {
    this.shouldRelaunchAssessors.emit(true);
  }

  getInactiveAssessors() {
    this.shouldGetAssessors.emit(true);
  }

  navigateToOpening() {
    this.shouldNavigateToOpening.emit(true);
  }

  ngOnInit() {
  }

  changeAssessors() {
    this.sendChangeAssessor.emit();
  }

  ngOnChanges() {
    this.participation = Math.round((this.assessorCount - this.inactiveAssessors) / this.assessorCount * 100);
  }
}
