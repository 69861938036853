import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-assessor-validation-header',
  templateUrl: './assessor-validation-header.component.html',
  styleUrls: ['./assessor-validation-header.component.less', '../../poll-live/poll-pages-header/poll-pages-header.component.less', '../../shared/common.less']
})
export class AssessorValidationHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isLive: boolean;
  @Input() isPublic: boolean;
  @Input() SMSOption: boolean;
  @Input() is2FA: boolean;
  @Input() isAssessors: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  navigateHome() {
    console.log('navigate home');
  }
}
