import {Component, Input, OnInit} from '@angular/core';
import {Poll} from '../../models/Poll';
import {LiveQuestion, PollChoice, Resolution} from '../../models/PollChoice';

@Component({
  selector: 'app-questions-preview',
  templateUrl: './questions-preview.component.html',
  styleUrls: ['./questions-preview.component.less']
})
export class QuestionsPreviewComponent implements OnInit {
  @Input() poll: Poll;
  showDetails = false;
  activeResolution: Resolution;
  activeEvent: LiveQuestion;
  activeChoices: PollChoice[];
  activeListElections: Array<LiveQuestion>;
  showListDetails = false;
  blankVoteChoice = PollChoice.withName('Vote blanc');

  constructor() { }

  setActiveResolution(resolution: Resolution) {
    this.activeResolution = resolution;
    this.showDetails = true;
  }

  getEventLength(): number {
    if (this.poll.votingType === 'resolutions') { return this.poll.resolutions.length; }
    else {
      if (this.poll.votingType === 'list') { return this.poll.electionIndexes.length; }
      else { return this.poll.liveQuestions.length; }
    }
  }

  getElectionEventTitles(): Array<LiveQuestion> {
    return this.poll.electionIndexes.map(i => this.poll.liveQuestions[i]);
  }

  setActiveElection(election: LiveQuestion) {
    this.activeEvent = election;
    this.showDetails = true;
    if (this.poll.votingType === 'list' || this.poll.votingType === 'election') {
      this.activeChoices = this.poll.choices[this.poll.liveQuestions.indexOf(election)];
    }
  }

  setActiveEvent(index: number) {
    const startIndex = this.poll.electionIndexes[index];
    if (index >= this.poll.electionIndexes.length - 1) {
      this.activeListElections = this.poll.liveQuestions.slice(startIndex, this.poll.liveQuestions.length);
    } else {
      this.activeListElections = this.poll.liveQuestions.slice(startIndex, startIndex + 2);
    }
    this.showDetails = true;
  }

  setListDetails(election: LiveQuestion) {
    this.setActiveElection(election);
    this.showListDetails = true
  }

  cancelListDetails() {
    this.showListDetails = false;
  }

  back() {
    this.showDetails = false;
  }

  dismissDetails() {
    this.showDetails = false;
  }

  ngOnInit(): void {
    console.log(this.poll);
  }

}
