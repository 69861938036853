import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { AuthenticationService } from "../../shared/authentication.service";
import { ContactData } from "../../models/ContactData";
import { OfferCommand, OfferInfo } from "../../models/Enums";

@Component({
  selector: "vc-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.less"],
})
export class Offers {
  @Input() source: string;
  @Output() isClosed = new EventEmitter<boolean>();
  @Output() freeTrial = new EventEmitter<boolean>();
  @Output() signup = new EventEmitter<boolean>();

  selectedCardIndex = 0;
  status = 0;
  isAssoc = true;

  constructor(
    public auth: AuthService,
    private authenticationService: AuthenticationService
  ) {}

  defineActive($event: { target: { checked: boolean } }) {
    const asso = document.getElementById("asso");
    const company = document.getElementById("company");
    this.isAssoc = !$event.target.checked;
    if ($event.target.checked) {
      company.className = "active";
      asso.className = "inactive";
    } else {
      company.className = "inactive";
      asso.className = "active";
    }
  }

  clicAction($event: { target: { className: string } }) {
    if (
      $event.target.className === "div-arriere-plan-action" ||
      $event.target.className === "div-global-action"
    )
      this.cancelSelection();
  }

  receiveSelection($event: OfferCommand) {
    switch ($event.offer) {
      case OfferInfo.FreeTest: {
        this.freeTrial.emit(true);
        break;
      }
      case OfferInfo.MoreInfo: {
        this.selectedCardIndex = $event.index;
        this.status = 1;
        break;
      }
      case OfferInfo.Buy: {
        this.signup.emit(true);
        break;
      }
      case OfferInfo.SignUp: {
        this.signup.emit(true);
        break;
      }
    }
  }

  getContactPhone($event: string) {    
    let offer = "gratuite";
    switch (this.selectedCardIndex) {
      case 1: {
        offer = "Pack 1 evenement";
        break;
      }
      case 2: {
        offer = "Pack 5 evenements";
        break;
      }
      case 3: {
        offer = "Abonnement annuel";
        break;
      }
      default: {
        offer = "Offre gratutuite";
      }
    }
      let mailString =
        "Changement d'offre<br><br>e-mail: " +
        "pas encore client" +
        "<br>Association: " +
        this.isAssoc +
        "<br>Numéro de téléphone: " +
        $event +
        "<br>Offre: " +
        offer;
      let mailData = new ContactData(mailString);
      
      this.authenticationService.sendContactMail(mailData).subscribe((res) => {
        this.status = 3;
      });
  }

  endDetailsPopup($event: boolean) {
    if (!$event) {
      this.status = 0;
      this.cancelSelection();
    } else {
      this.status = 2;
    }
  }

  backOffers() {
    this.status = 0;
}

  cancelSelection() {
    const disableScroll = document.getElementsByTagName("body");
    disableScroll[0].style.overflow = "initial";
    this.isClosed.emit(true);
  }
}
