<div class="form-holder">
  <vc-poll-card-header [image]="'assets/resolution-icon.svg'" [title]="'POLL-CREATION.QUESTIONS.TITLE-RESOLUTIONS' | translate" [description]="'POLL-CREATION.QUESTIONS.RESOLUTIONS-DESCRIPTION' | translate">
  </vc-poll-card-header>
  <div class="safe-area">
    <div *ngFor="let resolution of resolutions; let i = index">
      <vc-fixed-live-resolution [index]="i" [round]="livePolls[i].index" [resolution]="resolution"  [isPublic]="isPublic" 
      [status]="livePolls[i]" [isActive]="i === activeIndex"
      (sendCommand)="wsCommand($event)" (sendResults)=getResults($event) (deleteEvent)="deleteExistingResolution($event)">
      </vc-fixed-live-resolution>
    </div>
    <vc-added-resolution *ngIf="addedResolution" [index]="resolutions.length" [resolution]="addedResolution" [status]=0
     (sendCommand)="addResolution()" (deleteEvent)="deleteResolution()" (sendAnswerImage)="getImageData($event)">
    </vc-added-resolution>
    <button id="add-label" *ngIf="canAddQuestions && !addedResolution"  (click)=addChoice()>
      +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.QUESTIONS.ADD-RESOLUTION' | translate}}
    </button>
  </div>
</div>
