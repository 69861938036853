<div id="page-container">
  <div id="content-wrap">
    <vc-poll-pages-header [title]="title" [isLive]="isLive" [isPublic]="isPublic"
      [SMSOption]="isSms" [is2FA]="is2FA" [isAssessors]="hasAssessorsButtons">
    </vc-poll-pages-header>
    <vc-poll-ongoing-summary [description]="description" [startDate]="startDate" [endDate]="endDate"
      [votersVoted]="votes" [votersCount]="votersCount" (return)="rejoinPoll()" [isLive]="isLive"
      [isSms]="isSms" [isPublic]="isPublic" [is2FA]="is2FA" [type]="votingType" (refreshDataEvent)="refreshData()"
      [participation]="percentageString" [sharedLinks]="sharedLinks">
    </vc-poll-ongoing-summary>
    <vc-poll-ongoing-timer [timeDelta]="timeDifference" [isLive]="isLive"
      [isPublic]="isPublic" (shouldDisplayVoters)="displayVoters()" [sent]="emailsSent" [inviteDate]="inviteDate" [timeDiffHours]="remainingTime"
      (shouldRelaunchInvitations)="displayRelaunchWarning()" [displayChangeVoters]="true" (shouldChangeVoters)="changeVoter()">
    </vc-poll-ongoing-timer>

    <div *ngIf="votingType == 'resolution' && !isLive">
      <vc-poll-planned-list class="resolutions" [votingType]="votingType" [answers]="answers"
      (editionEventEmitter)="editPoll()">
      </vc-poll-planned-list>
    </div>
    <!-- votingType == 'planned' à retirer à terme car ne sera plus utilisé -->
    <div *ngIf="(votingType == 'election' || votingType == 'planned') && !isLive">
      <vc-poll-planned-list *ngFor="let candidate of candidates, let index = index" class="election" [votingType]="votingType" [candidates]="candidate"
      [answers]="answers[0]" [numberOfVote]="answers[0].numberOfVote" (editionEventEmitter)="editPoll()" [index]="index">
      </vc-poll-planned-list>
    </div>
    <div *ngIf="votingType == 'list'">
      <vc-poll-planned-list *ngFor="let list of lists, let round = index" class="list" [votingType]="votingType"
      [answers]="list" [numberOfVote]="list.numberOfVote" (editionEventEmitter)="editPoll()"
      [titles]="titles" [pollId]="pollId" [displayBlockchain]="true" [index]="round">
      </vc-poll-planned-list>
    </div>

  </div>
</div>
<div *ngIf="isError">
  <app-error (isClosed)="common.navigateToPolls()" [errorMessage]="errorMessage">
  </app-error>
</div>
<div *ngIf="shouldDisplayVoters">
  <vc-warning-popup title="{{'REMAINING-VOTERS' | translate}}" [mails]="remainingVoters" [understoodEndButton]="true"
    (endDetailsPopup)="dismissVoters()" [separator]="false" [downloadButton]="true" (sendDownloadOrder)="downloadVoterFile()">
  </vc-warning-popup>
</div>
<div *ngIf="shouldDisplayRelaunchWarning">
  <app-voters-relaunch (isClosed)="handleRelaunch($event)" [isResend]=true>
  </app-voters-relaunch>
</div>
<div *ngIf="resendOngoingLoader">
  <vc-loader messageToTheUser="{{'LOADER.RESEND' | translate}}">
  </vc-loader>
</div>
<div *ngIf="shouldChangeVoter">
  <app-change-voter (closeChangeVoter)="closeChangeVoter($event)" [pollId]="pollId">
  </app-change-voter>
</div>
<div *ngIf="shouldDisplayChangeVoterValidation">
  <app-valid-changes (close)="closeChangeValidation()"></app-valid-changes>
</div>
