<section id="contact-form" class="contact-form">
    <div class="w-container">
        <h2>{{'HOME.CONTACT.TITLE' | translate}}</h2>

        <div id="formInstructions" class="small-text">
            <em>{{'HOME.CONTACT.MANDATORY-FIELDS-LABEL' | translate}}</em>
        </div>

        <div class="w-form">
            <form id="wf-form-Contact-Form" (ngSubmit)="onContact()" name="wf-form-Contact-Form">
                <div class="contact-form-grid">
                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d474-753db5e3">
                        <label for="Pr-nom" id="contact-first-name">{{'HOME.CONTACT.SURNAME' | translate}}</label>
                        <input type="text" class="w-input" maxlength="256" name="Pr-nom" id="Pr-nom" required>

                    </div>

                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d478-753db5e3">
                        <label for="Nom-de-famille" id="contact-last-name">{{'HOME.CONTACT.NAME' | translate}}</label>
                        <input type="text" class="w-input" maxlength="256" name="Nom-de-famille" id="Nom-de-famille" required>
                    </div>

                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d47c-753db5e3">
                        <label for="Email" id="contact-email">{{'HOME.CONTACT.MAIL' | translate}}</label>
                        <input type="email" class="w-input" maxlength="256" name="Email" id="Email" (keyup)="common.validateEmail($event)" required>
                    </div>

                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d480-753db5e3">
                        <label for="Num-ro-de-t-l-phone" id="contact-phone">{{'HOME.CONTACT.PHONE' | translate}}*</label>
                        <input type="tel" class="w-input" maxlength="256" name="Num-ro-de-t-l-phone" id="Phone" 
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '');" (keyup)="common.isPhoneValid($event)">
                    </div>

                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d484-753db5e3 radio">
                        <label for="Type-d-organisme" class="type">{{'HOME.CONTACT.TYPE' | translate}}</label>
                        <div class="input_container">
                            <input type="radio" class="type_input" name="type" required minlength="4" maxlength="8" size="10" value="Association">
                            <p class="radio_input">{{'HOME.CONTACT.ASSO' | translate}}</p>
                        </div>

                        <div class="input_container">
                            <input type="radio" class="type_input" name="type" required minlength="4" maxlength="8" size="10" value="Entreprise">
                            <p class="radio_input">{{'HOME.CONTACT.COMPANY' | translate}}</p>
                        </div>
    
                        <div class="input_container">
                            <input type="radio" class="type_input" name="type" required minlength="4" maxlength="8" size="10" value="Autre">
                            <p class="radio_input">{{'HOME.CONTACT.OTHER' | translate}}</p>
                        </div>
                    </div>

                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d484-753db5e3">
                        <label for="campagnes" id="campaing">{{'HOME.CONTACT.CAMPAIGN' | translate}}</label>
                        <textarea oninput="this.value = this.value.replace(/[^0-9.]/g, '');" data-name="campaing" maxlength="5" id="campaign" name="Campaign" class="w-input"></textarea>
                    </div>

                    <div id="w-node-_882a823a-e9f2-7609-40ee-7d557fc0d484-753db5e3">
                        <label for="Message" id="contact-message">{{'HOME.CONTACT.MESSAGE' | translate}}</label>
                        <textarea data-name="Message" maxlength="5000" id="Message" name="Message" class="w-input"></textarea>
                    </div>
                </div>

                <input type="submit" value="{{'HOME.CONTACT.SUBMIT' | translate}}" id="Soumission-Formulaire" class="submit-button w-button">
            </form>
            <div id="snackbar">
                <p>{{errorMessage}}</p>
            </div>

        </div>
    </div>
 </section>